import React from 'react';
import {graphql, useMutation, useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../../../searchFilter';

const merchantId = process.env.RAZZLE_TECHNICAL_MERCHANT;

const Pools = React.memo(({service}) => {
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query PoolsByViewerQuery($request: PoolsRequestInput) {
                poolsByViewer(request: $request) {
                    content {
                        id
                        cardPan
                        cardType
                    }
                }
            }
        `,
        {request: {
            size: 100500,
            page: 0,
            merchantId,
            fetchKey: 0
        }}
    );
    const fService = useFragment(
        graphql`
            fragment PoolsCabinetService_service on Service {
                id
                pools {
                    id
                    cardPan
                    cardType
                }
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation PoolsCabinetServiceMutation($request: UpdateServicePoolsRequestInput) {
                updateServicePools(request: $request) {
                    id
                    pools {
                        id
                        cardPan
                        cardType
                    }
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, pools: data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    // console.log(data.couponsByViewer.content.map(e => e.id), fService.coupons.map(f => f.id));
    // data.couponsByViewer.content.map(e => {
    //     console.log(e.id, fService.coupons.map(f => f.id).includes(e.id));
    // });
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Pools'/>
        </div>
        {fService.pools && !!fService.pools.length &&
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fService.pools.map(e => `${e.cardType} ${e.cardPan}`).join(', ')}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <span><FormattedMessage defaultMessage='You can add pool using'/></span>
                    {' '}
                    <Link className='link' to={`/services/pools${searchFilter(location.search)}`}><FormattedMessage defaultMessage='this link'/></Link>
                </p>
                <form onSubmit={submit}>
                    <div className='display-flex flex-wrap-wrap margin-top-0dot5rem'>
                    {data.poolsByViewer.content.map(e =>
                        <div key={e.id} className='checkbox margin-right-0dot5rem margin-bottom-0dot5rem'>
                            <label>
                                <div><input
                                    defaultChecked={fService.pools && fService.pools.map(f => f.id).includes(e.id)}
                                    value={e.id}
                                    type='checkbox'
                                    {...register('input')}
                                /></div>
                               {/Visa/i.test(e.cardType) &&
                                    <img
                                        src='https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg'
                                        style={{maxHeight: 14}}
                                        alt=''
                                    />
                                }
                                {/MasterCard/i.test(e.cardType) &&
                                    <img
                                        src='https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/1544px-Mastercard-logo.svg.png'
                                        style={{maxHeight: 14}}
                                        alt=''
                                    />
                                }
                                <span className='padding-left-0dot5rem'>{e.cardPan.replace(/X/g, '*')}</span>
                            </label>
                        </div>
                    )}
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Pools);
