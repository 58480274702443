import React from 'react';
import './Shell.scss';
import ChevronDownSvg from '../../../assets/icons0/chevron-down.svg';
import ChevronUpSvg from '../../../assets/icons0/chevron-up.svg';
import Building02Svg from '../../../assets/icons0/building-02.svg';
import Wallet01Svg from '../../../assets/icons0/wallet-01.svg';
import Grid01Svg from '../../../assets/icons0/grid-01.svg';
import Ticket01Svg from '../../../assets/icons0/ticket-01.svg';
import QrCode02Svg from '../../../assets/icons0/qr-code-02.svg';
import ShoppingCart01Svg from '../../../assets/icons0/shopping-cart-01.svg';
import CreditCardRefreshSvg from '../../../assets/icons0/credit-card-refresh.svg';
import Avatar120120Png from '../../../assets/avatar-120-120@3x.png';
import LinkExternal01Svg from '../../../assets/icons0/link-external-01.svg';
import classNames from 'classnames';
import {queryParameters} from '../../../qs';
import searchUnFilter from '../../../searchUnFilter';
import {useLazyLoadQuery} from 'react-relay/hooks';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import useQuery from '../../../useQuery';
import Button from '../../button/button';
import CustomLink from '../../CustomLink';
import {TELEGRAM_SUPPORT} from '../../../constants';
import {useAuthorizationState} from '../../../contexts/Authorization';
import {graphqlWhoamiNextQuery} from '../../graphql';

const RAZZLE_APP_ACCOUNTS = process.env.RAZZLE_APP_ACCOUNTS;
const RAZZLE_APP_MASTERWASH_SERVICE = process.env.RAZZLE_APP_MASTERWASH_SERVICE;

const SignInButton = () => {
    const location = useLocation();
    const qps = queryParameters(location);
    const signIn = (e) => {
        e.preventDefault();
        setTimeout(() => {
            window.location.href = RAZZLE_APP_ACCOUNTS + '/endpoint?cache=0&new=true&continue='
                + encodeURIComponent(location.pathname + searchUnFilter(document.location.search))
                + (qps.length ? '&' + qps.join('&') : '');
        }, 500);
    };
    return (
        <div className='padding-top-1dot5rem'>
            <div className='display-flex align-items-center padding-left-0dot5rem padding-right-0dot5rem padding-bottom-0dot5rem'>
                <Button color='primary-gradient' fluid='mobile' clickHandler={signIn} size='lg'>
                    <FormattedMessage defaultMessage='Sign in'/>
                </Button>
            </div>
        </div>
    )
}

const UserButton = React.memo(() => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    return (
        <div className='padding-top-1dot5rem'>
            <div className='display-flex align-items-center justify-content-space-between padding-left-0dot5rem padding-right-0dot25rem padding-bottom-0dot5rem'>
                <div className='display-flex'>
                    <div className='padding-top-0dot125rem padding-bottom-0dot125rem'>
                        <img className='display-block height-2dot5rem width-2dot5rem' src={Avatar120120Png} alt=''/>
                    </div>
                    <div className='padding-left-0dot75rem display-flex flex-direction-column'>
                        <div className='flex-1 display-flex align-items-center text-md medium color-gray-700'>
                            {whoamiNext.username || whoamiNext.email}
                        </div>
                        <div className='flex-1 display-flex align-items-center'>
                            <div className='opacity-0dot4 text-sm color-primary-600'>
                                <FormattedMessage defaultMessage='Account management'/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='opacity-0dot4 margin-top-0dot25rem display-flex align-items-center justify-content-center width-2dot25rem height-2dot25rem'>
                    <LinkExternal01Svg className=' display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                </div>
            </div>
        </div>
    );
});

const MenuSmall = React.memo(({menuSmallRef, menuFlag, toggleMenu}) => {
    const authorized = useAuthorizationState();
    const [platformFlag, setPlatformFlag] = React.useState(false);
    const [partnersFlag, setPartnersFlag] = React.useState(false);
    const togglePlatform = React.useCallback(() => setPlatformFlag((flag) => !flag), []);
    const togglePartners = React.useCallback(() => setPartnersFlag((flag) => !flag), []);
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div
            ref={menuSmallRef}
            className={classNames('display-none', {'display-block': menuFlag})}
        >
            <div className='padding-left-1rem padding-right-1rem padding-top-1dot5rem'>
                <CustomLink to={`/${searchFilter(location.search)}`} onClick={toggleMenu}>
                    {match => 
                        <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                            <Grid01Svg className={classNames('display-block height-1dot5rem width-1dot5rem color-gray-600', {'color-primary-600': match})}/>
                            <div className='padding-left-0dot75rem text-md medium color-gray-900'>
                                <FormattedMessage defaultMessage='Marketplace'/>
                            </div>
                        </div>
                    }
                </CustomLink>
                <div className='padding-top-0dot5rem'>
                    <CustomLink to={`/wallet${searchFilter(location.search)}`} onClick={toggleMenu}>
                        {match => 
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <Wallet01Svg className={classNames('display-block height-1dot5rem width-1dot5rem color-gray-600', {'color-primary-600': match})}/>
                                <div className='padding-left-0dot75rem text-md medium color-gray-900'>
                                    <FormattedMessage defaultMessage='Wallet'/>
                                </div>
                            </div>
                        }
                    </CustomLink>
                </div>
                <div className='padding-top-0dot5rem'>
                    <Link to={`/business${searchFilter(location.search)}`} onClick={toggleMenu}>
                        <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                            <Building02Svg className='display-block height-1dot5rem width-1dot5rem color-gray-600'/>
                            <div className='padding-left-0dot75rem text-md medium color-gray-900'>
                                <FormattedMessage defaultMessage='Business cabinet'/>
                            </div>
                        </div>
                    </Link>
                </div>
                {authorized ?
                    <React.Suspense>
                        <UserButton/>
                    </React.Suspense>
                    :
                    <SignInButton/>
                }
                <div className='padding-top-1dot5rem padding-left-0dot75rem padding-right-0dot75rem'>
                    <div className='height-0dot0625rem background-color-gray-200'/>
                </div>
                <div className='padding-top-1dot5rem'>
                    <div className={classNames('display-flex align-items-center justify-content-space-between padding-left-0dot75rem padding-right-0dot875rem height-2dot5rem border-radius-0dot375rem', {'background-color-gray-50' : platformFlag})} onClick={togglePlatform}>
                        <div className='text-md medium color-gray-700'>
                            <FormattedMessage defaultMessage='Products'/>
                        </div>
                        {platformFlag ?
                            <ChevronUpSvg className='display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                            :
                            <ChevronDownSvg className='display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                        }
                    </div>
                </div>
                {platformFlag &&
                <div className='padding-top-0dot5rem padding-bottom-0dot5rem'>
                    <Link to={`/tickets${searchFilter(location.search)}`} onClick={toggleMenu}>
                        <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                            <Ticket01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                            <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                <FormattedMessage defaultMessage='Electronic tickets'/>
                            </div>
                        </div>
                    </Link>
                    <div className='padding-top-0dot5rem'>
                        <Link to={`/master-wash/services/${RAZZLE_APP_MASTERWASH_SERVICE}${searchFilter(location.search)}`} onClick={toggleMenu}>
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <QrCode02Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                                <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                    <FormattedMessage defaultMessage='QR terminal'/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className='padding-top-0dot5rem'>
                        <a href='https://time2eat.today/' rel='nofollow'>
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <ShoppingCart01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                                <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                    <FormattedMessage defaultMessage='Micromarkets'/>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className='padding-top-0dot5rem'>
                        <a href='https://subscription.uaf.org.ua/'>
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <CreditCardRefreshSvg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                                <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                    <FormattedMessage defaultMessage='Recurring payments'/>
                                </div>
                                <div className='margin-left-0dot5rem display-flex align-items-center padding-left-0dot625rem padding-right-0dot625rem padding-top-0dot125rem padding-bottom-0dot125rem background-color-success-50 border-radius-1rem'>
                                    <div className='text-xs medium color-success-700'>New</div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                }
                <div className='margin-top-0dot5rem'>
                    <div className={classNames('display-flex align-items-center justify-content-space-between padding-left-0dot75rem padding-right-0dot875rem height-2dot5rem border-radius-0dot375rem', {'background-color-gray-50' : partnersFlag})} onClick={togglePartners}>
                        <div className='text-md medium color-gray-700'>
                            <FormattedMessage defaultMessage='For partners'/>
                        </div>
                        {partnersFlag ?
                            <ChevronUpSvg className='display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                            :
                            <ChevronDownSvg className='display-block width-1dot25rem height-1dot25rem color-gray-500'/>
                        }
                    </div>
                </div>
                {partnersFlag &&
                <div className='padding-top-0dot5rem padding-bottom-0dot5rem'>
                    <Link to={`/promo/tickets${searchFilter(location.search)}`} onClick={toggleMenu}>
                        <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                            <Ticket01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                            <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                <FormattedMessage defaultMessage='Electronic tickets'/>
                            </div>
                        </div>
                    </Link>
                    <div className='padding-top-0dot5rem'>
                        <div className='opacity-0dot4 display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                            <QrCode02Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                            <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                <FormattedMessage defaultMessage='QR terminal'/>
                            </div>
                        </div>
                    </div>
                    <div className='padding-top-0dot5rem'>
                        <Link to={`/promo/micromarkets${searchFilter(location.search)}`} onClick={toggleMenu}>
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <ShoppingCart01Svg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                                <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                    <FormattedMessage defaultMessage='Micromarkets'/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <Link to={`/promo/subscriptions${searchFilter(location.search)}`} onClick={toggleMenu}>
                        <div className='padding-top-0dot5rem'>
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <CreditCardRefreshSvg className='display-block height-1dot5rem width-1dot5rem color-blue-gray-500'/>
                                <div className='padding-left-0dot75rem text-md medium color-gray-700'>
                                    <FormattedMessage defaultMessage='Recurring payments'/>
                                </div>
                                <div className='margin-left-0dot5rem display-flex align-items-center padding-left-0dot625rem padding-right-0dot625rem padding-top-0dot125rem padding-bottom-0dot125rem background-color-success-50 border-radius-1rem'>
                                    <div className='text-xs medium color-success-700'>New</div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                }
                <div className='margin-top-0dot5rem'>
                    <a className='' href={TELEGRAM_SUPPORT} target='_blank' onClick={toggleMenu}>
                        <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                            <div className='text-md medium color-gray-700'>
                                <FormattedMessage defaultMessage='Support'/>
                            </div>
                        </div>
                    </a>
                </div>
                {authorized &&
                    <div className='margin-top-1rem'>
                        <Link to={`/log-out${searchFilter(location.search)}`} onClick={toggleMenu}>
                            <div className='display-flex align-items-center padding-left-0dot75rem height-2dot5rem'>
                                <div className='text-md medium color-gray-500'>
                                    <FormattedMessage defaultMessage='Log out'/>
                                </div>
                            </div>
                        </Link>
                    </div>
                }
            </div>
            <div className='padding-top-1dot5rem'>
                <div className='height-0dot0625rem background-color-gray-200'/>
            </div>
            <div className='display-flex padding-left-1rem padding-top-1dot5rem padding-bottom-1dot5rem'>
                <a href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}>
                    <div className={classNames('padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem text-sm medium color-gray-700 border-radius-0dot5rem', {
                        'background-color-gray-50': locale === 'uk' 
                    })}>UA</div>
                </a>
                <a
                    className='margin-left-0dot5rem'
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                >
                    <div className={classNames('padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem text-sm medium color-gray-700 border-radius-0dot5rem', {
                        'background-color-gray-50': locale === 'en'
                    })}>EN</div>
                </a>
            </div>
        </div>
    );
});

export default MenuSmall;