/**
 * @generated SignedSource<<307fd7d7a245c4edb9d661420015b6eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "updateEndpointServices",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameUk",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesCabinetEndpointMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ServicesCabinetEndpointMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1529e78863587f0656d54fa11c3699e8",
    "id": null,
    "metadata": {},
    "name": "ServicesCabinetEndpointMutation",
    "operationKind": "mutation",
    "text": "mutation ServicesCabinetEndpointMutation(\n  $request: UpdateEndpointServicesRequestInput\n) {\n  updateEndpointServices(request: $request) {\n    id\n    services {\n      id\n      name\n      nameUk\n    }\n    version\n  }\n}\n"
  }
};
})();

node.hash = "65ec0cb0c1c9da3df804cbb6c91e558f";

module.exports = node;
