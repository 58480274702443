import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import {FetchKeySetStateContext} from '../../../FetchKey';
// import {ConnectionHandler} from 'relay-runtime';

const Target = React.memo(({service}) => {
    const fetchKeySetState = React.useContext(FetchKeySetStateContext);
    const fService = useFragment(
        graphql`
            fragment TargetCabinetService_service on Service {
                id
                version
                target
            }
        `,
        service
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation TargetCabinetServiceMutation($request: UpdateServiceTargetRequestInput) {
                updateServiceTarget(request: $request) {
                    id
                    version
                    target
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            target: !data.target ? null : moment(data.target).utc().format()
        }},
        onCompleted: () => { fetchKeySetState((e) => e + 1); setFlag(false)},
        onError: () => {},
        // updater: store => {
        //     const record = store.get(fService.id);
        //     if (record !== null) {
        //         console.log('invalidateRecord', record);
        //         record.invalidateRecord();
        //     }
        // }
        updater: store => {
            const targetServices = store.get('client:root:viewer:__TargetServices_targetServicesByViewer_connection');
            targetServices && targetServices.invalidateRecord();
            const topServices = store.get('client:root:viewer:__TopServices_targetServices_connection');
            topServices && topServices.invalidateRecord();
            // store.invalidateStore();
            // store.getRoot().getLinkedRecord('targetServicesByViewer', {page: 0, size: 2}).invalidateRecord()
            // console.log(store.getRoot().getLinkedRecord('targetServicesByViewer', {page: 0, size: 2}));
            // const rs = ConnectionHandler.getConnection(store.getRoot(), 'TargetServices_targetServicesByViewer');
            // if (rs) {
            //     rs.invalidateRecord();
            //     // const edges = rs.getLinkedRecords("edges");
            //     // edges.forEach(e => {
            //     //     // console.log(e);
            //     //     e.getLinkedRecord('node').invalidateRecord();
            //     //    // e.invalidateRecord();
            //     // });
            // }
        }
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Date of the event'/>
        </div>
        <div className='font-weight-bold margin-top-0dot5rem'>
            {fService.target && <>{moment(fService.target).format('YYYY-MM-DD HH:mm:ss')}</>}
        </div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem font-size-0dot8rem padding-bottom-1rem'>
                    <FormattedMessage defaultMessage='Date format YYYY MM DD hh:mm. For example'/>
                    {' '}
                    {moment().format('YYYY-MM-DD')} 10:00
                </p>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.target && moment(fService.target).format('YYYY-MM-DD HH:mm:ss')}
                            {...register('target', {
                                validate: value => !value || moment(value).isValid()
                            })}
                            placeholder='2024-12-30 10:00'
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Target);
