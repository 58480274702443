import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {graphql, useLazyLoadQuery, useFragment, useMutation, usePaginationFragment} from 'react-relay/hooks';
import {Link, useLocation} from 'react-router-dom';
import {IMPULSE_STATUS, IMPULSE_STATUS_COLOR} from '../../../constants';
import moment from 'moment';
import QRCode from 'qrcode.react';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import qs from 'qs';
import {FetchKeyStateContext} from '../../FetchKey';
import ErrorFallback from '../../ErrorFallback';

const Qrs = React.memo(({impulse, position}) => {
    const fImpulse = useFragment(
        graphql`
            fragment LatestImpulsesQrs_impulse on Impulse {
                id
                impulseEndpoints {
                    numbers
                }
                qrs
            }
        `,
        impulse
    );
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation LatestImpulsesQrsQDownloadMutation {
                handle {
                    accessToken
                }
            }
    `);
    const [index, setIndex] = React.useState(0);
    return (
        <>
        <div className='margin-top-0dot5rem'><QRCode size={192} value={`plant://${fImpulse.qrs[position][index]}`}/></div>
        <div className='display-flex margin-top-0dot5rem'>
            <div
                className='button'
                onClick={() => !isMutationInFlight && commit({
                    onCompleted: (data) => {
                        const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;
                        const suffix = qs.stringify({
                            handle: data.handle.accessToken,
                            id: fImpulse.id,
                            position,
                            index
                        });
                        window.location.href = RAZZLE_APP_PREFIX + `/api/impulses/plant/download?` + suffix;
                    },
                    onError: () => {}
                })}
            >
                <FormattedMessage defaultMessage='Download'/> #{index}{isMutationInFlight && '...'}
            </div>
        </div>
        {fImpulse.qrs[position].length > 1 &&
        <div className='display-flex align-items-center margin-top-0dot5rem'>
            <div
                onClick={() => index && setIndex(e => --e)}
                className={classNames('button', 'primary', {
                    'disabled': index === 0
                })}
            >&#60;</div>
            <div className='margin-left-0dot5rem'>#{index}</div>
            <div
                onClick={() => index < fImpulse.qrs[position].length - 1 && setIndex(e => ++e)}
                className={classNames('button', 'primary', 'margin-left-0dot5rem', 'margin-right-0dot5rem', {
                    'disabled': index === fImpulse.qrs[position].length - 1
                })}
            >&#62;</div>
            {(fImpulse.impulseEndpoints[position].numbers && !!fImpulse.impulseEndpoints[position].numbers[index]) &&
            <>
            <FormattedMessage defaultMessage='Take'/>
            <span className='badge orangered margin-left-0dot25rem'>
                {fImpulse.impulseEndpoints[position].numbers[index]}
            </span>
            </>
            }
        </div>
        }
        </>
    );
});

const LatestImpulsesImpl = React.memo(({viewer}) => {
    const location = useLocation();
    const {data, loadNext, hasNext, isLoadingNext} = usePaginationFragment(
        graphql`
            fragment LatestImpulsesImpl_viewer on Viewer @refetchable(queryName: "LatestImpulsesImplQuery") {
                impulsesByViewer(first: $first, after: $after, inbox: $inbox, sent: $sent, status: $status) @connection(key: "LatestImpulses_impulsesByViewer") {
                    edges {
                        node {
                            id
                            amount
                            impulseService {
                                name
                                demo
                            }
                            impulseEndpoints {
                                name
                                price
                                quantity
                                untie {
                                    price
                                    currency
                                }
                            }
                            impulseAddls {
                                name
                                value
                            }
                            impulseClient {
                                email
                            }
                            orderReference
                            modified
                            status
                            qrs
                            number
                            ...LatestImpulsesQrs_impulse
                        }
                    }
                }
            }
            
        `,
        viewer
    );
    return (
        <div className='display-flex flex-wrap-wrap'>
            {data.impulsesByViewer.edges.map(e => e.node).map((impulse) =>
                <div key={impulse.id} className='impulses__card'>
                    <div className='border-bottom-1px-dotted-black padding-bottom-1rem height-100percent'>
                        {impulse.impulseService.demo &&
                        <span className='tag tag-magenta margin-right-0dot5rem font-size-0dot8rem'>
                            <FormattedMessage defaultMessage='Demo'/>
                        </span>
                        }
                        <span className={`tag tag-${IMPULSE_STATUS_COLOR[impulse.status]} font-size-0dot8rem`}>
                            {IMPULSE_STATUS[impulse.status]}
                        </span>
                        <div>
                            <Link
                                className='focus'
                                to={`impulses/${impulse.id}${searchFilter(location.search)}`}
                            >{impulse.id}</Link>
                        </div>
                        <div className='font-size-0dot8rem color-grey'>
                            {impulse.orderReference}
                        </div>
                        <div className='font-weight-bold padding-top-1rem'>
                            <span className='font-weight-bold highlight palevioletred'>
                                &nbsp;{impulse.impulseService.name}&nbsp;
                            </span>
                        </div>
                        {impulse.impulseEndpoints.map((e, i) =>
                            <div key={i} className='margin-top-1rem'>
                                <div>
                                    <span className='highlight lightslategrey'>
                                        &nbsp;#{i} {e.name}&nbsp;
                                    </span>
                                    {Math.ceil(e.price) > 0 ? ` ${e.price} UAH` : ''}
                                    {!e.untie && <span>&nbsp;/&nbsp;<span className='badge'>{e.quantity}</span></span>}
                                </div>
                                <div className='margin-top-0dot5rem'>
                                    <Qrs impulse={impulse} position={i}/>
                                </div>
                            </div>
                        )}
                        {impulse.amount && Math.ceil(impulse.amount) > 0 &&
                        <div className='margin-top-1rem'>
                            <FormattedMessage defaultMessage='Amounts'/> <span
                            className='font-weight-bold'>{impulse.amount}</span> UAH
                        </div>
                        }
                        <div className='margin-top-1rem font-weight-bold'>
                            {moment(impulse.modified).format('YYYY-MM-DD HH:mm:ss')}
                        </div>
                        {!!impulse.number &&
                        <>
                        <FormattedMessage defaultMessage='Stub'/>
                        <span className='badge orangered margin-top-1rem margin-left-0dot25rem'>{impulse.number}</span>
                        </>
                        }
                    </div>
                </div>
            )}
            {!data.impulsesByViewer.edges.length &&
            <span><FormattedMessage defaultMessage='No data for now'/></span>
            }
            {hasNext &&
            <div className='display-flex'>
                <div
                    className='button primary'
                    onClick={() => !isLoadingNext && loadNext(2)}
                ><FormattedMessage defaultMessage='More'/>{isLoadingNext && '...'}</div>
            </div>
            }
        </div>
    );
});

const LatestImpulses = React.memo(() => {
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const data = useLazyLoadQuery(
        graphql`
            query LatestImpulsesQuery($first: Int!, $after: String, $inbox: Boolean, $sent: Boolean, $status: [Int]) {
                viewer {
                    ...LatestImpulsesImpl_viewer
                }
            }
        `,
        {first: 2, sent: true, status: [3, 4]},
        {fetchKey: fetchKeyState}
    );
    return (
        <LatestImpulsesImpl {...{viewer: data.viewer}}/>
    );
});

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-0dot5rem'>
                <span className='font-size-2dot5rem font-weight-bold'>
                    <FormattedMessage defaultMessage='Latest orders'/>
                </span>
                <div>
                    {' '}<FormattedMessage defaultMessage='or'/>{' '}
                    <Link
                        className='focus'
                        to={`../impulses${searchFilter(location.search)}`}
                    ><FormattedMessage defaultMessage='Show all'/></Link>
                </div>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense
                    fallback={
                        <div className='display-flex flex-wrap-wrap'>
                            {[1, 2, 3].map(i =>
                                <div key={i} className='card'>
                                    <div className='border-bottom-1px-dotted-black padding-bottom-1rem'>
                                        <Skeleton/>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                >
                    <LatestImpulses/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
