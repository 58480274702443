/**
 * @generated SignedSource<<d708dabab2b708e1f090088de49e7b31>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "updateEndpointUntie",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "untie",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UntieCabinetEndpointMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UntieCabinetEndpointMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4fd4ab1ccbd932a30c135466a34c2978",
    "id": null,
    "metadata": {},
    "name": "UntieCabinetEndpointMutation",
    "operationKind": "mutation",
    "text": "mutation UntieCabinetEndpointMutation(\n  $request: UpdateEndpointUntieRequestInput\n) {\n  updateEndpointUntie(request: $request) {\n    id\n    untie\n    version\n  }\n}\n"
  }
};
})();

node.hash = "c977a886ecff8168e81bee9b95f9c5c4";

module.exports = node;
