import React from 'react';
import './Shell.scss';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import searchFilter from '../../../searchFilter';
import useQuery from '../../../useQuery';
import IconMaster from '../../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../../assets/icons-payment/icon-google-pay.svg';
import LogoDarkModeFalseSvg from '../../../assets/logo-dark-mode-false.svg';
import Skeleton from '../../Skeleton';

const year = new Date().getFullYear();

const Footer = () => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='view'>
            <div className='block'>
                <div className='padding-top-3rem padding-bottom-3rem'>
                    <React.Suspense fallback={<Skeleton/>}>
                        <div className='display-flex justify-content-space-between align-items-center'>
                            {/*<img src='/assets/logo-dark-mode-false.png'/>*/}
                            {/*<img src={LogoDarkModeFalseSvg} alt=''/>*/}
                            <Link to={`/${searchFilter(location.search)}`}>
                                <LogoDarkModeFalseSvg className='display-block'/>
                            </Link>
                            <div className='hide-small display-flex text-md medium'>
                                <a
                                    className={classNames({'font-weight-bold': locale === 'uk'})}
                                    href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                                >
                                    <div className='padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem color-gray-600'>UA</div>
                                </a>
                                <a
                                    className={classNames({'font-weight-bold': locale === 'en'})}
                                    href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                                >
                                    <div className='padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem color-gray-600'>EN</div>
                                </a>
                            </div>
                        </div>
                        <div className='margin-top-1dot5rem-2rem display-flex flex-direction-column-row justify-content-space-between'>
                            <div className='text-md color-gray-500'>
                                <div className='display-block-inline'><FormattedMessage defaultMessage='Ottry LLC'/></div>
                                <div className='hide-small display-block-inline'>,{' '}</div>
                                <div className='display-block-inline'><FormattedMessage defaultMessage='VAT identification number is 41385243'/></div>
                                <div className='display-block'><FormattedMessage defaultMessage='16, Hlybochytska Street, office 13, Kyiv, 04050'/></div>
                            </div>
                            <div className='margin-top-1dot5rem-0'>
                                <a href='tel:+380979381162'>
                                    <div className='text-md medium color-gray-600'>
                                        +38 (097) 938-11-62
                                    </div>
                                </a>
                                <a href='mailto:info@ottry.com'>
                                    <div className='margin-top-0dot5rem mw768-margin-top-0 text-md medium color-indigo-600'>
                                        info@ottry.com
                                    </div>
                                </a>
                            </div>
                            <div className='margin-top-2rem-0 display-flex mw768-justify-content-flex-end'>
                                {/*<img className='height-1dot5rem-3rem' src='https://wfp-help.s3.eu-central-1.amazonaws.com/1568625809_payment_methods_1.png%3Fversion%3D1%26amp%3BmodificationDate%3D1510658069000%26amp%3Bapi%3Dv2' alt=''/>*/}
                                <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                            </div>
                        </div>
                        <div className='margin-top-2rem background-color-gray-200 height-0dot0625rem'/>
                        <div className='display-flex flex-direction-column-row-reverse justify-content-space-between margin-top-2rem'>
                            <div className='display-flex'>
                                <div>
                                    <Link className='text-md color-gray-400' to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                                </div>
                                <div className='margin-left-1rem'>
                                    <Link className='text-md color-gray-400' to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                                </div>
                                <div className='margin-left-1rem'>
                                    <Link className='text-md color-gray-400' to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                                </div>
                                <div 
                                    className='cursor-pointer margin-left-1rem text-md color-gray-600'
                                    onClick={() => window?.CookieConsentApi?.showSettings()}
                                >Cookies</div>
                            </div>
                            <div className='margin-top-1rem mw768-margin-top-0 text-md color-gray-400'>
                                © {year} Ottry. All rights reserved.
                            </div>
                        </div>
                    </React.Suspense>
                </div>
            </div>
        </div>
    )
};

export default React.memo(Footer);