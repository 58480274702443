import React from 'react';
import {graphql, useMutation, useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../../../searchFilter';

const Coupons = React.memo(({service}) => {
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query CouponsByViewerCabinetServiceQuery($size: Int!, $page: Int!) {
                couponsByViewer(size: $size, page: $page) {
                    totalPages,
                    content {
                        id
                        code
                        discount
                    }
                }
            }
        `,
        {size: 100500, page: 0}
    );
    const fService = useFragment(
        graphql`
            fragment CouponsCabinetService_service on Service {
                id
                coupons {
                    id
                    code
                    discount
                }
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation CouponsCabinetServiceMutation($request: UpdateServiceCouponsRequestInput) {
                updateServiceCoupons(request: $request) {
                    id
                    coupons {
                        id
                        code
                        discount
                    }
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, coupons: data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    // console.log(data.couponsByViewer.content.map(e => e.id), fService.coupons.map(f => f.id));
    // data.couponsByViewer.content.map(e => {
    //     console.log(e.id, fService.coupons.map(f => f.id).includes(e.id));
    // });
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Coupons'/>
        </div>
        {fService.coupons && !!fService.coupons.length &&
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fService.coupons.map(e => `${e.code} / ${e.discount}%`).join(', ')}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <span><FormattedMessage defaultMessage='You can create coupon using'/></span>
                    {' '}
                    <Link className='link' to={`../coupons${searchFilter(location.search)}`}><FormattedMessage defaultMessage='this link'/></Link>
                </p>
                <form onSubmit={submit}>
                    <div className='display-flex flex-wrap-wrap margin-top-0dot5rem'>
                    {data.couponsByViewer.content.map(e =>
                        <div key={e.id} className='checkbox margin-right-0dot5rem margin-bottom-0dot5rem'>
                            <label>
                                <div><input
                                    defaultChecked={fService.coupons && fService.coupons.map(f => f.id).includes(e.id)}
                                    value={e.id}
                                    type='checkbox'
                                    {...register('input')}
                                /></div>
                               {e.code} / {e.discount}%
                            </label>
                        </div>
                    )}
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Coupons);
