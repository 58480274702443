import React from 'react';
import classNames from 'classnames';

const LocationPending = ({children, data}) => {
    const defferedData = React.useDeferredValue(data);
    const isPending = data !== defferedData;
    return (
        <div className={classNames('delayed-spinner', {
            'in': isPending,
            'out': !isPending
        })}>
            {children(defferedData)}
        </div>
    );
}

export default React.memo(LocationPending);