import React from 'react';
import './Simple.scss';
import {Link, useLocation} from 'react-router-dom';
import {Outlet} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../searchFilter';
import classNames from 'classnames';
import useQuery from '../useQuery';
import Lol from './Lol';
import ErrorFallback from './ErrorFallback';

const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

function Simple() {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='simple'>
            <div className={`simple__header-divider`}>
                <div className='simple__header'>
                    <div className='simple__logo'>
                        <Link
                            to={`/${searchFilter(location.search)}`}
                        >Ottry</Link>
                    </div>
                </div>
            </div>
            <div className='view'>
                <div className='block'>
                    <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                        <React.Suspense fallback={'Loading...'}>
                            <Outlet/>
                        </React.Suspense>
                    </ErrorBoundary>
                </div>
            </div>
            <div className='margin-top-1dot5rem'><Lol/></div>
            <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                >Uk</a>
                {' '}
                <a
                    className={classNames('link', 'font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                >En</a>
            </div>
            <div className='font-size-0dot8rem margin-bottom-1rem'>
                <div className='simple__footer-content'>
                    <div className='simple__footer-info'>
                        <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                        {/* <a href={legal + `/rules-${locale}.docx`} download><FormattedMessage defaultMessage='Rules'/></a>
                        <a href={legal + `/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of service'/></a>
                        <a href={legal + `/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a> */}
                    </div>
                </div>
                <div className='simple__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
}

export default React.memo(Simple);