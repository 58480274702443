import qs from 'qs';

export default (search, inline = {}, release = true) => {
    const current = qs.parse(search, {ignoreQueryPrefix: true});
    const filter = [...new Set(
        ['locale', 'currency', 'more', 'mode', 'apply', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'fbclid'].concat(Object.keys(inline)).concat(release ? [] : Object.keys(current))
    )];
    return qs.stringify(
        {
            ...current,
            ...inline
        },
        {
            filter,
            addQueryPrefix: true
        }
    );
};