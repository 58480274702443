/**
 * @generated SignedSource<<8a6120874a78b0ad1fa2a962f5fbe15a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "createData",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadDownloadExCreateDataMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DownloadDownloadExCreateDataMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f5c0f1520662472dcf2c39e269c29349",
    "id": null,
    "metadata": {},
    "name": "DownloadDownloadExCreateDataMutation",
    "operationKind": "mutation",
    "text": "mutation DownloadDownloadExCreateDataMutation(\n  $request: DataRequestInput\n) {\n  createData(request: $request) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "2b35112922c1b05994faa7bf827e73bb";

module.exports = node;
