import React from 'react';
import useQuery from '../useQuery';
import {isTrue} from '../utility';

const ShowMoreQueryParam = ({children}) => {
    const {more} = useQuery();
    if (isTrue(more)) {
        return <>{children}</>
    } else {
        return <></>
    }
}

export default React.memo(ShowMoreQueryParam);