import React from 'react';
import {FormattedMessage} from 'react-intl';
import {LinkButton} from '../../button/button';
import Skeleton from '../../Skeleton';
import {Faq} from './Home';
import {Helmet} from 'react-helmet-async';
import {TELEGRAM_SUPPORT} from '../../../constants';
import classNames from 'classnames';
import SearchLgSvg from '../../../assets/icons0/search-lg.svg';
import XCircleSvg from '../../../assets/icons0/x-circle.svg';
import AlgoliaLogoBlueSvg from '../../../assets/algolia-logo-blue.svg';
import CalendarSvg from '../../../assets/icons/calendar.svg';
import MarkerPin01Svg from '../../../assets/icons0/marker-pin-01.svg';
import Masonry from './Masonsy';
import {graphql, useLazyLoadQuery, usePaginationFragment, useFragment} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import i18n from '../../../i18n';
import SuspenseImageStaticSize from '../../SuspenseImageStaticSize';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import searchFilter from '../../../searchFilter';
import SuspenseList from '../../SuspenseList';
import useQuery from '../../../useQuery';
import moment from 'moment';
import Button, {RouterLinkButton} from '../../button/button';
import ArrowUpRightSvg from '../../../assets/icons/arrow-up-right.svg';
import Browser from '../../Browser';
import ErrorFallback from '../../ErrorFallback';
import {useForm} from 'react-hook-form';
import {ServiceList} from './ServiceList';
import i18ns from '../../../i18ns';
import getServiceUrl from '../../../utilities/get-service-url';

const Focus = React.memo(({value}) => {
    const values = React.useMemo(() => {
        const dim = [];
        let accumulator = '';
        for (let i = 0; i < value.length; i++) {
            const current = value[i];
            if (i + 4 <= value.length) {
                const em =  value.substring(i, i + 4);
                if (em === '<em>') {
                    if (accumulator !== '') {
                        dim.push(accumulator);
                        accumulator = '';
                    }
                }
            }
            accumulator += current;
            if (i - 4 >= 0) {
                const em =  value.substring(i - 4, i + 1);
                if (em === '</em>') {
                    if (accumulator !== '') {
                        dim.push(accumulator);
                        accumulator = '';
                    }
                }
            }
        }
        if (accumulator !== '') {
            dim.push(accumulator);
            accumulator = '';
        }
        return dim;
    }, [value]);
    return (
        <>
            {values.map((e, i) => <span key={i}>
                {e.startsWith('<em>') ? <span className='bold'>{e.substring(4, e.length - 5)}</span> : e}
            </span>)}
        </>
    );
});

const SearchServiceEntry = React.memo(({pileService}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const fPileService = useFragment(
        graphql`
            fragment searchServiceEntry_pileService on PileService {
                pile {
                    get_highlightResult
                    get_snippetResult
                }
                service {
                    id
                    slug
                    name
                    nameUk
                    details
                    detailsUk
                    location
                    locationUk
                    start
                    end
                    strategy
                    every
                    warp
                    target
                    description
                    pin
                }
            }
        `,
        pileService
    );
    const fPile = fPileService.pile;
    const fServiceById = fPileService.service;
    const cover = React.useMemo(() => {
        if (i18n(fServiceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(fServiceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [fServiceById, locale]);
    const serviceUrl = getServiceUrl({description: fServiceById.description, id: fServiceById.id, slug: fServiceById.slug, warp: fServiceById.warp, location});
    return (
        <div 
            className='background-color-white border-radius-0dot75rem padding-left-1dot5rem padding-top-1dot5rem padding-right-1dot5rem padding-bottom-1dot5rem shadow-large-light'
            style={!!fServiceById.pin ? {
                border: '4px solid var(--primary-600, #1570EF)',
                boxShadow: '0px 8px 8px -4px rgba(16, 24, 40, 0.04), 0px 20px 24px -4px rgba(16, 24, 40, 0.10)',
                paddingTop: '20px',
                paddingBottom: '20px',
            } : {}}
        >
            <Link to={serviceUrl} className='display-block'>
                {cover &&
                    <Browser>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImageStaticSize
                                className='max-width-100percent border-radius-0dot5rem card-shadow-small'
                                src={cover}
                            />
                        </ErrorBoundary>
                    </Browser>
                }
                <div className='padding-top-0dot75rem'>
                    {!!fServiceById.pin &&
                    <div className='padding-top-0dot75rem'>
                        <div className='display-flex'>
                            <div className='padding-0dot25rem padding-right-0dot625rem background-color-success-50 border-radius-1rem'>
                                <div className='display-flex align-content-center'>
                                    <div className='padding-top-0dot125rem padding-right-0dot625rem padding-bottom-0dot125rem padding-left-0dot625rem background-color-success-600 border-radius-1rem'>
                                        <div className='text-sm media color-white'>Топ подія</div>
                                    </div>
                                    <div className='padding-left-0dot75rem padding-top-0dot125rem padding-bottom-0dot125rem'>
                                        <div className='text-sm medium color-success-700'>Оттрі рекомендує</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>
                {fPile?.get_highlightResult ?
                <div className='padding-top-0dot75rem text-xl medium color-gray-900'>
                    {i18ns(fPile.get_highlightResult, 'name', locale)
                        ? <Focus {...{value: i18ns(fPile.get_highlightResult, 'name', locale).value}}/> 
                        : i18n(fServiceById, 'name', locale)
                    }
                </div>
                :
                <div className='padding-top-0dot75rem text-xl medium color-gray-900'>
                    {i18n(fServiceById, 'name', locale)}
                </div>
                }
                {fPile?.get_snippetResult && i18ns(fPile.get_snippetResult, 'details', locale) &&
                <div className='padding-top-0dot25rem text-sm color-gray-500 overflow-wrap-break-word'>
                    <Focus {...{value: i18ns(fPile.get_snippetResult, 'details', locale).value}}/>
                </div>
                }
                {fServiceById.target &&
                <div className='margin-top-0dot75rem display-flex align-items-center'>
                    <CalendarSvg className='display-block width-1dot25rem height-1dot25rem color-gray-400'/>
                    <div className='text-md medium color-gray-500 margin-left-0dot5rem'>
                        {moment(fServiceById.target).format('DD.MM.YYYY')}
                    </div>
                </div>
                }
                {i18n(fServiceById, 'location', locale) &&
                <>
                {fPile?.get_highlightResult ?
                <div className='margin-top-0dot75rem display-flex align-items-center'>
                    <MarkerPin01Svg className='flex-shrink-0 display-block width-1dot25rem height-1dot25rem color-gray-400'/>
                    <div className='text-md medium color-gray-500 margin-left-0dot5rem overflow-wrap-break-word'>
                        {i18ns(fPile.get_highlightResult, 'location', locale)
                            ? <Focus {...{value: i18ns(fPile.get_highlightResult, 'location', locale).value}}/> 
                            : i18n(fServiceById, 'location', locale)
                        }
                    </div>
                </div>
                :
                <div className='margin-top-0dot75rem display-flex align-items-center'>
                    <MarkerPin01Svg className='flex-shrink-0 display-block width-1dot25rem height-1dot25rem color-gray-400'/>
                    <div className='text-md medium color-gray-500 margin-left-0dot5rem overflow-wrap-break-word'>
                        {i18n(fServiceById, 'location', locale)}
                    </div>
                </div>
                }
                </>
                }
            </Link>
            <div className='padding-top-2rem'>
                <RouterLinkButton to={serviceUrl} plain='true' color='primary' iconPosition='right'>
                    <FormattedMessage defaultMessage='Buy ticket' />
                    <ArrowUpRightSvg />
                </RouterLinkButton>
            </div>
        </div>
    );
});

const breakpointCols = {default: 3, 767: 1};
const SearchServicesImpl = React.memo(({viewer}) => {
    const {data, loadNext, hasNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment searchServicesImpl_viewer on Viewer @refetchable(queryName: "searchServicesImplQuery") {
                searchServices(first: $first, after: $after, contain: $contain) @connection(key: "searchServices_searchServices") {
                    edges {
                        node {
                          service {
                            id
                          }
                          ...searchServiceEntry_pileService
                        }
                    }
                    searchResult {
                        nbHits
                    }
                }
            }
        `,
        viewer
    );
    const {contain} = useQuery();
    const [isPending, startTransition] = React.useTransition();
    const isMount = React.useRef(true);
    React.useEffect(() => {
        if (isMount.current) {
            isMount.current = false;
        } else {
            startTransition(() => {
                refetch({contain});
            });
        }
    }, [contain, isMount]);
    return (
        <>
        <div className='position-relative background-color-gray-50'>
            <div className='block padding-bottom-4rem-6rem main-title--block'>
                <div className='padding-top-2rem main-title--container'>
                    <h1 className='color-primary-600 text-sm medium margin-bottom-0dot75rem'>
                        <span className='padding-top-0dot25rem padding-bottom-0dot25rem padding-left-0dot75rem padding-right-0dot75rem background-color-primary-50 border-radius-1rem'>
                            <FormattedMessage defaultMessage='Poster'/>
                        </span>
                    </h1>
                    <h2 className='display-lg semibold color-gray-900'>
                        {data.searchServices.searchResult?.nbHits || '0'}{' '}майбутніх подій
                    </h2>
                </div>
            </div>
        </div>
        <div className='background-color-gray-50 padding-bottom-4rem'>
            <div className='view'>
                <div className='block'>
                    <SuspenseList revealOrder='forwards'>
                        <Masonry
                            breakpointCols={breakpointCols}
                            className='masonry-grid'
                            columnClassName='masonry-grid_column'
                        >
                            {data.searchServices.edges.map(e => e.node).filter(e => !!e.service).map((pileService) =>
                                <div key={pileService.service.id}>
                                    <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                        <React.Suspense fallback={
                                            <div className='background-color-white border-radius-0dot75rem padding-left-1dot5rem padding-top-1dot5rem padding-right-1dot5rem padding-bottom-1dot5rem shadow-large-light'>
                                                <Skeleton/>
                                            </div>
                                        }>
                                            <SearchServiceEntry {...{pileService}} />
                                        </React.Suspense>
                                    </ErrorBoundary>
                                </div>
                            )}
                        </Masonry>
                        <div/>
                    </SuspenseList>
                    {hasNext &&
                    <div className='margin-top-1dot5rem display-flex justify-content-center'>
                        <Button {...{size: 'xl', color:'secondary-gray', disabled: isLoadingNext, clickHandler: () => !isLoadingNext && loadNext(number || 8)}}>
                            <FormattedMessage defaultMessage='More events' />
                            {isLoadingNext && '...'}
                        </Button>
                    </div>
                    }
                </div>
            </div>
        </div>
        </>
    );
});

const number = process.env.NODE_ENV === 'development' ? 2 : null;
const SearchServices = React.memo(() => {
    const {contain} = useQuery();
    const containMemo = React.useMemo(() => contain, []);
    const data = useLazyLoadQuery(
        graphql`
            query searchServicesQuery($first: Int!, $after: String, $contain: String) {
                viewer {
                    ...searchServicesImpl_viewer
                }
            }
        `,
        {first: number || 8, contain: containMemo}
    );
    return (
        <SearchServicesImpl {...{viewer: data.viewer}}/>
    );
});

const Switcher = React.memo(() => {
    const {contain} = useQuery();
    const containDeferred = React.useDeferredValue(contain);
    const flag = contain !== containDeferred;
    return (
        <>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={
                <div className='position-relative background-color-gray-50'>
                    <div className='block padding-bottom-4rem-6rem main-title--block'>
                        <div className='padding-top-2rem main-title--container'>
                            <Skeleton/>
                        </div>
                    </div>
                </div>
            }>
                <div className={classNames({'opacity-0dot6': flag})}>
                    {!!containDeferred ? 
                        <>
                        <SearchServices/> 
                        </>
                        : 
                        <>
                        <div className='position-relative background-color-gray-50'>
                            <div className='block padding-bottom-4rem-6rem main-title--block'>
                                <div className='padding-top-2rem main-title--container'>
                                    <h1 className='color-blue-gray-500 text-sm mw768-text-md semibold margin-bottom-0dot5rem mw768-margin-bottom-1rem'>
                                        <FormattedMessage defaultMessage='Poster'/> | Ottry Tickets
                                    </h1>
                                    <h2 className='display-sm mw768-display-lg semibold color-gray-900'><FormattedMessage defaultMessage='All upcoming events'/></h2>
                                </div>
                            </div>
                        </div>
                        <div className='background-color-gray-50 padding-bottom-4rem'>
                            <div className='view'>
                                <div className='block'>
                                    <ServiceList/>
                                </div>
                            </div>
                        </div>
                        </>
                    }
                </div>           
            </React.Suspense>
        </ErrorBoundary>
        </>
    );
});

const config = {shouldValidate: true, shouldDirty: true};
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const Search = () => {
    const {contain} = useQuery();
    const location = useLocation();
    const navigate = useNavigate();
    const {register, handleSubmit, setFocus, setValue, watch} = useForm({defaultValues: {contain}});
    const cntn = watch('contain');
    const submit = handleSubmit(data => {
        navigate(`${location.pathname}${searchFilter(location.search, {contain: data.contain}, false)}`);
    });
    return (
        <>
        <Helmet>
            <title>Купити квитки - швидко та легко на Ottry.com | Афіша 2024</title>
            <meta name='description' content='⭐ Замовляйте квитки на улюблені вечірки, концерти, театральні постановки та спортивні події в нашому онлайн-сервісі ✅ Швидко, легко та зручно!'/>
        </Helmet>
        <div className='position-relative background-color-gray-50'>
            <div className='padding-top-1dot5rem mw768-padding-top-6rem'>
                <div className='view'>
                    <div className='block'>
                        <div className='display-flex'>
                            <div className='flex-1 mw768-flex-grow-0 mw768-flex-shrink-0 mw768-flex-basis-50percent'>
                                <form onSubmit={submit}>
                                    <div className='form_beta'>
                                        <div className='margin-top-0dot375rem'>
                                            <div className='input background-color-white border-1px-solid border-color-gray-300 border-radius-0dot5rem display-flex align-items-center padding-left-0dot875rem padding-top-0dot625rem padding-bottom-0dot625rem padding-right-0dot875rem'>
                                                <SearchLgSvg
                                                    className='color-gray-500 cursor-pointer display-block width-1dot25rem height-1dot25rem'
                                                    onClick={() => {
                                                        submit();
                                                        setFocus('contain');
                                                    }}
                                                />
                                                <div className='flex-1 padding-left-0dot5rem padding-right-0dot5rem'>
                                                    <input
                                                        className='width-100percent text-md color-gray-500 display-block'
                                                        type='text'
                                                        placeholder='Search'
                                                        {...register('contain')}
                                                    />
                                                </div>
                                                {cntn && 
                                                <XCircleSvg
                                                    className='color-gray-500 cursor-pointer display-block width-1dot25rem height-1dot25rem'
                                                    onClick={() => {
                                                        setValue('contain', '', config);
                                                        setFocus('contain');
                                                    }}
                                                />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className='display-flex padding-top-0dot25rem'>
                            <div className='flex-1 mw768-flex-grow-0 mw768-flex-shrink-0 mw768-flex-basis-50percent'>
                                <div className='display-flex justify-content-flex-end'>
                                    <AlgoliaLogoBlueSvg
                                        className='display-block'
                                        style={{width: 52.7, height: 12}}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Switcher/>
        <Faq/>
        <div className='margin-top-4rem padding-bottom-4rem-6rem gradient-grey-900-600-45deg-gradient-grey-800-700-26dot5deg'>
            <div className='view'>
                <div className='block'>
                    <div>
                        <React.Suspense fallback={
                            <div className='padding-top-4rem-6rem'>
                                <Skeleton/>
                            </div>
                        }>
                            <div className='display-flex flex-direction-column-row padding-top-4rem-6rem'>
                                <div className='mw768-flex-1'>
                                    <div className='display-sm semibold color-white'>
                                        <FormattedMessage defaultMessage='Want to'/>
                                        {' '}
                                        <span className='background-mesh-gradient-04'><FormattedMessage defaultMessage='sell online?'/></span>
                                    </div>
                                    <div className='margin-top-1rem-1dot25rem text-lg color-primary-25'>
                                        <FormattedMessage defaultMessage='Sign up today and start selling online tomorrow.' />
                                    </div>
                                </div>
                                <div className='margin-top-2rem mw768-margin-top-0 display-flex justify-content-stretch-center flex-direction-column-row'>
                                    <div className='mw768-text-align-center'>
                                        <LinkButton link={{target: '_blank', rel: 'noopener noreferrer', href: formLink}} color='primary-success-gradient' size='xl' fluid='mobile'>
                                            <FormattedMessage defaultMessage='Start sales'/>
                                        </LinkButton>
                                    </div>
                                    <div className='margin-left-0-0dot75rem margin-top-0dot75rem-0 text-align-center'>
                                        <LinkButton link={{target: '_blank', rel: 'noopener noreferrer', href: TELEGRAM_SUPPORT}} color='secondary-gray-dark' size='xl'  fluid='mobile'>
                                            <FormattedMessage defaultMessage='Learn more'/>
                                        </LinkButton>
                                    </div>
                                </div>
                            </div>
                        </React.Suspense>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default React.memo(Search);