import React from 'react';
import {useParams, useLocation} from 'react-router-dom';
// import ErrorBoundary from '../../ErrorBoundary';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import A from './a/A';
import B from './b/B';
import C from './c/C';
import D from './d/D';
import e from '../../../pages/warp/e';
import {ErrorBoundary} from 'react-error-boundary';
import ErrorFallbackHomePage from '../../ErrorFallbackHomePage';
import Skeleton from '../../Skeleton';
import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../constants';
import getTagValue from '../../../utilities/get-tag-value';
import getServiceUrl from '../../../utilities/get-service-url';
import EffectRedirect from '../../EffectRedirect';

const components = {
    'A': A,
    'B': B,
    'C': C,
    'D': D,
    'E': e
};

const Warp = React.memo(() => {
    const {payload} = useParams();
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query WarpServiceQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    warp
                    slug
                    description
                }
            }
        `,
        {payload: decodeURIComponent(payload)}
    );
    // https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
    const SpecificWarp = components[data.serviceByIdOrSlug.warp] || A;
    const serviceTags = data.serviceByIdOrSlug.description && data.serviceByIdOrSlug.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) || [];
    const isNewService = !!getTagValue(serviceTags, 'theme');
    if (isNewService) {
         const serviceUrl = getServiceUrl({description: data.serviceByIdOrSlug.description, id: data.serviceByIdOrSlug.id, slug: data.serviceByIdOrSlug.slug, warp: data.serviceByIdOrSlug.warp, location});
         return <EffectRedirect to={`${serviceUrl}`} replace/>
    }
    return (
        <SpecificWarp id={data.serviceByIdOrSlug.id}/>
    );
});

export default React.memo(() =>
    <ErrorBoundary {...{FallbackComponent: ErrorFallbackHomePage}}>
        <React.Suspense fallback={'Loading...'}>
            <Warp/>
        </React.Suspense>
    </ErrorBoundary>
);