export const FORM_FIELDS = {
    languageUk: {
        placeholderUk: 'Українською мовою',
        placeholder: 'in Ukrainian'
    },
    languageEn: {
        placeholderUk: 'Англійською мовою',
        placeholder: 'In English'
    },
    slug: {
        placeholderUk: 'англійською, доступні маленькі літери, цифри і дефіс',
        placeholder: 'in English, lowercase letters, numbers and hyphens are available'
    }
}