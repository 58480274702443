import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import Marked from '../../../Marked';

const Description = React.memo(({service}) => {
    const fService = useFragment(
        graphql`
            fragment DescriptionCabinetService_service on Service {
                id
                description
                descriptionUk
                version
            }
        `,
        service
    );
    const {register, handleSubmit, setValue, getValues} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation DescriptionCabinetServiceMutation($request: UpdateServiceDescriptionRequestInput) {
                updateServiceDescription(request: $request) {
                    id
                    description
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            description: data.input
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    const append = (value) => {
        setValue('input', `${getValues('input') ? getValues('input') + ', ' + value : value}`);
    }
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Description'/> (<FormattedMessage defaultMessage='Tags'/>)
        </div>
        <div className='margin-top-0dot5rem'><Marked content={fService.description}/></div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <span><FormattedMessage defaultMessage='Add tags separated by comma'/></span>
                </p>
                <form onSubmit={submit}>
                    <div  className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.description}
                            {...register('input')}
                        />
                    </div>
                    {/* <div>
                        <input
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fService.descriptionUk}
                            {...register('inputUk')}
                        />
                    </div> */}
                    <div className='display-flex margin-bottom-1rem'>
                        <div
                            className='button primary'
                            onClick={() => append('btnAnchorTickets')}
                        >
                            btnAnchorTickets
                        </div>
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Description);
