import React from 'react';
import './Impulseticks.scss';
import moment from 'moment';
import {graphql, useLazyLoadQuery, useMutation, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import useQuery from '../../../useQuery';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import LocationPending from '../../LocationPending';
import qs from 'qs';
import {isTrue, length, timeZone} from '../../../utility';
import ErrorFallback from '../../ErrorFallback';

const DECLINED = 3;
const IMPULSETICKS_STATUSES = {
    0: 'Активний',
    1: 'Завершений',
    2: 'Очікуючий',
    3: 'Відхилений'
}

const dim = (value) => value ? value.split(',').map(Number) : [];

const Impulsetick = React.memo(({impulsetick}) => {
    const fImpulsetick = useFragment(
        graphql`
            fragment ImpulseticksImpulsetickImpl_impulsetick on Impulsetick {
                id
                version
                status
                tick
                impulse {
                    id
                    amount
                }
                tries {
                    responseDescription
                    orderStatus
                }
            }
        `,
        impulsetick
    );
    const [tryStatus, tryingStatus] = useMutation(
        graphql`
            mutation ImpulseticksMutation($request: TryImpulsetickRequestInput) {
                tryImpulsetick(request: $request) {
                    id
                    version
                    status
                }
            }
    `);
    return (
        <>
        <div className='margin-top-1rem'>
            {fImpulsetick.id} - <span className={classNames('color-grey font-weight-bold', {'color-firebrick': fImpulsetick.status === 3, 'color-green': fImpulsetick.status === 1})}>{IMPULSETICKS_STATUSES[fImpulsetick.status] || fImpulsetick.status}</span>
        </div>
        <div className='margin-top-1rem font-size-14px'>
            <span className='color-grey'><FormattedMessage defaultMessage='Order'/></span>
            <span className='font-weight-bold padding-left-0dot5rem'> {fImpulsetick.impulse.id}</span>
        </div>
        <div className='margin-top-0dot5rem font-size-14px'>
            <span className='color-grey'><FormattedMessage defaultMessage='Order amount'/></span>
            <span className='font-weight-bold padding-left-0dot5rem'> {fImpulsetick.impulse.amount} UAH</span>
        </div>
        <div className='margin-top-1rem font-weight-bold'>
            {moment(fImpulsetick.tick).format('DD.MM.YYYY HH:mm:ss')}
        </div>
        {length(fImpulsetick.tries) &&
        <div className='margin-top-1rem'>
            {fImpulsetick.tries.map((e, i) =>
                <div key={i} className='margin-top-0dot5rem'>
                    <span className={classNames('margin-top-0dot5rem font-weight-bold color-grey', {'color-firebrick': e.orderStatus === 'declined', 'color-green': e.orderStatus === 'approved'})}>{e.orderStatus}</span>
                    {e.responseDescription && <span className='margin-top-0dot5rem color-grey font-size-14px'> ({e.responseDescription})</span>}
                </div>
            )}
        </div>
        }
        {fImpulsetick.status === DECLINED &&
        <div className='margin-top-1rem'>
            <div className='display-flex'>
                <div
                    className='button primary'
                    onClick={() => !tryingStatus && tryStatus({
                        variables: {request: {id: fImpulsetick.id, version: fImpulsetick.version}},
                        onCompleted: () => {setStatusFlag(false)},
                        onError: () => {},
                        updater: () => {}
                    })}
                >
                    <FormattedMessage defaultMessage='Try'/>{tryingStatus && '...'}
                </div>
            </div>
        </div>
        }
        </>
    );
});


const Impulseticks = React.memo(({request}) => {
    const {more} = useQuery();
    const {size, page, status} = request;
    const data = useLazyLoadQuery(
        graphql`
            query ImpulseticksQuery($request: ImpulseticksRequestInput) {
                impulseticks(request: $request) {
                    totalPages
                    totalElements
                    content {
                        id
                        ...ImpulseticksImpulsetickImpl_impulsetick
                    }
                }
            }
        `,
        {
            request: {
                size: parseInt(size),
                page: parseInt(page),
                status: dim(status)
            }
        }
    );
    const location = useLocation();
    const navigate = useNavigate();
    const {register, handleSubmit} = useForm();
    const submit = handleSubmit(data => {
        navigate(`${location.pathname}${searchFilter(location.search, {
            page: 0, 
            status: data.status.join(',')
        }, false)}`);
    });
    const [flag, setFlag] = React.useState(false);
    return (
        <>
            <form className='form border-bottom-1px-dotted-black padding-bottom-1rem' onSubmit={submit}>
                <div>
                    <div className='display-flex'>
                        <div
                            className='button'
                            onClick={() => setFlag(state => !state)}
                        ><FormattedMessage defaultMessage='Filters'/></div>
                    </div>
                    <div className={classNames('inline', {'display-none': !flag})}>
                        <div className='checkbox display-flex flex-wrap-wrap margin-top-1rem'>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(0)} value={0} {...register('status')}/>
                                <FormattedMessage defaultMessage='Active'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(2)} value={2} {...register('status')}/>
                                <FormattedMessage defaultMessage='Pending'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(1)} value={1} {...register('status')}/>
                                <FormattedMessage defaultMessage='Complete'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(3)} value={3} {...register('status')}/>
                                <FormattedMessage defaultMessage='Declined'/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='display-flex'>
                    <div
                        className='button primary'
                        onClick={() => submit()}
                    ><FormattedMessage defaultMessage='Search'/></div>
                </div>
            </form>
            <div className='display-flex flex-wrap-wrap'>
                {data.impulseticks.content.map((impulsetick) =>
                    <div key={impulsetick.id} className='impulseticks__card'>
                        <div className='border-bottom-1px-dotted-black padding-bottom-1rem height-100percent'>
                            <Impulsetick {...{impulsetick}}/>
                        </div>
                    </div>
                )}
                {!data.impulseticks.content.length &&
                <span><FormattedMessage defaultMessage='No data for now'/></span>
                }
            </div>
            <div className='display-flex justify-content-flex-end flex-wrap-wrap'>
                {[...new Array(data.impulseticks.totalPages)].map((x, i) =>
                    <Link
                        key={i}
                        className={classNames('impulseticks__pager-item', {
                            'impulseticks__pager-item-active': parseInt(page) === i
                        })}
                        to={`${location.pathname}${searchFilter(location.search, {page: i}, false)}`}
                    >{i + 1}</Link>
                )}
                <div>{'| '}page</div>
            </div>
            <div className='display-flex justify-content-flex-end flex-wrap-wrap'>
                <Link
                    className={classNames('impulseticks__pager-item', {
                        'impulseticks__pager-item-active': parseInt(size) === 2
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 2}, false)}`}
                >2</Link>
                <Link
                    className={classNames('impulseticks__pager-item', {
                        'impulseticks__pager-item-active': parseInt(size) === 4
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 4}, false)}`}
                >4</Link>
                <Link
                    className={classNames('impulseticks__pager-item', {
                        'impulseticks__pager-item-active': parseInt(size) === 8
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 8}, false)}`}
                >8</Link>
                <div>{'| '}per page</div>
            </div>
            <div className='display-flex justify-content-flex-end'>
                <div>{data.impulseticks.totalElements} total elements</div>
            </div>
            {isTrue(more) && <Download {...{contain, inbox, sent, status}}/>}
        </>
    );
});

export default React.memo(() => {
    const {size = '40', page = '0', status = '3'} = useQuery();
    const request = {size, page, status};
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-2dot5rem font-weight-bold margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='Orders'/> p2p
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense
                    fallback={
                        <div className='display-flex flex-wrap-wrap'>
                            {[1,2,3].map(i =>
                                <div key={i} className='card'>
                                    <div className='padding-bottom-1rem'>
                                        <Skeleton/>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                >
                    <LocationPending {...{data: request}}>
                        {deferredData =>
                            <Impulseticks {...{request: deferredData}}/>
                        }
                    </LocationPending>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});