/**
 * @generated SignedSource<<b8ea6f7e4a851aa9a4ae570bd64cf489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LatestImpulsesQrs_impulse",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImpulseEndpoint",
      "kind": "LinkedField",
      "name": "impulseEndpoints",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numbers",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "qrs",
      "storageKey": null
    }
  ],
  "type": "Impulse",
  "abstractKey": null
};

node.hash = "7669bccdb7a31508b381745e63c9ce76";

module.exports = node;
