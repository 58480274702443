import React from 'react'
import './Mister.scss';
import {Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../../Skeleton';
import {CartStateContext, CartDispatchContext} from '../../../../contexts/cart';
import {useNavigate} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from './../../../Marked';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../../utility';
import ErrorFallback from '../../../ErrorFallback';

const fancyId = process.env.NODE_ENV === 'development' ? '61711016cfda01765ad09627' : '61710e6456ad07763a191db1';
const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

const MisterEndpoint = React.memo(({endpoint, serviceById}) => {
    const location = useLocation();
    const {locale = 'uk', more} = useQuery();
    const navigate = useNavigate();
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    const fEndpoint = useFragment(
        graphql`
            fragment MisterEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
            }
        `,
        endpoint
    );
    const fServiceById = useFragment(
        graphql`
            fragment MisterEndpoint_serviceById on Service {
                id
            }
        `,
        serviceById
    );
    const count = React.useMemo(() =>
            state
                .filter(e => e.serviceId === fServiceById.id && e.endpointId === fEndpoint.id)
                .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
        [state, fEndpoint.id, fServiceById.id]
    );
    return (
        <div className='margin-top-1rem'>
            <div className='font-weight-bold'>
                {i18n(fEndpoint, 'name', locale)} {fEndpoint.price} UAH
            </div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{fEndpoint.id}</div>}
            {(fEndpoint.quantity < 16 || isTrue(more)) && <div className='input-warning'>{fEndpoint.quantity} <FormattedMessage defaultMessage='left'/></div>}
            <div className='display-flex margin-top-1rem'>
                <div
                    className='button primary'
                    onClick={() => {
                        !count && dispatch({
                            type: 'add',
                            payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id}
                        });
                        navigate(`/checkout-new/cart${searchFilter(location.search)}`);
                    }}
                >
                    {count ? <FormattedMessage defaultMessage='Already in the cart, go'/> : <FormattedMessage defaultMessage='Buy'/>}
                </div>
            </div>
        </div>
    );
});

const Mister = React.memo(({id}) => {
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query MisterQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    endpoints {
                        id
                        quantity
                        ...MisterEndpoint_endpoint
                    }
                    ...MisterEndpoint_serviceById
                }
            }
        `,
        {id}
    );
    const {locale = 'uk', more} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    return (
        <>
        <Helmet>
            <title>{i18n(data.serviceById, 'name', locale)}</title>
            <meta name='description' content={i18n(data.serviceById, 'name', locale)}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            <div className='view'>
                <div className='block'>
                    <Link
                        className='focus'
                        to={`/queues${searchFilter(location.search)}`}>
                        &#60; <FormattedMessage defaultMessage='Back'/>
                    </Link>
                    <div className='font-size-2rem font-weight-bold text-align-center margin-bottom-1rem'>
                        {i18n(data.serviceById, 'name', locale)}
                    </div>
                    <Marked {...{content: i18n(data.serviceById, 'details', locale)}}/>
                    {data.serviceById.endpoints.map((endpoint, index) =>
                        <div key={endpoint.id}>
                            {(more || data.serviceById.endpoints[index].quantity > 0)
                            && <MisterEndpoint endpoint={endpoint} serviceById={data.serviceById}/>
                            }
                        </div>
                    )}
                    <br/>
                    <p className='font-size-0dot8rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>
                </div>
            </div>
        </div>
        </>
    )
});

export default React.memo(() => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='mister'>
            <div className='mister__header'>
                <Link to={`/${searchFilter(location.search)}`}><span className='mister__accent font-weight-bold'>Impulse</span> by Ottry</Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='view'>
                        <div className='block'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <Mister {...{id: fancyId}}/>
                </React.Suspense>
            </ErrorBoundary>
            <div className='mister__footer'>
                <div className='mister__footer-line'>
                    <div className='mister__footer-line-left'>&nbsp;</div>
                    <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                    <div className='mister__footer-line-right'>&nbsp;</div>
                </div>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                    >En</a>
                </div>
                <div className='mister__payment'>
                    <img
                        src='https://wfp-help.s3.eu-central-1.amazonaws.com/1568625809_payment_methods_1.png%3Fversion%3D1%26amp%3BmodificationDate%3D1510658069000%26amp%3Bapi%3Dv2'
                        alt=''/>
                </div>
                <div className='mister__footer-content'>
                    <div className='mister__footer-info'>
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <a href={legal + `/rules-${locale}.docx`} download><FormattedMessage defaultMessage='Rules'/></a>
                        <a href={legal + `/terms-${locale}.docx`} download><FormattedMessage defaultMessage='Terms of service'/></a>
                        <a href={legal + `/privacy-${locale}.docx`} download><FormattedMessage defaultMessage='Privacy policy'/></a>
                    </div>
                </div>
                <div className='mister__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
});