import React from 'react';
import {graphql, useMutation} from 'react-relay/hooks';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import {useForm} from 'react-hook-form';
import useMountedState from '../../../useMountedState';
import i18n from '../../../i18n';
import {FORM_FIELDS} from '../DynamicTranslations';
import useQuery from '../../../useQuery';
import ErrorFallback from '../../ErrorFallback';
import getStoreRecordsByMask from '../../../utilities/get-store-records-by-mask';

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;

const Create = () => {
    const {locale = 'uk'} = useQuery();
    const navigate = useNavigate();
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [apply, applying] = useMutation(
        graphql`
            mutation CreateCreateAddlMutation($request: AddlRequestInput) {
                createAddl(request: $request) {
                    id
                }
            }
    `);
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const submit = handleSubmit(data => {
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                apply({
                    variables: {
                        request: {
                            ...data,
                            reCaptcha: token
                        }
                    },
                    onCompleted: (response) => {
                        navigate(`/cabinet/addls/${response.createAddl.id}${searchFilter(location.search)}`);
                    },
                    onError: () => {},
                    updater: store => {
                        const recordIds = getStoreRecordsByMask(store, 'addlsByViewer');
                        if (recordIds.length) {
                            recordIds.forEach(id => {
                                store.get(id).invalidateRecord();
                            })
                        }
                    }
                });
                setGrecaptcha(false);
            });
        });
    });
    return (
        <>
        <form className='form' onSubmit={submit}>
            <div className='input-label'><FormattedMessage defaultMessage='Additional field name'/> <sup>*</sup></div>
            <div className='input'>
                <input
                    className='width-100percent'
                    placeholder={i18n(FORM_FIELDS.languageEn, 'placeholder', locale)}
                    {...register('name', {required: true})}
                />
            </div>
            {errors.name && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
            <div className='input'>
                <input
                    className='width-100percent'
                    placeholder={i18n(FORM_FIELDS.languageUk, 'placeholder', locale)}
                    {...register('nameUk')}
                />
            </div>
            <div className='font-size-0dot8rem color-grey'>
                <FormattedMessage defaultMessage='This site is protected by Google reCAPTCHA'/>, <a className='focus' href='https://policies.google.com/privacy'><FormattedMessage defaultMessage='Privacy Policy'/></a> <FormattedMessage defaultMessage='and'/> <a className='focus' href='https://policies.google.com/terms'><FormattedMessage defaultMessage='Terms of Service'/></a>.
            </div>
            <div className='iwtso__form-action'>
                <div
                    className='button primary'
                    onClick={() => !grecaptcha && !applying && submit()}
                ><FormattedMessage defaultMessage='Create'/>{(grecaptcha || applying) && '...'}</div>
            </div>
        </form>
        </>
    );
};

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-1rem'>
                <Link className='focus' to={`/cabinet/addls${searchFilter(location.search)}`}>
                    &#60; <FormattedMessage defaultMessage='Additional fields'/>
                </Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<Skeleton/>}>
                    <Create/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
