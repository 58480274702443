import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm, useFieldArray} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';

const Hints = React.memo(({endpoint}) => {
    const fEndpoint = useFragment(
        graphql`
            fragment HintsCabinetEndpoint_endpoint on Endpoint {
                id
                hints
                version
            }
        `,
        endpoint
    );
    const {register, handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            inputs: fEndpoint.hints && fEndpoint.hints.map(e => ({value: e})) || []
        }
    });
    const {fields, remove, append} = useFieldArray({control, name: 'inputs'});
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation HintsCabinetEndpointMutation($request: UpdateEndpointHintsRequestInput) {
                updateEndpointHints(request: $request) {
                    id
                    hints
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fEndpoint.id, version: fEndpoint.version, hints: data.inputs.map(e => e.value)}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Hints'/>
        </div>
        {fEndpoint.hints && !!fEndpoint.hints.length &&
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fEndpoint.hints.join(', ')}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    {fields.map((item, index) => (
                        <div key={item.id} className='input'>
                            <input
                                className='margin-0dot5rem-0 width-100percent'
                                {...register(`inputs.${index}.value`, {required: true})}
                                defaultValue={item.value}
                            />
                            {errors.inputs && errors.inputs[index] && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                            <div className='display-flex'>
                                <div className='button' onClick={() => remove(index)}>
                                    <FormattedMessage defaultMessage='Delete'/>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='display-flex margin-top-0dot5rem'>
                        <div
                            className='button primary'
                            onClick={() => append({value: ''})}
                        >
                            <FormattedMessage defaultMessage='Append'/>
                        </div>
                    </div>
                    <div className='display-flex margin-top-0dot5rem'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Hints);
