import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {isNullOrUndefined} from '../../../../utility';

const Discount = React.memo(({coupon}) => {
    const fCoupon = useFragment(
        graphql`
            fragment DiscountCabinetCoupon_coupon on Coupon {
                id
                discount
                version
            }
        `,
        coupon
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation DiscountCabinetCouponMutation($request: UpdateCouponDiscountRequestInput) {
                updateCouponDiscount(request: $request) {
                    id
                    discount
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fCoupon.id, version: fCoupon.version, discount: data.input === '' ? null : data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='% discount'/>
        </div>
        {!isNullOrUndefined(fCoupon.discount) &&
        <div className='margin-top-0dot5rem font-weight-bold'>{fCoupon.discount}%</div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fCoupon.discount}
                            {...register('input', {pattern: /^\d+(?:[.]\d{1,2})*$/})}
                        />
                    </div>
                    {errors.input && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Discount);
