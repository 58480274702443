import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {isSet} from '../../../../utility';

const MerchantAccountSalt = React.memo(({service}) => {
    const fService = useFragment(
        graphql`
            fragment MerchantAccountSaltCabinetService_service on Service {
                id
                merchantAccount
                salt
                partnerCode
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation MerchantAccountSaltCabinetServiceMutation($request: UpdateServiceMerchantAccountSaltRequestInput) {
                updateServiceMerchantAccountSalt(request: $request) {
                    id
                    merchantAccount
                    salt
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            merchantAccount: data.merchantAccount,
            salt: data.salt,
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Wayforpay settings MerchantAccount / Salt'/>
        </div>
        {(isSet(fService.merchantAccount) || isSet(fService.salt)) &&
        <div className='font-weight-bold margin-top-0dot5rem overflow-wrap-break-word'>
            {fService.merchantAccount} / {fService.salt}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.merchantAccount}
                            placeholder='Merchant account'
                            {...register('merchantAccount')}
                        />
                    </div>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            placeholder='Salt'
                            {...register('salt')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(MerchantAccountSalt);
