/**
 * @generated SignedSource<<b831e2b40b0ecfac63ac4fe0d74ef16f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "updateServiceSlug",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "slug",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SlugCabinetServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SlugCabinetServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d78591259676ba0a91bb00ff49a053ac",
    "id": null,
    "metadata": {},
    "name": "SlugCabinetServiceMutation",
    "operationKind": "mutation",
    "text": "mutation SlugCabinetServiceMutation(\n  $request: UpdateServiceSlugRequestInput\n) {\n  updateServiceSlug(request: $request) {\n    id\n    slug\n    version\n  }\n}\n"
  }
};
})();

node.hash = "0ed154e69626023b97072cfb9f2b0c7b";

module.exports = node;
