import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Chart} from 'react-google-charts';
import {useIntl, FormattedMessage} from 'react-intl';
import {timeZone} from '../../../utility';
import moment from 'moment';
import useQuery from '../../../useQuery';
import useSubscription from '../../../useSubscription';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import ErrorFallback from '../../ErrorFallback';
import useSubscriptionStatus from '../../../useSubscriptionStatus';

const config = {shouldValidate: true, shouldDirty: true};

const ChartInbox = React.memo(({setChartInbox, refreshedQueryOptions, flag}) => {
    const {locale = 'uk'} = useQuery();
    const intl = useIntl();
    const query = useLazyLoadQuery(
        graphql`
            query ChartInboxImpulsesStatisticsQuery($request: ImpulsesStatisticsRequestInput) {
                impulsesStatistics(request: $request) {
                    value
                    size
                    amount
                    average
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const data = React.useMemo(
        () => [['x', intl.formatMessage({defaultMessage: 'Amount'})]]
            .concat(
                query.impulsesStatistics.value.length ?
                    query.impulsesStatistics.value
                        .flatMap((e) => Object.entries(e))
                        .map(e => [moment(e[0]).toDate(), e[1]])
                    :
                    [[moment().toDate(), 0]]

            ),
        [query]
    );
    return (
        <div className={classNames({'opacity-0dot6': flag})}>
            {query.impulsesStatistics.size &&
            <div className='margin-top-0dot5rem text-align-center'>
                <span className='tag tag-purple margin-right-0dot5rem margin-top-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Amount'/> {query.impulsesStatistics.amount} UAH
                </span>
                <span className='tag tag-purple margin-right-0dot5rem margin-top-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Size'/> {query.impulsesStatistics.size}
                </span>
                <span className='tag tag-purple margin-top-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Average'/> {query.impulsesStatistics.average} UAH
                </span>
            </div>
            }
            <Chart
                width={'100%'}
                chartType='LineChart'
                loader={<div className='margin-top-1rem'><Skeleton/></div>}
                data={data}
                options={{
                    focusTarget: 'category',
                    crosshair: {trigger: 'focus', orientation: 'vertical', color: 'lightgrey'},
                    legend: {position: 'bottom'},
                    vAxis: {format: '# UAH'},
                }}
                chartLanguage={locale}
                chartEvents={[
                    {eventName: 'ready', callback: () => {setChartInbox(true);}},
                    {eventName: 'select', callback: ({chartWrapper}) => {
                        chartWrapper.getChart().setSelection();
                    }}
                ]}
            />
        </div>
    );
});

const ChartInboxServices = React.memo(({setChartInbox}) => {
    const data = useLazyLoadQuery(
        graphql`
            query ChartInboxServicesByViewerQuery($size: Int!, $page: Int!) {
                servicesByViewer(size: $size, page: $page) {
                    content {
                        id
                    }
                }
            }
        `,
        {size: 100500, page: 0}
    );
    const services = React.useMemo(() => data.servicesByViewer.content.map((e) => e.id), [data]);
    const from = React.useMemo(() => moment().subtract(1, 'months').startOf('day'), []);
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {
            timeZone: timeZone(),
            inbox: true,
            services,
            fetchKey: 0,
            from,
            to: null
        }}
    });
    const [isPending, startTransition] = React.useTransition();
    const refresh = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        fetchKey: prev.variables.request.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    const payload = React.useMemo(() => ({
        channel: services.map(e => `/services/${e}`).join(','),
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh, services]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    const {register, handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            from: from.format('YYYY-MM-DD')
        }
    });
    const submit = handleSubmit(data => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        from: !data.from ? null : moment(data.from).utc().format(),
                        to: !data.to ? null : moment(data.to).utc().format()
                    }
                }
            }));
        });
    });
    const wfrom = watch('from');
    return (
        <>
        <form onSubmit={submit} className='margin-top-1rem'>
            <p className='margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='Start of sales'/>
            </p>
            <div className='input'>
                <input
                    className='width-100percent'
                    placeholder='2024-12-30 10:00'
                    {...register('from', {
                        validate: value => !value || moment(value).isValid()
                    })}
                />
            </div>
            <p className='margin-top-1rem margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='End of sales'/>
            </p>
            <div className='input'>
                <input
                    className='margin-0dot5rem-0 width-100percent'
                    placeholder='2024-12-30 10:00'
                    {...register('to', {
                        validate: value => !value || moment(value).isValid()
                    })}
                />
            </div>
            <div className='display-flex'>
                <div
                    className='button primary'
                    onClick={() => !isPending && submit()}
                >
                    <FormattedMessage defaultMessage='Filter'/>{isPending && '...'}
                </div>
            </div>
        </form>
        <div className='margin-top-0dot5rem'>
            <a
                className={classNames('link', {
                    'font-weight-bold': moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD') === wfrom
                })}
                onClick={(e) => {
                    e.preventDefault();
                    setValue('from', moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'), config)
                }}
            ><FormattedMessage defaultMessage='7 days'/></a>, <a
                className={classNames('link', {
                    'font-weight-bold': moment().subtract(1, 'months').startOf('day').format('YYYY-MM-DD') === wfrom
                })}
                onClick={(e) => {
                    e.preventDefault();
                    setValue('from', moment().subtract(1, 'months').startOf('day').format('YYYY-MM-DD'), config)
                }}
            ><FormattedMessage defaultMessage='month'/></a>, <a
                className={classNames('link', {
                    'font-weight-bold': moment().subtract(1, 'years').startOf('day').format('YYYY-MM-DD') === wfrom
                })}
                onClick={(e) => {
                    e.preventDefault();
                    setValue('from', moment().subtract(1, 'years').startOf('day').format('YYYY-MM-DD'), config)
                }}
            ><FormattedMessage defaultMessage='year'/></a>
        </div>
        <ChartInbox {...{
            setChartInbox,
            refreshedQueryOptions,
            flag: isPending
        }}/>
        </>
    );
});

export default React.memo(({setChartInbox}) => {
    const subscriptionStatus = useSubscriptionStatus();
    return (
        <>
        <div className='font-weight-bold font-size-2dot5rem text-align-center margin-top-1rem'>
            <span className={classNames('dot margin-right-0dot2rem', {
                'green': subscriptionStatus === 0
            })}/>
            <FormattedMessage defaultMessage='Ordered from you'/>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<div className='margin-top-1rem'><Skeleton/></div>}>
                <ChartInboxServices {...{setChartInbox}}/>
            </React.Suspense>
        </ErrorBoundary>
        </>
    );
});
