import React from 'react'
import './cart.scss';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../components/Skeleton';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import ErrorFallback from '../../../components/ErrorFallback';
import ShoppingBag03 from '../../../assets/icons0/shopping-bag-03.svg';
import XClose from '../../../assets/icons0/x-close.svg';
import Button from '../../../components/button/button';
import {LocalCartFooterDesktop, LocalCartFooterMobile} from './cart-footer';
import Amount from './amount';
import WarpOrderItem from './order-item';

const Cart = ({decrementEndpoint, incrementEndpoint, serviceById, localCartState, cartVisible, closeCart, checkout}) => {
    const localCartStateDeferred = React.useDeferredValue(localCartState);
    return (
        <div className={classNames('pop-up-cart-backdrop', {'show': cartVisible})}>
            <div className={classNames('pop-up-cart position-fixed background-color-white bottom-0 right-0', {'pop-up-cart--visible': cartVisible})}>
                <div className='position-sticky top-0 background-color-white'>
                    <div className='display-flex justify-content-flex-end padding-1dot25rem-1rem mw768-padding-1dot25rem-2dot5rem'>
                        <XClose className='display-block height-1dot5rem width-1dot5rem color-gray-500 cursor-pointer' onClick={closeCart}/>
                    </div>
                    <h3 className='display-sm semibold color-gray-900 padding-left-1dot25rem padding-right-1dot25rem mw768-padding-left-2dot5rem mw768-padding-right-2dot5rem padding-bottom-2rem'>
                        <FormattedMessage defaultMessage='Cart'/>
                    </h3>
                </div>
                <div className='pop-up-cart__content padding-left-1dot25rem padding-right-1dot25rem mw768-padding-left-2dot5rem mw768-padding-right-2dot5rem'>
                    {localCartState.map((e, index) =>
                        <div key={e.endpointId} className={classNames('margin-bottom-1rem', {'margin-bottom-0': index === localCartState.length - 1})}>
                            <React.Suspense fallback={<Skeleton/>}>
                                <WarpOrderItem {...{endpointId: e.endpointId, cartEntry: e, serviceById: serviceById, decrementEndpoint, incrementEndpoint}}/>
                            </React.Suspense>
                        </div>
                    )}
                </div>
                <div className='hide-mobile local-cart-footer position-fixed bottom-0'>
                    <LocalCartFooterDesktop {...{localCartState: localCartStateDeferred, serviceById, deferred: localCartStateDeferred !== localCartState, checkout}}/>
                </div>
            </div>
        </div>
    )
}

const CartButton = ({serviceById, localCartState, decrementEndpoint, incrementEndpoint, checkout}) => {
    const localCartStateDeferred = React.useDeferredValue(localCartState);
    const [cartVisibility, setCartVisibility] = React.useState(false);
    const setCartVisibilityCallback = React.useCallback((value) => setCartVisibility(value));
    React.useEffect(() => {
        if (!localCartState.length) {
            setCartVisibility(false);
        }
    }, [localCartState]);
    return (
        <div className=''>
            <div className='display-none mw768-display-block min-width-11rem'>
                <Button {...{size: 'lg', color: 'primary-gradient', fluid: 'always', additionalClasses: 'cart-btn--disabled', disabled: !localCartState.length, clickHandler: () => setCartVisibility(true)}}>
                    {localCartState.length ?
                        <React.Suspense fallback={
                            <>
                                <ShoppingBag03 className='display-block height-1dot25rem margin-right-0dot5rem opacity-0dot4'/>
                                <span className='opacity-0dot4'>0.00 <FormattedMessage defaultMessage='UAH'/></span>
                            </>
                        }>
                            <ShoppingBag03 className={classNames('display-block height-1dot25rem margin-right-0dot5rem', {'opacity-0dot4': localCartStateDeferred !== localCartState})}/>
                            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                                <Amount {...{localCartState: localCartStateDeferred, serviceId: serviceById.id, deferred: localCartStateDeferred !== localCartState}}/>
                            </ErrorBoundary>
                        </React.Suspense>
                        :
                        <>
                            <ShoppingBag03 className='display-block height-1dot25rem margin-right-0dot5rem'/>
                            <span>0.00 <FormattedMessage defaultMessage='UAH'/></span>
                        </>
                    }
                </Button>
            </div>
            {!!localCartState.length && 
                <>
                    <div className='mw768-display-none local-cart-footer'>
                        <LocalCartFooterMobile {...{localCartState: localCartStateDeferred, serviceById, deferred: localCartStateDeferred !== localCartState, checkout, flag: cartVisibility, setFlag: setCartVisibilityCallback, checkout}}/>
                    </div>
                    <Cart {...{cartVisible: cartVisibility, decrementEndpoint, incrementEndpoint, serviceById, localCartState, deferred: localCartStateDeferred !== localCartState, closeCart: () => setCartVisibilityCallback(false), checkout}}/>
                </>
            }
        </div>
    )
}

export default React.memo(CartButton);