import React from 'react';
import {graphql, useMutation, useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import ShowMoreQueryParam from '../../../ShowMoreQueryParam';

const Services = React.memo(({endpoint}) => {
    const {locale = 'uk'} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query ServicesByViewerCabinetEndpointQuery($size: Int!, $page: Int!) {
                servicesByViewer(size: $size, page: $page) {
                    totalPages,
                    content {
                        id
                        name
                        nameUk
                    }
                }
            }
        `,
        {size: 100500, page: 0}
    );
    const fEndpoint = useFragment(
        graphql`
            fragment ServicesCabinetEndpoint_endpoint on Endpoint {
                id
                services {
                    id
                    name
                    nameUk
                }
                version
            }
        `,
        endpoint
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation ServicesCabinetEndpointMutation($request: UpdateEndpointServicesRequestInput) {
                updateEndpointServices(request: $request) {
                    id
                    services {
                        id
                        name
                        nameUk
                    }
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fEndpoint.id, version: fEndpoint.version, services: data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));

    const [showLinks, setShowLinks] = React.useState(false);
    const copyLink = (link) => {
        if (navigator && navigator.clipboard) {
            navigator.clipboard.writeText(link);
        }
    }
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Attach to event'/>
        </div>
        {fEndpoint.services && !!fEndpoint.services.length &&
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fEndpoint.services.map(e => i18n(e, 'name', locale)).join(', ')}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='display-flex flex-wrap-wrap margin-top-0dot5rem'>
                    {data.servicesByViewer.content.map(e =>
                        <div key={e.id} className='checkbox margin-right-0dot5rem margin-bottom-0dot5rem'>
                            <label>
                                <div><input
                                    defaultChecked={fEndpoint.services && fEndpoint.services.map(e => e.id).includes(e.id)}
                                    value={e.id}
                                    type='checkbox'
                                    {...register('input')}
                                /></div>
                                {i18n(e, 'name', locale)}
                            </label>
                        </div>
                    )}
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
            {fEndpoint.services && !!fEndpoint.services.length &&
                <ShowMoreQueryParam>
                    <div className='padding-top-1rem'>
                        <label htmlFor='show-links'>
                            <input
                                type='checkbox'
                                id='show-links'
                                onChange={() => setShowLinks(!showLinks)}
                            />
                            <span className='padding-left-0dot5rem'><FormattedMessage defaultMessage='Show generated links for payment link integration'/></span>
                        </label>
                    </div>
                    {showLinks && fEndpoint.services.map(e => 
                        <div className='padding-top-0dot5rem' key={e.id}>
                            <p className='font-size-0dot8rem'>{i18n(e, 'name', locale)}</p>
                            <p className='font-weight-bold cursor-pointer font-size-0dot8rem' title='Click to copy' onClick={() => copyLink(`https://ottry.com/checkout/integration?type=payment-link&c[0][s]=${e.id}&c[0][e]=${fEndpoint.id}&c[0][q]=1`)}>
                                {`https://ottry.com/checkout/integration?type=payment-link&c[0][s]=${e.id}&c[0][e]=${fEndpoint.id}&c[0][q]=1`}
                            </p>
                        </div>
                    )}
                </ShowMoreQueryParam>
            }
        </div>
        </>
    );
});
export default React.memo(Services);
