import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {FORM_FIELDS} from '../../DynamicTranslations';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';

const Name = React.memo(({addl}) => {
    const {locale = 'uk'} = useQuery();
    const fAddl = useFragment(
        graphql`
            fragment NameCabinetAddl_addl on Addl {
                id
                name
                nameUk
                version
                merchant {
                    id
                }
            }
        `,
        addl
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation NameCabinetAddlMutation($request: UpdateAddlNameRequestInput) {
                updateAddlName(request: $request) {
                    id
                    name
                    nameUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fAddl.id, version: fAddl.version, name: data.input, nameUk: data.inputUk}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Additional field name'/>
        </div>
        <div className='font-weight-bold margin-top-0dot5rem'>{i18n(fAddl, 'name', locale)}</div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fAddl.name}
                            placeholder={i18n(FORM_FIELDS.languageEn, 'placeholder', locale)}
                            {...register('input', {required: true})}
                        />
                    </div>
                    {errors.input &&
                    <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fAddl.nameUk}
                            placeholder={i18n(FORM_FIELDS.languageUk, 'placeholder', locale)}
                            {...register('inputUk')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className={classNames('button primary', {'disabled': !fAddl.merchant})}
                            onClick={() => fAddl.merchant && !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Name);
