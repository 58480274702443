import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {useParams, Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import Publish from './properties/Publish';
import Untie from './properties/Untie';
import Price from './properties/Price';
import Quantity from './properties/Quantity';
import Description from './properties/Description';
import Details from './properties/Details';
import Name from './properties/Name';
import Hints from './properties/Hints';
import Season from './properties/Season';
import Services from './properties/Services';
import classNames from 'classnames';
import ShowMoreQueryParam from '../../ShowMoreQueryParam';
import Tags from './properties/Tags';
import ErrorFallback from '../../ErrorFallback';

const Endpoint = () => {
    const {id} = useParams();
    const query = useLazyLoadQuery(
        graphql`
            query EndpointByIdQuery($id: String) {
                endpointById(id: $id) {
                    id
                    untie
                    ...PublishCabinetEndpoint_endpoint
                    ...UntieCabinetEndpoint_endpoint
                    ...PriceCabinetEndpoint_endpoint
                    ...QuantityCabinetEndpoint_endpoint
                    ...DescriptionCabinetEndpoint_endpoint
                    ...DetailsCabinetEndpoint_endpoint
                    ...NameCabinetEndpoint_endpoint
                    ...ServicesCabinetEndpoint_endpoint
                    ...HintsCabinetEndpoint_endpoint
                    ...SeasonCabinetEndpoint_endpoint
                    ...TagsCabinetEndpoint_endpoint
                }
            }
        `,
        {id}
    );
    return (
        <>
        <Name {...{endpoint: query.endpointById}}/>
        <React.Suspense fallback={<Skeleton/>}>
            <Services {...{endpoint: query.endpointById}}/>
        </React.Suspense>
        <ShowMoreQueryParam>
            <Untie {...{endpoint: query.endpointById}}/>
            <div className={classNames({'opacity-0dot2': !query.endpointById.untie})}>
                <Hints {...{endpoint: query.endpointById}}/>
            </div>
        </ShowMoreQueryParam>
        <div className={classNames({'opacity-0dot2': query.endpointById.untie})}>
            <Price {...{endpoint: query.endpointById}}/>
        </div>
        <Quantity {...{endpoint: query.endpointById}}/>
        <ShowMoreQueryParam>
            <Details {...{endpoint: query.endpointById}}/>
            <Description {...{endpoint: query.endpointById}}/>
        </ShowMoreQueryParam>
        <Season {...{endpoint: query.endpointById}}/>
        <ShowMoreQueryParam>
            <Tags {...{endpoint: query.endpointById}}/>
            <Publish {...{endpoint: query.endpointById}}/>
        </ShowMoreQueryParam>
        </>
    );
};

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-1rem'>
                <Link className='focus' to={`/cabinet/endpoints${searchFilter(location.search)}`}>
                    &#60; <FormattedMessage defaultMessage='Tickets'/>
                </Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<Skeleton/>}>
                    <Endpoint/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
