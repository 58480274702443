import React from 'react';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import {ErrorBoundary} from 'react-error-boundary';
import {length} from '../../../utility';
import useSubscription from '../../../useSubscription';
import ErrorFallback from '../../ErrorFallback';
import {graphqlWhoamiNextQuery} from '../../graphql';
import useSubscriptionStatus from '../../../useSubscriptionStatus';

const Watcher = React.memo(({refresh}) => {
    const {whoamiNext} = useLazyLoadQuery(graphqlWhoamiNextQuery);
    const payload = React.useMemo(() => ({
        channel: `/merchant/${whoamiNext.id}/poolticks`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return null;
});

const PoolticksImpl = React.memo(({flag, refreshedQueryOptions, refresh, refreshPools, pools}) => {
    const data = useLazyLoadQuery(
        graphql`
            query SyncPoolticksImplPoolsCabinetQuery($poolticksRequest: RecurringPoolticksRequestInput, $poolsRequest: PoolsRequestInput) {
                recurringPoolticksByViewer(request: $poolticksRequest) {
                    content {
                        id
                    }
                }
                poolsByViewer(request: $poolsRequest) {
                    content {
                        id
                        cardPan
                        cardType
                        merchantId
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    // console.log(JSON.stringify(data.poolsByViewer.content.map((e) => e.id).sort()), JSON.stringify(pools.sort()));
    return (
        <>
        <Watcher {...{refresh}}/>
        {length(data.recurringPoolticksByViewer.content) &&
        <div
            className='padding-0dot4rem-0dot8rem margin-right-0dot5rem'
        >...</div>
        }
        {(
            JSON.stringify(data.poolsByViewer.content.map((e) => e.id).sort()) !==
            JSON.stringify(pools.sort())
        ) &&
        <div
            className='button primary margin-right-0dot5rem'
            onClick={() => {
                refreshPools();
            }}
        >Refresh</div>
        }
        </>
    );
});

const Poolticks = React.memo(({merchantId, refreshPools, pools}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {
            poolticksRequest: {
                size: 100500,
                page: 0,
                status: [0],
                merchantId,
                fetchKey: 0
            },
            poolsRequest: {
                size: 100500,
                page: 0,
                merchantId,
                fetchKey: 0
            }
        }
    });
    const [isPending, startTransition] = React.useTransition();
    const refresh = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    poolticksRequest: {
                        ...prev.variables.poolticksRequest,
                        fetchKey: prev.variables.poolticksRequest.fetchKey + 1
                    },
                    poolsRequest: {
                        ...prev.variables.poolsRequest,
                        fetchKey: prev.variables.poolsRequest.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    return (
        <PoolticksImpl {...{
            refreshedQueryOptions,
            flag: isPending,
            refresh,
            refreshPools,
            pools
        }}/>
    );
});

export default React.memo(({merchantId, refreshPools, pools}) =>
    <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
        <React.Suspense fallback={'Loading...'}>
            <Poolticks {...{merchantId, refreshPools, pools}}/>
        </React.Suspense>
    </ErrorBoundary>
);

// const Watcher = React.memo(({poolticks, refresh}) => {
//     const payload = React.useMemo(() => ({
//         channel: poolticks.map(e => `/poolticks/${e}`).join(','),
//         onNext: () => refresh(),
//         onError: (error) => {}
//     }), [refresh, poolticks]);
//     useSubscription(payload);
//     const subscriptionStatus = useSubscriptionStatus();
//     React.useEffect(() => {
//         if (subscriptionStatus === 0)
//             refresh();
//     }, [subscriptionStatus, refresh]);
//     return null;
// });
//
// const PoolticksImpl = React.memo(({flag, refreshedQueryOptions, refresh, refreshPools}) => {
//     const data = useLazyLoadQuery(
//         graphql`
//             query SyncPoolticksImplPoolsFancyRootComponetsQuery($request: PoolticksRequestInput) {
//                 poolticksByViewer(request: $request) {
//                     content {
//                         id
//                     }
//                 }
//             }
//         `,
//         refreshedQueryOptions.variables,
//         refreshedQueryOptions.options
//     );
//     const poolticks = React.useMemo(() => data.poolticksByViewer.content.map((e) => e.id),
//         [JSON.stringify(data.poolticksByViewer.content.map((e) => e.id))]);
//     const [ack, setAck] = React.useState(poolticks);
//     return (
//         <>
//         {length(poolticks) &&
//         <>
//         <div>...</div>
//         <Watcher {...{poolticks, refresh}}/>
//         {!ack.some(e => poolticks.includes(e)) &&
//         <div
//             onClick={() => {
//                 setAck(poolticks);
//                 refreshPools();
//             }}
//         >Refresh</div>
//         }
//         </>
//         }
//         </>
//     );
// });

