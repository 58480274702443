import React from 'react';
import './NotFound.scss';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../searchFilter';

function NotFound() {
    const location = useLocation();
    return (
        <div className='cabinet-not-found'>
            <div className='cabinet-not-found__content'>
                <h1 className='cabinet-not-found__headline'>
                    <FormattedMessage defaultMessage='Our apologies.'/>
                    <br/><FormattedMessage defaultMessage="We're unable to find the page you're looking for."/>
                </h1>
                <div className='cabinet-not-found__error-code'><FormattedMessage defaultMessage='404. Page not found.'/></div>
                <div className='display-flex justify-content-center'>
                    <Link
                        className='focus font-weight-bold'
                        to={`/cabinet${searchFilter(location.search)}`}
                    ><FormattedMessage defaultMessage='Go to home page'/></Link>
                </div>
            </div>
        </div>
    );
}

export default React.memo(NotFound);
