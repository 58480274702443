/**
 * @generated SignedSource<<8f1ab7862aea7860278ea44d37ff9a98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "request",
    "variableName": "request"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalPages",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalElements",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImpulseticksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Page_Impulsetick",
        "kind": "LinkedField",
        "name": "impulseticks",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Impulsetick",
            "kind": "LinkedField",
            "name": "content",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ImpulseticksImpulsetickImpl_impulsetick"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImpulseticksQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Page_Impulsetick",
        "kind": "LinkedField",
        "name": "impulseticks",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Impulsetick",
            "kind": "LinkedField",
            "name": "content",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tick",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Impulse",
                "kind": "LinkedField",
                "name": "impulse",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "amount",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImpulsetickTry",
                "kind": "LinkedField",
                "name": "tries",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "responseDescription",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderStatus",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "96b391bf8d87666c23183f6c157ca0f2",
    "id": null,
    "metadata": {},
    "name": "ImpulseticksQuery",
    "operationKind": "query",
    "text": "query ImpulseticksQuery(\n  $request: ImpulseticksRequestInput\n) {\n  impulseticks(request: $request) {\n    totalPages\n    totalElements\n    content {\n      id\n      ...ImpulseticksImpulsetickImpl_impulsetick\n    }\n  }\n}\n\nfragment ImpulseticksImpulsetickImpl_impulsetick on Impulsetick {\n  id\n  version\n  status\n  tick\n  impulse {\n    id\n    amount\n  }\n  tries {\n    responseDescription\n    orderStatus\n  }\n}\n"
  }
};
})();

node.hash = "6bb7df62c25199589b4b66d93a1f05d3";

module.exports = node;
