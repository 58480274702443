/**
 * @generated SignedSource<<194e3207949dc89e216d3d4ebd78e5f5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Endpoint",
    "kind": "LinkedField",
    "name": "updateEndpointHints",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hints",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HintsCabinetEndpointMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HintsCabinetEndpointMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c7c7717a22d41a0c1b9fb462d0e31ac7",
    "id": null,
    "metadata": {},
    "name": "HintsCabinetEndpointMutation",
    "operationKind": "mutation",
    "text": "mutation HintsCabinetEndpointMutation(\n  $request: UpdateEndpointHintsRequestInput\n) {\n  updateEndpointHints(request: $request) {\n    id\n    hints\n    version\n  }\n}\n"
  }
};
})();

node.hash = "9b3edcec81fc2ff78b83303e279bfd03";

module.exports = node;
