import React from 'react'
import './VendingMachines.scss';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import useQuery from '../../useQuery';
import {ErrorBoundary} from 'react-error-boundary';
import SuspenseImage from '../SuspenseImageStaticSize';
import Skeleton from '../Skeleton';
import SuspenseList from '../SuspenseList';
import ErrorFallback from '../ErrorFallback';

const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

const VendingMachines = () => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='vm'>
            <div className='vm__header'>
                <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
            </div>
            <div className='vm__title'>
                <div className='vm__title-name'>Vending Machines service</div>
                <div className='vm__title-description'>
                    Learn more about Ottry Vending Machines service opportunities.
                </div>
            </div>
            <div className='vm__introduction'>
                Ottry is a multifunctional platform that allows you to sell goods and services online both on your website and on our marketplace.
            </div>
            <div className='view'>
                <div className='block'>
                    <SuspenseList revealOrder='forwards'>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <React.Suspense fallback={<Skeleton/>}>
                                <div>
                                    <div className='display-flex flex-direction-adaptive margin-top-1rem'>
                                        <div>
                                            <SuspenseImage
                                                className='vm__logo'
                                                src='https://impulse.ottry.com/images/vending/1.jpg'
                                            />
                                        </div>
                                        <div className='flex-1 padding-left-1rem padding-top-1rem'>
                                            <div className='font-weight-bold'>Vending Machines</div>
                                            <div className='margin-top-1rem'>Integration with self-service car wash</div>
                                            <div>Acceptance of non-cash payments</div>
                                            <div>Сollection of statistics</div>
                                            <div>Error handling</div>
                                        </div>
                                    </div>
                                </div>
                            </React.Suspense>
                        </ErrorBoundary>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <React.Suspense fallback={<Skeleton/>}>
                                <div>
                                    <div className='display-flex flex-direction-adaptive margin-top-1rem'>
                                        <div>
                                            <SuspenseImage
                                                className='vm__logo'
                                                src='https://impulse.ottry.com/images/vending/2.jpg'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </React.Suspense>
                        </ErrorBoundary>
                    </SuspenseList>
                </div>
            </div>


            <div className='vm__footer-line'>
                <div className='vm__footer-line-left'>&nbsp;</div>
                <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                <div className='vm__footer-line-right'>&nbsp;</div>
            </div>
            <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                >Uk</a>
                {' '}
                <a
                    className={classNames('link', 'font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                >En</a>
            </div>
            <div className='vm__footer-content'>
                <div className='vm__footer-info'>
                    <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                    <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                    <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                </div>
            </div>
            <div className='vm__footer-rights'>
                © {year} Ottry. All rights reserved.
            </div>
        </div>
    )
};

export default React.memo(VendingMachines);