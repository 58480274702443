import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import moment from 'moment';
import SuspenseList from '../../SuspenseList';
import {useForm} from 'react-hook-form';
import classNames from 'classnames';
import {adopt} from '../../../math';
import useSubscription from '../../../useSubscription';
import ErrorFallback from '../../ErrorFallback';
import useSubscriptionStatus from '../../../useSubscriptionStatus';

const NumbersServiceNumbersImpl = React.memo(({submit, register, flag, refreshedQueryOptions, from, to}) => {
    const data = useLazyLoadQuery(
        graphql`
            query NumbersServiceNumbersImplQuery($request: ServiceNumbersRequestInput) {
                numbers(request: $request) {
                    value
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    return (
        <>
        <form onSubmit={submit}>
            <div className='input'>
                <p className='margin-bottom-0dot5rem'>
                    <FormattedMessage defaultMessage='Start of sales'/>
                </p>
                <input
                    className='width-100percent'
                    defaultValue={from && from.format('YYYY-MM-DD HH:mm:ss')}
                    placeholder='2024-12-30 10:00'
                    {...register('from', {
                        validate: value => !value || moment(value).isValid()
                    })}
                />
            </div>
            <p className='margin-top-1rem margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='End of sales'/>
            </p>
            <div className='input'>
                <input
                    className='margin-0dot5rem-0 width-100percent'
                    defaultValue={to && to.format('YYYY-MM-DD HH:mm:ss')}
                    placeholder='2024-12-30 10:00'
                    {...register('to', {
                        validate: value => !value || moment(value).isValid()
                    })}
                />
            </div>
            <div className='display-flex'>
                <div
                    className='button primary'
                    onClick={() => !flag && submit()}
                >
                    <FormattedMessage defaultMessage='Filter'/>{flag && '...'}
                </div>
            </div>
        </form>
        <div className={classNames({'opacity-0dot6': flag})}>
            {data.numbers.value ?
                Object.entries(data.numbers.value).map(
                    ([serviceId, serviceIdValue]) => {
                        // if (!serviceIdValue) return;
                        // console.log('serviceIdValue', serviceIdValue);
                        return Object.entries(serviceIdValue).map(
                            ([serviceName, serviceNameValue]) => {
                                // if (!serviceNameValue) return;
                                // console.log('serviceNameValue', serviceNameValue);
                                return (
                                    <div
                                        className='margin-top-1rem'
                                        key={serviceId + ' ' + serviceName}
                                    >
                                        <div className='font-weight-bold badge palevioletred'>
                                            {serviceName}
                                        </div>
                                        <div className='display-flex flex-wrap-wrap'>
                                            {Object.entries(serviceNameValue).map(
                                                ([endpointId, endpointIdValue]) => {
                                                    // if (!endpointIdValue) return;
                                                    // console.log('endpointIdValue', endpointIdValue);
                                                    return Object.entries(endpointIdValue).map(
                                                        ([endpointName, endpointNameValue]) => {
                                                            // console.log(
                                                            //     'endpointName, endpointNameValue',
                                                            //     endpointName, endpointNameValue
                                                            // );
                                                            return (
                                                                <div
                                                                    className='card'
                                                                    key={endpointId + ' ' + endpointName}
                                                                >
                                                                    <div
                                                                        className='display-flex justify-content-center badge lightslategrey'
                                                                    >{endpointName}</div>
                                                                    <div className='margin-top-1rem'>
                                                                        <FormattedMessage defaultMessage='quantity'/>
                                                                    </div>
                                                                    <div>{Math.trunc(endpointNameValue.quantity)}</div>
                                                                    <div><FormattedMessage defaultMessage='unique scans/total scans of qr tickets'/></div>
                                                                    <div>
                                                                        {Math.trunc(endpointNameValue.taken)}
                                                                        <span className='color-grey font-size-0dot8rem'>
                                                                        &nbsp;/&nbsp;{Math.trunc(endpointNameValue.numbers)}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            );
                                                        }
                                                    )
                                                }
                                            )}
                                        </div>
                                    </div>
                                );
                            }
                        )
                    }
                )
                :
                <div className='margin-top-1rem'><FormattedMessage defaultMessage='No data for now'/></div>
            }
        </div>
        </>
    );
});

const NumbersServiceNumbers = React.memo(({service}) => {
    const fService = useFragment(
        graphql`
            fragment NumbersServiceNumbers_service on Service {
                id
                start
                end
                strategy
                every
            }
        `,
        service
    );
    const {from, to} = React.useMemo(() => adopt(new Date(), fService.start, fService.end, fService.strategy,
        fService.every), [fService]);
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {
            fetchKey: 0,
            fetchPolicy: 'network-only'
        },
        variables: {
            request: {
                id: fService.id,
                from,
                to,
                fetchKey: 0
            }
        }
    });
    const refreshedQueryOptionsDeferred = React.useDeferredValue(refreshedQueryOptions);
    const {register, handleSubmit} = useForm();
    const submit = handleSubmit(data => {
        setRefreshedQueryOptions(prev => ({
            ...prev,
            options: {
                ...prev.options,
                fetchKey: prev.options.fetchKey + 1
            },
            variables: {
                request: {
                    ...prev.variables.request,
                    from: !data.from ? null : moment(data.from).utc().format(),
                    to: !data.to ? null : moment(data.to).utc().format()
                }
            }
        }));
    });
    const refresh = React.useCallback(() => {
        setRefreshedQueryOptions(prev => ({
            ...prev,
            options: {
                ...prev.options,
                fetchKey: prev.options.fetchKey + 1
            },
            variables: {
                request: {
                    ...prev.variables.request,
                    fetchKey: prev.variables.request.fetchKey + 1
                }
            }
        }));
    }, []);
    const payload = React.useMemo(() => ({
        channel: `/services/${fService.id}`,
        onNext: () => refresh(),
        onError: (error) => {}
    }), [refresh, fService]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return (
        <NumbersServiceNumbersImpl {...{
            service: fService,
            submit,
            register,
            refreshedQueryOptions: refreshedQueryOptionsDeferred,
            flag: refreshedQueryOptions !== refreshedQueryOptionsDeferred,
            from,
            to
        }}/>
    );
});

const NumbersServices = React.memo(() => {
    const data = useLazyLoadQuery(
        graphql`
            query NumbersServicesByViewerQuery($size: Int!, $page: Int!) {
                servicesByViewer(size: $size, page: $page) {
                    content {
                        id
                        ...NumbersServiceNumbers_service
                    }
                }
            }
        `,
        {size: 100500, page: 0}
    );
    const subscriptionStatus = useSubscriptionStatus();
    return (
        <>
        <SuspenseList revealOrder='forwards'>
            {data.servicesByViewer.content.map((service) =>
                <ErrorBoundary {...{FallbackComponent: ErrorFallback}} key={service.id}>
                    <React.Suspense fallback={<div className='card'><Skeleton/></div>}>
                        <div className='card'>
                            <div className='display-flex flex-direction-column'>
                                <div className='color-grey font-size-0dot8rem'>
                                    <span className={classNames('dot margin-right-0dot2rem', {
                                        'green': subscriptionStatus === 0
                                    })}/>
                                    {service.id}
                                </div>
                                <div className='inline'>
                                    <div className='border-bottom-1px-dotted-black padding-top-1rem'>
                                        <NumbersServiceNumbers key={service.id} {...{service}}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Suspense>
                </ErrorBoundary>
            )}
        </SuspenseList>
        {!data.servicesByViewer.content.length &&
        <span><FormattedMessage defaultMessage='No data for now'/></span>
        }
        </>
    );
});

const Numbers = React.memo(() => {
    return (
        <>
        <div className='font-weight-bold font-size-2dot5rem text-align-center margin-top-1rem'>
            <FormattedMessage defaultMessage='Number of sold tickets'/>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense
                fallback={
                    <div className='display-flex flex-wrap-wrap'>
                        {[1,2,3].map(i =>
                            <div key={i} className='card'>
                                <div className='height-100percent display-flex flex-direction-column'>
                                    <Skeleton/>
                                </div>
                            </div>
                        )}
                    </div>
                }
            >
                <NumbersServices/>
            </React.Suspense>
        </ErrorBoundary>
        </>
    );
});

export default Numbers;
