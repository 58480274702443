import {isAfter, isBefore} from 'date-fns';
import getTagValue from '../../../utilities/get-tag-value';

export const getServiceStartDate = (endpoints) => {
    if (!endpoints || !endpoints.length) {
        return null;
    } else {
        const startDate = endpoints.reduce((date, endpoint) => {
            const sellAfter = getTagValue(endpoint.tags, 'sellAfter');
            if (sellAfter === 'datetime') {
                if (date) {
                    const endpointStart = new Date(endpoint.start);
                    date = isBefore(endpointStart, date) ? endpointStart : date;
                } else {
                    date = new Date(endpoint.start);
                }
            }
            return date;
        }, null);
        return startDate;
    }
};

export const getServiceEndDate = (endpoints) => {
    if (!endpoints || !endpoints.length) {
        return null;
    } else {
        const endDate = endpoints.reduce((date, endpoint) => {
            if (date) {
                const endpointEnd = new Date(endpoint.end);
                date = isAfter(endpointEnd, date) ? endpointEnd : date;
            } else {
                date = new Date(endpoint.end);
            }
            return date;
        }, null);
        return endDate;
    }
};