import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {graphql, useLazyLoadQuery, usePaginationFragment} from 'react-relay/hooks';
import {Link, useLocation} from 'react-router-dom';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import {FetchKeyStateContext} from '../../FetchKey';
import {isTrue} from '../../../utility';
import useQuery from '../../../useQuery';
import ErrorFallback from '../../ErrorFallback';

const TargetServicesImpl = React.memo(({viewer}) => {
    const {more} = useQuery();
    const location = useLocation();
    const {data, loadNext, hasNext, isLoadingNext} = usePaginationFragment(
        graphql`
            fragment TargetServicesImpl_viewer on Viewer @refetchable(queryName: "TargetServicesImplQuery") {
                targetServicesByViewer(first: $first, after: $after) @connection(key: "TargetServices_targetServicesByViewer") {
                    edges {
                        node {
                            id
                            name
                        }
                    }
                }
            }
            
        `,
        viewer
    );
    return (
        <>
        <div className='display-flex flex-wrap-wrap'>
            {data.targetServicesByViewer.edges.map(e => e.node).map((service) =>
                <div key={service.id} className='card'>
                    <div className='border-bottom-1px-dotted-black height-100percent display-flex flex-direction-column'>
                        <div className='font-weight-bold'>
                            <Link to={`services/${service.id}${searchFilter(location.search)}`}>
                                {service.name}
                            </Link>
                        </div>
                        {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{service.id}</div>}
                    </div>
                </div>
            )}
            {!data.targetServicesByViewer.edges.length &&
            <span><FormattedMessage defaultMessage='No data for now'/></span>
            }
        </div>
        {hasNext &&
        <div className='display-flex'>
            <div
                className='button primary'
                onClick={() => !isLoadingNext && loadNext(2)}
            ><FormattedMessage defaultMessage='More'/>{isLoadingNext && '...'}</div>
        </div>
        }
        </>
    );
});

const TargetServices = React.memo(() => {
    const fetchKeyState = React.useContext(FetchKeyStateContext);
    const data = useLazyLoadQuery(
        graphql`
            query TargetServicesQuery($first: Int!, $after: String) {
                viewer {
                    ...TargetServicesImpl_viewer
                }
            }
        `,
        {first: 2},
        {fetchKey: fetchKeyState}
    );
    return (
        <TargetServicesImpl {...{viewer: data.viewer}}/>
    );
});

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-0dot5rem'>
                <span className='font-size-2dot5rem font-weight-bold'>
                    <FormattedMessage defaultMessage='Active events'/>
                </span>
                <div>
                    {' '}<FormattedMessage defaultMessage='or'/>{' '}
                    <Link
                        className='focus'
                        to={`../services${searchFilter(location.search)}`}
                    ><FormattedMessage defaultMessage='Show all'/></Link>
                </div>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense
                    fallback={
                        <div className='display-flex flex-wrap-wrap'>
                            {[1, 2, 3].map(i =>
                                <div key={i} className='card'>
                                    <div className='border-bottom-1px-dotted-black padding-bottom-1rem'>
                                        <Skeleton/>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                >
                    <TargetServices/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
