import React from 'react';
import useQuery from '../useQuery';
import classNames from 'classnames';

const dim = (value) => value ? value.split(',') : [];

export default React.memo(({on, children}) => {
    const {apply} = useQuery();
    const applyDim = dim(apply);
    return (
        <div className={classNames('display-none', {
            'display-block': applyDim.includes(on)
        })}>
            {children}
        </div>
    );
});