import React from 'react'
import {Link, useLocation, useParams} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../../components/Skeleton';
import {useCartDispatch, useCartState} from '../../../contexts/cart';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from '../../../components/Marked';
import i18n from '../../../i18n';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import useNow from '../../../useNow';
import {adopt} from '../../../math';
import {IN, OUT_NEGATIVE, TICKETS_CONTACT_FORM, SUPPORT_CONTACT, CONTACT_EMAIL, ENTITY_TYPE, OUT_POSITIVE, COUNTDOWN_CONSTRAINTS, DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../../../constants';
import IconMaster from '../../../assets/icons-payment/icon-master.svg';
import IconVisa from '../../../assets/icons-payment/icon-visa.svg';
import IconApplePay from '../../../assets/icons-payment/icon-apple-pay.svg';
import IconGooglePay from '../../../assets/icons-payment/icon-google-pay.svg';
import ErrorFallback from '../../../components/ErrorFallback';
import Browser from '../../../components/Browser';
import Ogle from '../../../components/theme/Ogle';
import LogoWhiteDarkModeTrue from '../../../assets/logo-white-dark-mode-true.svg';
import LogoWhiteDarkModeFalse from '../../../assets/logo-white-dark-mode-false.svg';
import ButtonIcon from '../../../components/button-icon/button-icon.component';
import CartMinusCircle from '../../../assets/icons/cart-minus-circle.svg';
import CartAddCircle from '../../../assets/icons/cart-add-circle.svg';
import EndpointsHeader, {WarpEndpointsHeader} from './endpoints-header';
import moment from 'moment';
import ServiceSchedule, {SERVICE_DESCRIPTION, ServiceScheduleCard} from './service-schedule';
import {isSet, isTrue} from '../../../utility';
import EndpointSchedule from './endpoint-schedule';
import './basic.scss';
import classNames from 'classnames';
import getTagValue from '../../../utilities/get-tag-value';
import SuspenseImageDynamicSize from '../../../components/SuspenseImageDynamicSize';
import CalendarIcon from '../../../assets/icons0/calendar.svg';
import {format, isBefore} from 'date-fns';
import {getServiceEndDate, getServiceStartDate} from './get-service-date';
import FacebookIcon from '../../../assets/icons0/socials/facebook-icon.svg';
import InstagramIcon from '../../../assets/icons0/socials/instagram-icon.svg';
import TelegramIcon from '../../../assets/icons0/socials/telegram-icon.svg';

const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

const config = {shouldValidate: true, shouldDirty: true};

const Endpoint = React.memo(({endpoint, serviceByIdOrSlug, theme}) => {
    const {locale = 'uk', more} = useQuery();
    const state = useCartState();
    const dispatch = useCartDispatch();
    const [isAnimationRunning, setIsAnimationRunning] = React.useState(false);
    const fEndpoint = useFragment(
        graphql`
            fragment basicEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
                start
                end
                strategy
                every
                description
                descriptionUk
                untie
                hints
                tags
            }
        `,
        endpoint
    );
    const fServiceByIdOrSlug = useFragment(
        graphql`
            fragment basicEndpoint_serviceByIdOrSlug on Service {
                id
                nameUk
                name
                target
            }
        `,
        serviceByIdOrSlug
    );
    const cartEntry = React.useMemo(() =>
            state.find(e => e.serviceId === fServiceByIdOrSlug.id && e.endpointId === fEndpoint.id),
        [state, fEndpoint.id, fServiceByIdOrSlug.id]
    );
    const now = useNow();
    const dpt = React.useMemo(() => adopt(now, fEndpoint.start, fEndpoint.end, fEndpoint.strategy,
        fEndpoint.every), [now, fEndpoint]);
    const addEndpoint = () => {
        if(!cartEntry) {
            setIsAnimationRunning(true);
            dispatch({type: 'add', payload: {
                endpointId: fEndpoint.id,
                serviceId: fServiceByIdOrSlug.id,
                ...(fEndpoint.untie && {untie: {price: data.input, currency: 'uah'}}) 
            }});
        }
    };
    const incrementEndpoint = () => {
        dispatch({type: 'increment', payload: cartEntry.key});
    };
    const decrementEndpoint = () => {
        if (cartEntry.quantity > 1) {
            dispatch({type: 'decrement', payload: cartEntry.key});
        } else {
            dispatch({type: 'remove', payload: cartEntry.key});
        }
    };
    const alternativePriceTag = fEndpoint.tags && fEndpoint.tags.length && fEndpoint.tags.find(tag => tag.includes('price'));
    return (
        <div className='basic-endpoint border-radius-0dot75rem border-1px-solid padding-1dot25rem mw768-padding-2rem'>
            <div className='display-flex flex-direction-column-row'>
                <div className=' mw768-flex-1 mw768-margin-right-1rem'>
                    <div className='basic-endpoint__title text-lg medium mw768-text-xl'>
                        {i18n(fServiceByIdOrSlug, 'name', locale)} | {i18n(fEndpoint, 'name', locale)}
                    </div>
                    {fServiceByIdOrSlug.target && moment(fServiceByIdOrSlug.target).isValid() && <div className='basic-endpoint__date text-sm mw768-text-md padding-top-0dot5rem'>{moment(fServiceByIdOrSlug.target).format('DD.MM.YYYY')}</div>}
                    {((dpt.state === IN && fEndpoint.quantity < 16) || isTrue(more)) && <div className='padding-top-0dot5rem color-warning-500 text-sm'><FormattedMessage defaultMessage='Left'/> {fEndpoint.quantity}</div>}
                    <EndpointSchedule {...{
                        start: fEndpoint.start,
                        end: fEndpoint.end,
                        strategy: fEndpoint.strategy,
                        every: fEndpoint.every,
                        theme
                    }}/>
                </div>
                {dpt.state === IN &&
                    <div className='display-flex mw768-flex-1 justify-content-space-between align-items-center mw768-align-self-center mw768-justify-content-flex-end padding-top-1rem mw768-padding-top-0'>
                        <p className='basic-endpoint__price mw768-margin-right-2dot5rem'>{fEndpoint.price} UAH {alternativePriceTag ? `(${alternativePriceTag.replace('price=', '')})` : ''}</p>
                        <div className='width-7rem min-height-2dot625rem'>
                            {fEndpoint.quantity > 0 &&
                                <Browser>
                                    <div className={classNames('basic-endpoint__btn border-1px-solid padding-0dot5rem border-radius-0dot5rem text-md medium display-flex position-relative justify-content-center shadow-xs', {'basic-endpoint__btn--empty cursor-pointer width-100percent buy-btn-animation--backwards': !cartEntry, 'basic-endpoint__btn--entry buy-btn-animation--forward': !!cartEntry, 'buy-btn-animation--play': isAnimationRunning})} onClick={() => addEndpoint()}>
                                        <span className={classNames({'add-to-cart--visible': !cartEntry, 'add-to-cart--hidden': !!cartEntry, 'color-primary-500': theme === 'dark', 'color-primary-600': theme === 'light'})}><FormattedMessage defaultMessage='Buy'/></span>
                                        <div className={classNames({'decrement-endpoint--visible': !!cartEntry, 'decrement-endpoint--hidden': !cartEntry})}>
                                            <ButtonIcon {...{clickHandler: () => decrementEndpoint()}}>
                                                <CartMinusCircle className={classNames('width-100percent', {'color-primary-500': theme === 'dark', 'color-primary-600': theme === 'light'})}/>
                                            </ButtonIcon>
                                        </div>
                                        <p className={classNames('text-md text-align-center', {'endpoint-quantity--visible': !!cartEntry, 'endpoint-quantity--hidden': !cartEntry, 'color-gray-100': theme === 'dark', 'color-gray-700': theme === 'light'})}>{cartEntry ? cartEntry.quantity : ''}</p>
                                        <div className={classNames({'increment-endpoint--visible': !!cartEntry, 'increment-endpoint--hidden': !cartEntry})}>
                                            <ButtonIcon {...{clickHandler: () => incrementEndpoint()}}>
                                                <CartAddCircle className={classNames('width-100percent', {'color-primary-500': theme === 'dark', 'color-primary-600': theme === 'light'})}/>
                                            </ButtonIcon>
                                        </div>
                                    </div>
                                </Browser>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    );
});

const EndpointWithScheduleContainer = ({endpoint, children, now, endpoints}) => {
    const {more} = useQuery();
    const endpointAlwaysVisible = getTagValue(endpoint.tags, 'visibility', {defaultValue: 'hidden'}) === 'visible';
    const isWave = getTagValue(endpoint.tags, 'sellAfter') === 'endpoint';
    const sellEndpointId = getTagValue(endpoint.tags, 'sellEndpointId');
    const previousWave = isWave && sellEndpointId && endpoints.find(e => e.id === sellEndpointId);
    const previousWaveEnded = previousWave && React.useMemo(() => {
        if (isWave && previousWave) {
            if (previousWave.quantity === 0) {
                return true;
            } else {
                const previousWaveEnd = new Date(previousWave.end);
                return isBefore(previousWaveEnd, now);
            }
        }
    }, [isWave, previousWave, now]);
    const visibilityCondition = isWave ? previousWaveEnded && endpoint.quantity > 0 : endpoint.quantity > 0;
    const [showEndpoint, setShowEndpoint] = React.useState(visibilityCondition || endpointAlwaysVisible || more);
    const {state} = React.useMemo(() => adopt(now, endpoint.start, endpoint.end, endpoint.strategy,
        endpoint.every), [now, endpoint]);
    React.useEffect(() => {
        if (state === OUT_NEGATIVE && isSet(endpoint.end) && moment(endpoint.end).add(COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].amount, COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].units) < moment(now)) {
            setShowEndpoint(more || endpointAlwaysVisible || false);
        } else if (state === OUT_POSITIVE && isSet(endpoint.start) && moment(now) < moment(endpoint.start).subtract(COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].amount, COUNTDOWN_CONSTRAINTS[ENTITY_TYPE.endpoint].units)) {
            setShowEndpoint(more || endpointAlwaysVisible || false);
        } else {
            setShowEndpoint(visibilityCondition || endpointAlwaysVisible || more);
        }
    }, [endpoint, state, now]);
    return (
        <>
            {showEndpoint &&
                <div className='padding-bottom-1dot75rem'>
                    {children}
                </div>
            }
        </>
    )
}

const EndpointContainer = ({endpoint, children, endpoints}) => {
    const {more} = useQuery();
    const endpointAlwaysVisible = getTagValue(endpoint.tags, 'visibility', {defaultValue: 'hidden'}) === 'visible';
    if ((!endpoint.start && !endpoint.end && endpoint.quantity > 0) || endpointAlwaysVisible || more) return (
        <div className='padding-bottom-1dot75rem'>
            {children}
        </div>
    );
    const now = useNow();
    return (
        <EndpointWithScheduleContainer {...{endpoint, now, endpoints}}>
            {children}
        </EndpointWithScheduleContainer>
    );

}

const Service = ({setShowTicketsBoxBtn, ticketsBoxRef}) => {
    const {idOrSlug} = useParams();
    const data = useLazyLoadQuery(
        graphql`
            query basicQuery($payload: String) {
                serviceByIdOrSlug(payload: $payload) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    description
                    location
                    start
                    end
                    strategy
                    every
                    target
                    endpoints {
                        id
                        quantity
                        start
                        end
                        strategy
                        every
                        price
                        tags
                        ...basicEndpoint_endpoint
                    }
                    ...basicEndpoint_serviceByIdOrSlug
                }
            }
        `,
        {payload: decodeURIComponent(idOrSlug)}
    );
    const tags = data.serviceByIdOrSlug.description ? data.serviceByIdOrSlug.description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) : [];
    const theme = getTagValue(tags, 'theme', {defaultValue: 'dark'});
    const posterUrl = getTagValue(tags, 'posterUrl');
    const targetEndVal = getTagValue(tags, 'targetEnd');
    const target = data.serviceByIdOrSlug.target ? format(new Date(data.serviceByIdOrSlug.target), 'dd-MM-yyyy') : null;
    const targetEnd = targetEndVal ? format(new Date(targetEndVal), 'dd-MM-yyyy') : null;
    const lowestTicketPrice = React.useMemo(() => {
        data.serviceByIdOrSlug.endpoints ? data.serviceByIdOrSlug.endpoints.map(e => e.price).sort() : [];
        if (data.serviceByIdOrSlug.endpoints && data.serviceByIdOrSlug.endpoints.length) {
            return data.serviceByIdOrSlug.endpoints.reduce((price, endpoint) => {
                price = price > endpoint.price ? endpoint.price : price;
                return price;
            }, data.serviceByIdOrSlug.endpoints[0].price);
        }
    }, [data]);
    const locationName = data.serviceByIdOrSlug.location;
    const facebook = getTagValue(tags, 'facebook');
    const instagram = getTagValue(tags, 'instagram');
    const telegram = getTagValue(tags, 'telegram');
    React.useEffect(() => {
        setShowTicketsBoxBtn(data.serviceByIdOrSlug.description && data.serviceByIdOrSlug.description.includes('btnAnchorTickets'));
    }, [setShowTicketsBoxBtn, data]);
    const {locale = 'uk'} = useQuery();
    const now = useNow();
    const serviceStart = getServiceStartDate(data.serviceByIdOrSlug.endpoints);
    const serviceEnd = getServiceEndDate(data.serviceByIdOrSlug.endpoints);
    const {state} = React.useMemo(() => adopt(now, serviceStart, serviceEnd, data.serviceByIdOrSlug.strategy,
        data.serviceByIdOrSlug.every), [now, data.serviceByIdOrSlug]);
    return (
        <>
        <Helmet>
            <title>{i18n(data.serviceByIdOrSlug, 'name', locale)}</title>
            <meta name='description' content={i18n(data.serviceByIdOrSlug, 'name', locale)}/>
            <meta property='og:image' content={`https://${posterUrl}`}/>
        </Helmet>
        <div>
            <div className={`view theme theme--${theme}`}>
                {posterUrl &&
                    <div className=''>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImageDynamicSize
                                className='basic__poster-img display-block max-height-35rem mw768-max-height-37dot5rem margin-0-auto max-width-100vw mw768-max-width-76rem'
                                src={`https://${posterUrl}`}
                            />
                        </ErrorBoundary>
                    </div>
                }
                <div className='block'>
                    <div className='max-width-50rem margin-0-auto'>
                        <div className='basic__title display-sm semibold padding-top-2rem mw768-padding-top-4rem'>
                            {i18n(data.serviceByIdOrSlug, 'name', locale)}
                        </div>
                        {target &&
                            <div className='padding-top-0dot5rem basic__target display-flex'>
                                <CalendarIcon className='display-block height-1dot25rem width-1dot25rem'/>
                                <p className='text-md medium padding-left-0dot5rem'>{target}</p>
                                {targetEnd && target !== targetEnd &&
                                    <span className='text-md medium padding-left-0dot125rem'>{`- ${targetEnd}`}</span>
                                }
                            </div>
                        }
                        <div className='padding-top-1dot5rem'>
                            <Marked {...{content: i18n(data.serviceByIdOrSlug, 'details', locale)}}/>
                        </div>
                        {data.serviceByIdOrSlug.endpoints && data.serviceByIdOrSlug.endpoints.length &&
                            <p className='marked text-md'>
                                <span className='bold'><FormattedMessage defaultMessage='Price: from'/> </span>
                                <span>{lowestTicketPrice} UAH</span>
                            </p>
                        }
                        <p className='marked text-md'>
                            <span className='bold'><FormattedMessage defaultMessage='Location'/>: </span>
                            <span>{ locationName ? decodeURI(locationName) : <FormattedMessage defaultMessage='To be announced'/>}</span>
                        </p>
                        {(facebook || instagram || telegram) &&
                            <div className='padding-top-1dot25rem mw768-padding-top-3rem display-flex gap-1dot25rem'>
                                {facebook &&
                                    <a href={facebook} target='_blank' rel='noopener noreferrer'>
                                        <FacebookIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {instagram &&
                                    <a href={instagram} target='_blank' rel='noopener noreferrer'>
                                        <InstagramIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                                {telegram &&
                                    <a href={telegram} target='_blank' rel='noopener noreferrer'>
                                        <TelegramIcon className='basic__social-icon display-block height-1dot5rem mw768-height-2rem width-1dot5rem mw768-width-2rem'/>
                                    </a>
                                }
                            </div>
                        }
                        <div className='margin-top-1rem mw768-margin-top-3rem padding-top-2rem padding-bottom-2rem' ref={ticketsBoxRef}>
                            <WarpEndpointsHeader {...{serviceByIdOrSlug: data.serviceByIdOrSlug, theme}}>
                                {({decrementEndpoint, incrementEndpoint, localCartState, checkout}) =>
                                    <EndpointsHeader {...{serviceByIdOrSlug: data.serviceByIdOrSlug, decrementEndpoint, incrementEndpoint, localCartState, checkout, showCart: state !== OUT_NEGATIVE, theme}}/>
                                }
                            </WarpEndpointsHeader>
                            <div className='padding-top-2rem'>
                                <div>
                                    {i18n(data.serviceByIdOrSlug, 'details', locale) && i18n(data.serviceByIdOrSlug, 'details', locale).endsWith('!') ?
                                        <div className='padding-bottom-2rem'>
                                            <ServiceScheduleCard {...{...SERVICE_DESCRIPTION[OUT_NEGATIVE].default, theme}}/>
                                        </div>
                                        :
                                        <>
                                            <ServiceSchedule {...{
                                                start: serviceStart,
                                                end: serviceEnd,
                                                strategy: data.serviceByIdOrSlug.strategy,
                                                every: data.serviceByIdOrSlug.every,
                                                state,
                                                theme
                                            }}/>
                                            {state === IN &&
                                                <div>
                                                    {data.serviceByIdOrSlug.endpoints.map((endpoint) =>
                                                        <EndpointContainer key={endpoint.id} {...{endpoint, endpoints: data.serviceByIdOrSlug.endpoints}}>
                                                            <Endpoint {...{endpoint, serviceByIdOrSlug: data.serviceByIdOrSlug, theme}}/>
                                                        </EndpointContainer>
                                                    )}
                                                    {!data.serviceByIdOrSlug.endpoints.some(e => e.quantity) && <ServiceScheduleCard {...{...SERVICE_DESCRIPTION[OUT_NEGATIVE].default, theme}}/>}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            {state === IN && data.serviceByIdOrSlug.endpoints.some(e => e.quantity) && <p className='text-dm mw768-text-md color-gray-400 padding-top-0dot25rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
};

const BasicWarp = () => {
    const location = useLocation();
    const [showTicketsBoxBtn, setShowTicketsBoxBtn] = React.useState(false);
    const ticketsBoxRef = React.useRef();
    const scrollToTickets = () => {
        ticketsBoxRef.current.scrollIntoView({
            behavior: "smooth",
        });
    };
    return (
        <Ogle>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='view'>
                        <div className='block padding-top-2rem'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <div className='pages'>
                        <div className='warp'>
                            <div className='basic'>
                                <div className='view'>
                                    <div className='block basic__header'>
                                        <div className='padding-top-1rem padding-bottom-1rem mw768-padding-top-1dot25rem mw768-padding-bottom-1dot25rem display-flex justify-content-space-between align-items-center'>
                                            <Link to={`/tickets${searchFilter(location.search)}`}>
                                                <LogoWhiteDarkModeTrue className='height-2rem logo logo--dark'/>
                                                <LogoWhiteDarkModeFalse className='height-2rem logo logo--light'/>
                                            </Link>
                                            {showTicketsBoxBtn &&
                                                <div className='buy-tickets-btn border-radius-0dot5rem border-1px-solid border-color-gray-300 box-shadow-xs text-sm medium padding-top-0dot5rem padding-bottom-0dot5rem padding-left-0dot875rem padding-right-0dot875rem cursor-pointer' onClick={() => scrollToTickets()}>
                                                    <FormattedMessage defaultMessage='Tickets'/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <Service {...{setShowTicketsBoxBtn, ticketsBoxRef}}/>
                                <div className='view'>
                                    <div className='block'>
                                        <div className='warp-footer margin-top-4rem border-top-1px-solid padding-top-2rem mw768-padding-top-3rem padding-bottom-2rem mw768-padding-bottom-3rem'>
                                            <Link to={`/tickets${searchFilter(location.search)}`}>
                                                <LogoWhiteDarkModeTrue className='height-2rem logo logo--dark'/>
                                                <LogoWhiteDarkModeFalse className='height-2rem logo logo--light'/>
                                            </Link>
                                            <div className='display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem'>
                                                <div className='flex-1'>
                                                    <div className='margin-bottom-0dot5rem'>
                                                        <a className='text-md medium' href={`tel:${SUPPORT_CONTACT.cell}`} data-gtm='warp support-phone'>
                                                            {SUPPORT_CONTACT.cellFormatted}
                                                        </a>
                                                    </div>
                                                    <div>
                                                        <a className='text-md medium color-primary-500' href={`mailto:${CONTACT_EMAIL}?subject=Warp support`} data-gtm='warp email'>{CONTACT_EMAIL}</a>
                                                    </div>
                                                </div>
                                                <div className='mw768-align-self-flex-end display-flex padding-top-2rem mw768-padding-top-0'>
                                                    <IconMaster className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                                    <IconVisa className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                                    <IconApplePay className='display-block margin-right-0dot5rem height-2rem mw768-height-2dot5rem'/>
                                                    <IconGooglePay className='display-block height-2rem mw768-height-2dot5rem'/>
                                                </div>
                                            </div>
                                            <div className='warp-footer display-flex flex-direction-column-row padding-top-1dot5rem mw768-padding-top-2rem margin-top-2rem mw768-margin-top-4rem border-top-1px-solid'>
                                                <div className='flex-order-2 mw768-flex-order-1 flex-1 padding-top-1rem mw768-padding-top-0'>
                                                    <p className='text-md'>© {year} Ottry. All rights reserved.</p>
                                                </div>
                                                <div className='flex-order-1 mw768-flex-order-2 display-flex flex-wrap-wrap gap-0dot5rem-1rem'>
                                                    <Link className='text-md' to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                                                    <Link className='text-md' to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                                                    <Link className='text-md' to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                                                    <a className='text-md' href={TICKETS_CONTACT_FORM} target='_blank'><FormattedMessage defaultMessage='I want to sell online'/></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Suspense>
            </ErrorBoundary>
        </Ogle>
    );
};

export default React.memo(BasicWarp);