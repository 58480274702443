import React from 'react';
import {graphql, useLazyLoadQuery, useFragment, useMutation} from 'react-relay/hooks';
import {FormattedMessage} from 'react-intl';
import Sync from './Sync';
import Pool from './Pool';
import Apply from '../../Apply';
import {useForm} from 'react-hook-form';

const merchantId = process.env.RAZZLE_TECHNICAL_MERCHANT;
const technicalServiceId = process.env.RAZZLE_TECHNICAL_SERVICE_ID;

const Display = React.memo(({pool}) => {
    const fPool = useFragment(
        graphql`
            fragment PoolsDisplayCabinet_pool on Pool {
                id
                cardType
                cardPan
                version
                inn
            }
        `,
        pool
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation PoolsDisplayCabinetMutation($request: UpdatePoolInnRequestInput) {
                updatePoolInn(request: $request) {
                    id
                    inn
                    version
                }
            }
    `);
    const submit = handleSubmit(data => {
        apply({
            variables: {request: {id: fPool.id, version: fPool.version, inn: data.input === '' ? null : data.input}},
            onCompleted: () => {setFlag(false)},
            onError: () => {}
        });
    });
    return (
        <div key={fPool.id} className='margin-top-1rem border-bottom-1px-dotted-black padding-top-1rem padding-bottom-1rem'>  
            <div>
                {/Visa/i.test(fPool.cardType) &&
                    <img
                        src='https://upload.wikimedia.org/wikipedia/commons/5/5e/Visa_Inc._logo.svg'
                        style={{maxHeight: 14}}
                        alt=''
                    />
                }
                {/MasterCard/i.test(fPool.cardType) &&
                    <img
                        src='https://upload.wikimedia.org/wikipedia/commons/thumb/2/2a/Mastercard-logo.svg/1544px-Mastercard-logo.svg.png'
                        style={{maxHeight: 14}}
                        alt=''
                    />
                }
                <span className='padding-left-1rem'>{fPool.cardPan.replace(/X/g, '*')}</span>
            </div>
            <Apply {...{on: 'beta'}}>
                <div className='padding-top-1rem'>
                    <div className='color-blue opacity-0dot6 margin-bottom-0dot5rem'>Inn</div>
                    <div className='font-weight-bold margin-top-0dot5rem'>{fPool.inn}</div>
                    <div className='padding-top-0dot5rem'>
                        <div className='display-flex'>
                            <div
                                className='button'
                                onClick={() => !applying && setFlag((flag) => !flag)}
                            >
                                {flag ?
                                    <FormattedMessage defaultMessage='Cancel'/>
                                    :
                                    <FormattedMessage defaultMessage='Edit'/>
                                }{applying && '...'}
                            </div>
                        </div>
                        {flag &&
                        <div className='inline'>
                            <form onSubmit={submit}>
                                <div className='input'>
                                    <input
                                        className='margin-0dot5rem-0 width-100percent'
                                        defaultValue={fPool.inn}
                                        {...register('input')}
                                    />
                                </div>
                                <div className='display-flex'>
                                    <div
                                        className='button primary'
                                        onClick={() => !applying && submit()}
                                    >
                                        <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                                    </div>
                                </div>
                            </form>
                            <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
                        </div>
                        }
                    </div>
                </div>
            </Apply>
        </div>
    );
});

const PoolsImpl = React.memo(({refreshPools, refreshedQueryOptions}) => {
    const data = useLazyLoadQuery(
        graphql`
            query PoolsPoolsImplPoolsByViewerQuery($request: PoolsRequestInput) {
                poolsByViewer(request: $request) {
                    content {
                        id
                        ...PoolsDisplayCabinet_pool
                    }
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const pools = React.useMemo(() => data.poolsByViewer.content.map((e) => e.id),
        [JSON.stringify(data.poolsByViewer.content.map((e) => e.id))]);
    return (
        <>
         <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-0dot5rem'>
                <span className='font-size-2dot5rem font-weight-bold '><FormattedMessage defaultMessage='Pools'/></span>
                <div className='display-flex justify-content-flex-end padding-bottom-1rem'>
                    <Sync {...{
                        // merchantAccount: data.impulseByClaim.impulseService.merchantAccount,
                        // merchantId: data.impulseByClaim.impulseService.mrchntd
                        pools,
                        merchantId,
                        refreshPools
                    }}/>
                    <Pool {...{serviceId: technicalServiceId}}/>
                </div>
            </div>
            <div>
                {data.poolsByViewer.content.map((pool) =>
                    <Display {...{key: pool.id, pool}}/>
                )}
            </div>
        </div>
        </>
    );
});

const Pools = () => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {
            size: 100500,
            page: 0,
            merchantId,
            fetchKey: 0
        }}
    });
    const [isPending, startTransition] = React.useTransition();
    const refreshPools = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        fetchKey: prev.variables.request.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    return (
        <div className='pools'>
            <PoolsImpl {...{
                // merchantAccount: data.impulseByClaim.impulseService.merchantAccount,
                // merchantId: data.impulseByClaim.impulseService.mrchntd
                // merchantId: '1396424',
                refreshPools,
                refreshedQueryOptions
            }}/>
        </div>
    );
}

export default React.memo(Pools);