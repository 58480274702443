import React from 'react';
import {graphql, useMutation} from 'react-relay/hooks';
import useMountedState from '../../../useMountedState';
import useQuery from '../../../useQuery';
import qs from 'qs';
import {submitForm} from '../../../utility';
import {FormattedMessage} from 'react-intl';

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_APP_RETURN_URL0 = process.env.RAZZLE_APP_RETURN_URL0;
const RAZZLE_APP_SERVER_CALLBACK_URL0 = process.env.RAZZLE_APP_SERVER_CALLBACK_URL0;

export default React.memo(({serviceId}) => {
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation PoolPoolsCabinetCreatePooltickMutation($request: PooltickRequestInput!) {
                createPooltick(request: $request) {
                    id
                    claim
                    pooltickService {
                        mrchntd
                        service {
                            name
                        }
                    }
                }
            }
    `);
    const [sign, isSignInFlight] = useMutation(
        graphql`
            mutation PoolPoolsCabinetSignFondySignPooltickMutation($request: FondySignRequestInput) {
                fondySignPooltick(request: $request) {
                    signature
                }
            }
    `);
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const {locale = 'uk'} = useQuery();
    return (
        <div
            className='link font-weight-bold cursor-pointer'
            onClick={() => {
                setGrecaptcha(true);
                window.grecaptcha.ready(() => {
                    window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                        commit({
                            variables: {request: {reCaptcha: token, service: serviceId}},
                            onCompleted: (foo) => {
                                const payload = {
                                    order_id: foo.createPooltick.id,
                                    merchant_id: foo.createPooltick.pooltickService.mrchntd,
                                    order_desc: foo.createPooltick.pooltickService.service.name,
                                    amount: '100',
                                    currency: 'UAH',
                                    ...(RAZZLE_APP_RETURN_URL0 && {response_url: `${RAZZLE_APP_RETURN_URL0}?url=${window.location.origin}/services/pools?pooltickId=${foo.createPooltick.id}`}),
                                    ...(RAZZLE_APP_SERVER_CALLBACK_URL0 && {
                                        server_callback_url: RAZZLE_APP_SERVER_CALLBACK_URL0 + qs.stringify({upsert: true}, {addQueryPrefix: true})
                                    }),
                                    lifetime: 900, // this also means hold 7 working days for preauth: 'Y'
                                    preauth: 'Y',
                                    verification: 'Y',
                                    lang: locale,
                                    // sender_email: foo.createPooltick.pooltickClient.email,
                                    required_rectoken: 'Y'
                                };
                                sign({
                                    variables: {request: {payload, claim: foo.createPooltick.claim}},
                                    onCompleted: (bar) => {
                                        submitForm('https://pay.fondy.eu/api/checkout/redirect/', {
                                            ...payload,
                                            signature: bar.fondySignPooltick.signature
                                        });
                                    }
                                });
                            },
                            onError: () => {},
                            updater: () => {}
                        });
                        setGrecaptcha(false);
                    });
                });
            }}
        >+ <FormattedMessage defaultMessage='Add new card'/></div>
    );
});
