/**
 * @generated SignedSource<<417664e2abdc932fc534a66b16b0bb84>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "request",
    "variableName": "request"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PoolsPoolsImplPoolsByViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Page_Pool",
        "kind": "LinkedField",
        "name": "poolsByViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pool",
            "kind": "LinkedField",
            "name": "content",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PoolsDisplayCabinet_pool"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PoolsPoolsImplPoolsByViewerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Page_Pool",
        "kind": "LinkedField",
        "name": "poolsByViewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pool",
            "kind": "LinkedField",
            "name": "content",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cardType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cardPan",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "inn",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2bcc02b217d2b4393c2f6e339f4e502d",
    "id": null,
    "metadata": {},
    "name": "PoolsPoolsImplPoolsByViewerQuery",
    "operationKind": "query",
    "text": "query PoolsPoolsImplPoolsByViewerQuery(\n  $request: PoolsRequestInput\n) {\n  poolsByViewer(request: $request) {\n    content {\n      id\n      ...PoolsDisplayCabinet_pool\n    }\n  }\n}\n\nfragment PoolsDisplayCabinet_pool on Pool {\n  id\n  cardType\n  cardPan\n  version\n  inn\n}\n"
  }
};
})();

node.hash = "d84e84f54dffdcb19ffab25a3638fbc4";

module.exports = node;
