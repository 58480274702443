import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {isSet, isTrue} from '../../../../utility';
import useQuery from '../../../../useQuery';

const Warp = React.memo(({service}) => {
    const {more} = useQuery();
    const fService = useFragment(
        graphql`
            fragment WarpCabinetService_service on Service {
                id
                warp
                version
            }
        `,
        service
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation WarpCabinetServiceMutation($request: UpdateServiceWarpRequestInput) {
                updateServiceWarp(request: $request) {
                    id
                    warp
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, warp: data.input === '' ? null : data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Appearance of the event page'/>
        </div>
        {isSet(fService.warp) && <div className='font-weight-bold margin-top-0dot5rem'>{fService.warp}</div>}
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type A - dark page theme. You can display several different tickets for sale at once'/>
                </p>
                <p className='margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type B - light page theme. You can display several different tickets for sale at once'/>
                </p>
                <p className='margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type C - dark page theme. Only one ticket type is displayed. Another type appears automatically when the previous one has been sold out'/>
                </p>
                <p className='margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type D - light page theme. Only one ticket type is displayed. Another type appears automatically when the previous one has been sold out'/>
                </p>
                <form onSubmit={submit}>
                    <div className='select'>
                        <select
                            className='margin-0dot5rem-0'
                            defaultValue={fService.warp || ''}
                            {...register('input')}
                        >
                            <option value=''/>
                            <option value='A'>A</option>
                            <option value='B'>B</option>
                            <option value='C'>C</option>
                            <option value='D'>D</option>
                            {isTrue(more) && <option value='E'>E</option>}
                        </select>
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Warp);
