import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import Marked from '../../../Marked';
import useQuery from '../../../../useQuery';
import i18n from '../../../../i18n';

const defaultEmailTemplate = {
    emailUk: `Вітаємо!  

    Твій квиток у вигляді QR-коду знаходиться у прикріплених файлах. Тобі достатньо завантажити його на телефон і просто показати нам на вході.  
    
    Увага!  
    Організатори залишають за собою право не пропустити на вечірку при наявності квитка у випадках:  
    \- якщо ви прийшли на вечірку в алкогольному і/або наркотичному сп'янінні;   
    \- якщо ви не досягнули повноліття або необхідного мінімального віку вказаного на вечірці;   
    \- якщо ви ведете себе агресивно по відношенню до персоналу і/або оточуючих.`,
    emailEn: `Hello!

    Your ticket in the form of a QR code is in the attached files. All you have to do is download it to your phone and show it at the entrance.
    
     Warning!  
     The ticket holder’s right to attend a concert can be denied in following cases:  
     \- if you came to the party under the influence of alcohol and / or drugs;  
     \- if you have not reached the age of majority or the required minimum age specified for the party;  
     \- if you behave aggressively towards staff and / or others.`
}

const SuccessImpulseEmailMarkdownTemplate = React.memo(({service}) => {
    const {locale = 'uk'} = useQuery();
    const fService = useFragment(
        graphql`
            fragment SuccessImpulseEmailMarkdownTemplateCabinetService_service on Service {
                id
                successImpulseEmailMarkdownTemplate
                successImpulseEmailMarkdownTemplateUk
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation SuccessImpulseEmailMarkdownTemplateCabinetServiceMutation($request: UpdateServiceSuccessImpulseEmailMarkdownTemplateRequestInput) {
                updateServiceSuccessImpulseEmailMarkdownTemplate(request: $request) {
                    id
                    successImpulseEmailMarkdownTemplate
                    successImpulseEmailMarkdownTemplateUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            successImpulseEmailMarkdownTemplate: data.input,
            successImpulseEmailMarkdownTemplateUk: data.inputUk
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='The letter template that the client will receive after successful payment'/>
        </div>
        <div className='margin-top-0dot5rem'><Marked content={i18n(fService, 'successImpulseEmailMarkdownTemplate', locale)}/></div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem'>
                    <FormattedMessage defaultMessage='This field uses the Markdown markup language.'/>
                </p>
                <div className='font-size-0dot8rem'>
                    <p className='margin-top-1rem margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='A quick guide to using Markdown'/>
                    </p>
                    <p className='margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='1. Paragraphs: To create a new paragraph, simply leave a blank line between lines of text.'/>
                    </p>
                    <p className='margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='2. New line: in order to start a sentence with a new line, it is necessary to end the line with two spaces and press the "Enter" key.'/>
                    </p>
                    <p className='margin-bottom-0dot5rem'>
                        <p><FormattedMessage defaultMessage='3. Text formatting:'/></p>
                        <p className='margin-left-1rem'><FormattedMessage defaultMessage='* Bold text: enclose text in double asterisks, for example'/><span className='font-weight-bold'> <FormattedMessage defaultMessage='**this is bold text**'/></span>.</p>
                        <p className='margin-left-1rem'><FormattedMessage defaultMessage='* Italic: Enclose text in single asterisks, for example'/><span className='font-style-italic'> <FormattedMessage defaultMessage='*this is italic text*'/></span>.</p>
                    </p>
                    <p className='margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='4. Link: To insert a link, enclose the text in square brackets and the link in round brackets, for example [link text](URL).'/>
                    </p>
                    <p className='margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='5. Image: To insert an image, use the image link and alt text syntax, such as ![alt text](image link).'/>
                    </p>
                    <p>
                        <FormattedMessage defaultMessage='6. Horizontal lines (dividers): in order to create a horizontal line, it is necessary to place three stars on a separate line of text.'/>
                    </p>
                </div>
                <form onSubmit={submit}>
                    <div>
                        <textarea
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fService.successImpulseEmailMarkdownTemplate || defaultEmailTemplate.emailEn}
                            {...register('input')}
                        />
                    </div>
                    <div>
                        <textarea
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fService.successImpulseEmailMarkdownTemplateUk || defaultEmailTemplate.emailUk}
                            {...register('inputUk')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(SuccessImpulseEmailMarkdownTemplate);
