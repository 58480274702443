import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Chart} from 'react-google-charts';
import {useIntl, FormattedMessage} from 'react-intl';
import {timeZone} from '../../../utility';
import moment from 'moment';
import useQuery from '../../../useQuery';
import classNames from 'classnames';
import {useForm} from 'react-hook-form';
import ErrorFallback from '../../ErrorFallback';

const config = {shouldValidate: true, shouldDirty: true};

const ChartSentImpl = React.memo(({setChartSent, refreshedQueryOptions, flag}) => {
    const {locale = 'uk'} = useQuery();
    const intl = useIntl();
    const query = useLazyLoadQuery(
        graphql`
            query ChartSentImpulsesStatisticsQuery($request: ImpulsesStatisticsRequestInput) {
                impulsesStatistics(request: $request) {
                    value
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const data = React.useMemo(
        () => [['x', intl.formatMessage({defaultMessage: 'Amount'})]]
            .concat(
                query.impulsesStatistics.value.length ?
                    query.impulsesStatistics.value
                        .flatMap((e) => Object.entries(e))
                        .map(e => [moment(e[0]).toDate(), e[1]])
                    :
                    [[moment().toDate(), 0]]
            ),
        [query]
    );
    return (
        <div className={classNames({'opacity-0dot6': flag})}>
            <Chart
                width={'100%'}
                chartType='LineChart'
                loader={<div className='margin-top-1rem'><Skeleton/></div>}
                data={data}
                options={{
                    focusTarget: 'category',
                    crosshair: {trigger: 'focus', orientation: 'vertical', color: 'lightgrey'},
                    legend: {position: 'bottom'},
                    vAxis: {format: '# UAH'}
                }}
                chartLanguage={locale}
                chartEvents={[{eventName: 'ready', callback: () => {setChartSent(true);}}]}
            />
        </div>
    );
});

const ChartSent = React.memo(({setChartSent}) => {
    const from = React.useMemo(() => moment().subtract(1, 'months').startOf('day'), []);
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {
            timeZone: timeZone(),
            sent: true,
            from,
            to: null
            // There is no fetchKey in variables, and that means we will spawn only first request and
            // all the following won't even start because exact one is already in progress
            // new one will be spawned on new request and only after finishing current.
            // For subscription we do have fetchKey because we want all the requests to be spawned as
            // we need to catch latest information from server.
        }}
    });
    const [isPending, startTransition] = React.useTransition();
    const {register, handleSubmit, setValue, watch} = useForm({
        defaultValues: {
            from: from.format('YYYY-MM-DD')
        }
    });
    const submit = handleSubmit(data => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        from: !data.from ? null : moment(data.from).utc().format(),
                        to: !data.to ? null : moment(data.to).utc().format()
                    }
                }
            }));
        });
    });
    const wfrom = watch('from');
    return (
        <>
        <form onSubmit={submit} className='margin-top-1rem'>
            <p className='margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='Start of sales'/>
            </p>
            <div className='input'>
                <input
                    className='width-100percent'
                    defaultValue={from.format('YYYY-MM-DD')}
                    placeholder='2024-12-30 10:00'
                    {...register('from', {
                        validate: value => !value || moment(value).isValid()
                    })}
                />
            </div>
            <p className='margin-top-1rem margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='End of sales'/>
            </p>
            <div className='input'>
                <input
                    className='margin-0dot5rem-0 width-100percent'
                    placeholder='2024-12-30 10:00'
                    {...register('to', {
                        validate: value => !value || moment(value).isValid()
                    })}
                />
            </div>
            <div className='display-flex'>
                <div
                    className='button primary'
                    onClick={() => !isPending && submit()}
                >
                    <FormattedMessage defaultMessage='Filter'/>{isPending && '...'}
                </div>
            </div>
        </form>
        <div className='margin-top-0dot5rem'>
            <a
                className={classNames('link', {
                    'font-weight-bold': moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD') === wfrom
                })}
                onClick={(e) => {
                    e.preventDefault();
                    setValue('from', moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'), config)
                }}
            ><FormattedMessage defaultMessage='7 days'/></a>, <a
                className={classNames('link', {
                    'font-weight-bold': moment().subtract(1, 'months').startOf('day').format('YYYY-MM-DD') === wfrom
                })}
                onClick={(e) => {
                    e.preventDefault();
                    setValue('from', moment().subtract(1, 'months').startOf('day').format('YYYY-MM-DD'), config)
                }}
            ><FormattedMessage defaultMessage='month'/></a>, <a
                className={classNames('link', {
                    'font-weight-bold': moment().subtract(1, 'years').startOf('day').format('YYYY-MM-DD') === wfrom
                })}
                onClick={(e) => {
                    e.preventDefault();
                    setValue('from', moment().subtract(1, 'years').startOf('day').format('YYYY-MM-DD'), config)
                }}
            ><FormattedMessage defaultMessage='year'/></a>
        </div>
        <ChartSentImpl {...{
            setChartSent,
            refreshedQueryOptions,
            flag: isPending
        }}/>
        </>
    );
});

export default React.memo(({setChartSent}) => {
    return (
        <>
        <div className='font-weight-bold font-size-2dot5rem text-align-center margin-top-1rem'>
            <FormattedMessage defaultMessage='Ordered by you'/>
        </div>
        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
            <React.Suspense fallback={<div className='margin-top-1rem'><Skeleton/></div>}>
                <ChartSent {...{setChartSent}}/>
            </React.Suspense>
        </ErrorBoundary>
        </>
    );
});
