import React from 'react';
import './Impulses.scss';
import {graphql, useLazyLoadQuery, useMutation} from 'react-relay/hooks';
import searchFilter from '../../../searchFilter';
import {Link, useLocation} from 'react-router-dom';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import useQuery from '../../../useQuery';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import ImpulseImpl from './ImpulseImpl';
import {FormattedMessage} from 'react-intl';
import LocationPending from '../../LocationPending';
import {isTrue} from '../../../utility';
import Download from './Download';
import ErrorFallback from '../../ErrorFallback';

const dim = (value) => value ? value.split(',').map(Number) : [];

const Impulses = React.memo(({request}) => {
    const {size, page, contain, inbox, sent, status} = request;
    const data = useLazyLoadQuery(
        graphql`
            query ImpulsesQuery($request: ImpulsesRequestInput) {
                impulses(request: $request) {
                    totalPages
                    totalElements
                    content {
                        id
                        ...ImpulseImpl_impulse
                    }
                }
            }
        `,
        {
            request: {
                size: parseInt(size),
                page: parseInt(page),
                contain,
                inbox: isTrue(inbox),
                sent: isTrue(sent),
                status: dim(status)
            }
        }
    );
    const location = useLocation();
    const navigate = useNavigate();
    const {register, handleSubmit} = useForm();
    const submit = handleSubmit(data => {
        navigate(`${location.pathname}${searchFilter(location.search, {
            page: 0,
            contain: data.contain,
            inbox: data.inbox.toString(),
            sent: data.sent.toString(),
            status: data.status.join(',')
        }, false)}`);
    });
    const [flag, setFlag] = React.useState(false);
    return (
        <>
            <form className='form border-bottom-1px-dotted-black padding-bottom-1rem' onSubmit={submit}>
                <div className='input flex-1'><input className='width-100percent' {...register('contain')}/></div>
                <div>
                    <div className='display-flex'>
                        <div
                            className='button'
                            onClick={() => setFlag(flag => !flag)}
                        ><FormattedMessage defaultMessage='Filters'/></div>
                    </div>
                    <div className={classNames('inline', {'display-none': !flag})}>
                        <div className='checkbox margin-top-1rem'>
                            <label>
                                <div><input name='inbox' defaultChecked={isTrue(inbox)}  type='checkbox' {...register('inbox')}/></div>
                                <FormattedMessage defaultMessage='Inbox'/>
                            </label>
                        </div>
                        <div className='checkbox margin-top-1rem'>
                            <label>
                                <div><input name='sent' defaultChecked={isTrue(sent)} type='checkbox' {...register('sent')}/></div>
                                <FormattedMessage defaultMessage='Sent'/>
                            </label>
                        </div>
                        <div className='checkbox display-flex flex-wrap-wrap margin-top-1rem'>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(0)} value={0} {...register('status')}/>
                                <FormattedMessage defaultMessage='Active'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(1)} value={1} {...register('status')}/>
                                <FormattedMessage defaultMessage='Pending'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(2)} value={2} {...register('status')}/>
                                <FormattedMessage defaultMessage='Applied'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(3)} value={3} {...register('status')}/>
                                <FormattedMessage defaultMessage='Verifying'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(4)} value={4} {...register('status')}/>
                                <FormattedMessage defaultMessage='Complete'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(5)} value={5} {...register('status')}/>
                                <FormattedMessage defaultMessage='Expiring'/>
                            </label>
                            <label className='margin-right-1rem'>
                                <input name='status' type='checkbox' defaultChecked={dim(status).includes(6)} value={6} {...register('status')}/>
                                <FormattedMessage defaultMessage='Expired'/>
                            </label>
                        </div>
                    </div>
                </div>
                <div className='display-flex'>
                    <div
                        className='button primary'
                        onClick={() => submit()}
                    ><FormattedMessage defaultMessage='Search'/></div>
                </div>
            </form>
            <div className='display-flex flex-wrap-wrap'>
                {data.impulses.content.map((impulse) =>
                    <div key={impulse.id} className='impulses__card'>
                        <div className='border-bottom-1px-dotted-black padding-bottom-1rem height-100percent'>
                            <ImpulseImpl impulse={impulse} />
                        </div>
                    </div>
                )}
                {!data.impulses.content.length &&
                <span><FormattedMessage defaultMessage='No data for now'/></span>
                }
            </div>
            <div className='display-flex justify-content-flex-end flex-wrap-wrap'>
                {[...new Array(data.impulses.totalPages)].map((x, i) =>
                    <Link
                        key={i}
                        className={classNames('impulses__pager-item', {
                            'impulses__pager-item-active': parseInt(page) === i
                        })}
                        to={`${location.pathname}${searchFilter(location.search, {page: i}, false)}`}
                    >{i + 1}</Link>
                )}
                <div>{'| '}page</div>
            </div>
            <div className='display-flex justify-content-flex-end flex-wrap-wrap'>
                <Link
                    className={classNames('impulses__pager-item', {
                        'impulses__pager-item-active': parseInt(size) === 2
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 2}, false)}`}
                >2</Link>
                <Link
                    className={classNames('impulses__pager-item', {
                        'impulses__pager-item-active': parseInt(size) === 4
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 4}, false)}`}
                >4</Link>
                <Link
                    className={classNames('impulses__pager-item', {
                        'impulses__pager-item-active': parseInt(size) === 8
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 8}, false)}`}
                >8</Link>
                <div>{'| '}per page</div>
            </div>
            <div className='display-flex justify-content-flex-end'>
                <div>{data.impulses.totalElements} total elements</div>
            </div>
            <Download {...{contain, inbox, sent, status}}/>
        </>
        
    );
});

export default React.memo(() => {
    const {size = '8', page = '0', contain, inbox, sent, status = '3,4'} = useQuery();
    const request = {
        size, page, contain, inbox, sent, status
    }
    return (
        <div className='margin-top-1dot5rem'>
            <div className='font-size-2dot5rem font-weight-bold margin-bottom-0dot5rem'>
                <FormattedMessage defaultMessage='Orders'/>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense
                    fallback={
                        <div className='display-flex flex-wrap-wrap'>
                            {[1,2,3].map(i =>
                                <div key={i} className='card'>
                                    <div className='padding-bottom-1rem'>
                                        <Skeleton/>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                >
                    <LocationPending {...{data: request}}>
                        {deferredData => 
                            <Impulses request={deferredData}/>
                        }
                    </LocationPending>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});