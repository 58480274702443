/**
 * @generated SignedSource<<e6c78091aab348a5c7a966daf69f654a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Data",
    "kind": "LinkedField",
    "name": "dataById",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DownloadDataImplDataByIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DownloadDataImplDataByIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4e8736ff69b8523b64d05fb8e8c37825",
    "id": null,
    "metadata": {},
    "name": "DownloadDataImplDataByIdQuery",
    "operationKind": "query",
    "text": "query DownloadDataImplDataByIdQuery(\n  $request: DataByIdRequestInput\n) {\n  dataById(request: $request) {\n    id\n    status\n  }\n}\n"
  }
};
})();

node.hash = "c8ec3f8ebd5d3e61a499bf896f0a5612";

module.exports = node;
