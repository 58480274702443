/**
 * @generated SignedSource<<9cd0a9dc370efc9d06d7577e6d3ea65e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "size"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "size",
        "variableName": "size"
      }
    ],
    "concreteType": "Page_Service",
    "kind": "LinkedField",
    "name": "servicesByViewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "content",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameUk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServicesByViewerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ServicesByViewerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d93a58b6b5491f48d87c0a2f484ec6a7",
    "id": null,
    "metadata": {},
    "name": "ServicesByViewerQuery",
    "operationKind": "query",
    "text": "query ServicesByViewerQuery(\n  $size: Int!\n  $page: Int!\n) {\n  servicesByViewer(size: $size, page: $page) {\n    totalPages\n    content {\n      id\n      name\n      nameUk\n    }\n  }\n}\n"
  }
};
})();

node.hash = "b49227b67e43e02a0cfe2bd6b4511325";

module.exports = node;
