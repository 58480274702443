// There's no method to get records by mask from store
// https://relay.dev/docs/api-reference/store/
// Here it's written that relay is going to create method for searching connections with arquments
// https://relay.dev/docs/guided-tour/list-data/updating-connections/#connection-identity-with-filters

const getStoreRecordsByMask = (store, mask) => {
    const recordIds = [];
    const rootStore = store.getRoot();
    const clientRoot = rootStore._mutator.__sources.find((item) => {
        return item._records && item._records.get('client:root')
    });
    if (clientRoot && clientRoot._records) {
        clientRoot._records.forEach((value, key) => {
            if (key.includes(mask)) {
                recordIds.push(value.__id);
            }
        });
    }
    return recordIds;
}

export default getStoreRecordsByMask;