import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm, useFieldArray} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import getTagValue from '../../../../utilities/get-tag-value';
import Apply from '../../../Apply';
import useQuery from '../../../../useQuery';

const Tags = React.memo(({endpoint}) => {
    const fEndpoint = useFragment(
        graphql`
            fragment TagsCabinetEndpoint_endpoint on Endpoint {
                id
                tags
                version
            }
        `,
        endpoint
    );
    const mmDisplayValue = getTagValue(fEndpoint.tags, 'mmDisplay', {defaultValue: false}) === 'true';
    const mmDisplayRef = React.useRef();
    const defaultTagValues = fEndpoint.tags && fEndpoint.tags.filter(e => !e.startsWith('mmDisplay')).map(e => ({value: e})) || [];
    const {register, handleSubmit, control, formState: {errors}} = useForm({
        defaultValues: {
            inputs: defaultTagValues
        }
    });
    const {fields, remove, append} = useFieldArray({control, name: 'inputs'});
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation TagsCabinetEndpointMutation($request: UpdateEndpointTagsRequestInput) {
                updateEndpointTags(request: $request) {
                    id
                    tags
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fEndpoint.id, version: fEndpoint.version, tags: getTags(data)}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    const queryParams = useQuery();
    const getTags = (data) => {
        const tags = data.inputs.map(e => e.value);
        if (queryParams.apply && queryParams.apply.split(',').find(e => e === 'mm') && mmDisplayRef.current.checked) {
            tags.push(`mmDisplay:${mmDisplayRef.current.checked}`);
        }
        return tags;
    }
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Tags'/>
        </div>
        {fEndpoint.tags && !!fEndpoint.tags.length &&
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fEndpoint.tags.join(', ')}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    {fields.map((item, index) => (
                        <div key={item.id} className='input'>
                            <input
                                className='margin-0dot5rem-0 width-100percent'
                                {...register(`inputs.${index}.value`, {required: true})}
                                defaultValue={item.value}
                            />
                            {errors.inputs && errors.inputs[index] && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                            <div className='display-flex'>
                                <div className='button' onClick={() => remove(index)}>
                                    <FormattedMessage defaultMessage='Delete'/>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className='display-flex margin-top-0dot5rem'>
                        <div
                            className='button primary'
                            onClick={() => append({value: ''})}
                        >
                            <FormattedMessage defaultMessage='Append'/>
                        </div>
                        <div
                            className='button primary margin-left-1rem'
                            onClick={() => append({value: 'tickets.pdf'})}
                        >
                            tickets.pdf
                        </div>
                    </div>
                    <Apply {...{on: 'mm'}}>
                        <div className='display-flex margin-top-0dot5rem checkbox'>
                            <label>
                                <input type='checkbox' ref={mmDisplayRef} defaultChecked={mmDisplayValue}/>
                                <FormattedMessage defaultMessage='Display the product'/>
                            </label>
                        </div>
                    </Apply>
                    <div className='display-flex margin-top-0dot5rem'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Tags);
