/**
 * @generated SignedSource<<e6bfefda307cc136d285d0edceb0e895>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Addl",
    "kind": "LinkedField",
    "name": "updateAddlPlaceholder",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeholder",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "placeholderUk",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PlaceholderCabinetAddlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PlaceholderCabinetAddlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dcbefb520a373900c126b7dc4b644301",
    "id": null,
    "metadata": {},
    "name": "PlaceholderCabinetAddlMutation",
    "operationKind": "mutation",
    "text": "mutation PlaceholderCabinetAddlMutation(\n  $request: UpdateAddlPlaceholderRequestInput\n) {\n  updateAddlPlaceholder(request: $request) {\n    id\n    placeholder\n    placeholderUk\n    version\n  }\n}\n"
  }
};
})();

node.hash = "8e39f1f0aad0186150b248934c7af669";

module.exports = node;
