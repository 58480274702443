import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import {ADDL_TYPE} from "../../../../constants";
import {isSet} from "../../../../utility";

const Type = React.memo(({addl}) => {
    const fAddl = useFragment(
        graphql`
            fragment TypeCabinetAddl_addl on Addl {
                id
                type
                version
                merchant {
                    id
                }
            }
        `,
        addl
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation TypeCabinetAddlMutation($request: UpdateAddlTypeRequestInput) {
                updateAddlType(request: $request) {
                    id
                    type
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fAddl.id, version: fAddl.version, type: data.input === '' ? null : data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Field type'/>
        </div>
        {isSet(fAddl.type) &&
        <div className='font-weight-bold margin-top-0dot5rem'>{ADDL_TYPE[fAddl.type]}</div>
        }

        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type Input - one-line text input field'/>
                </p>
                <p className='margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type Textarea - multi-line text input field'/>
                </p>
                <p className='margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Type Checkbox - field that can be checked'/>
                </p>
                <form onSubmit={submit}>
                    <div className='select'>
                        <select
                            className='margin-0dot5rem-0'
                            defaultValue={isSet(fAddl.type) ? fAddl.type : ''}
                            {...register('input')}
                        >
                            <option value=''/>
                            <option value='0'>{ADDL_TYPE[0]}</option>
                            <option value='2'>{ADDL_TYPE[2]}</option>
                            <option value='3'>{ADDL_TYPE[3]}</option>
                        </select>
                    </div>
                    <div className='display-flex'>
                        <div
                            className={classNames('button primary', {'disabled': !fAddl.merchant})}
                            onClick={() => fAddl.merchant && !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Type);
