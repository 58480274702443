import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import i18n from '../../../../i18n';
import {FORM_FIELDS} from '../../DynamicTranslations';
import useQuery from '../../../../useQuery';
import ShowMoreQueryParam from '../../../ShowMoreQueryParam';
import replacePipe from '../../replace-pipe';

const Name = ({service}) => {
    const {locale = 'uk'} = useQuery();
    const fService = useFragment(
        graphql`
            fragment NameCabinetService_service on Service {
                id
                name
                nameUk
                version
            }
        `,
        service
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation NameCabinetServiceMutation($request: UpdateServiceNameRequestInput) {
                updateServiceName(request: $request) {
                    id
                    name
                    nameUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => {
        const replacedData = replacePipe(data, 'input', 'inputUk');
        apply({
            variables: {request: {id: fService.id, version: fService.version, name: replacedData.input, nameUk: replacedData.inputUk}},
            onCompleted: () => {setFlag(false)},
            onError: () => {}
        });
    });
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Event name'/>
        </div>
        <div className='font-weight-bold margin-top-0dot5rem'>{i18n(fService, 'name', locale)}</div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.name}
                            {...register('input', {required: true})}
                        />
                    </div>
                    {errors.input && <div className='input-error'><FormattedMessage defaultMessage='This field is required'/></div>}
                    <ShowMoreQueryParam>
                        <div className='input'>
                            <input
                                className='margin-0dot5rem-0 width-100percent'
                                defaultValue={fService.nameUk}
                                placeholder={i18n(FORM_FIELDS.languageUk, 'placeholder', locale)}
                                {...register('inputUk')}
                            />
                        </div>
                    </ShowMoreQueryParam>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
};
export default React.memo(Name);
