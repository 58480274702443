import React from 'react'
import './Queues.scss';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../searchFilter';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import useQuery from '../../useQuery';
import Skeleton from '../Skeleton';
import SuspenseList from '../SuspenseList';

const legal = 'https://www.ottry.com/legal';
const year = new Date().getFullYear();

const Queues = () => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='queues'>
            <div className='queues__header'>
                <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
            </div>
            <div className='queues__title'>
                <div className='queues__title-name'>Queues service</div>
                <div className='queues__title-description'>
                    Learn more about Ottry Queues service opportunities.
                </div>
            </div>
            <div className='queues__introduction'>
                Ottry is a multifunctional platform that allows you to sell goods and services online both on your website and on our marketplace.
            </div>
            <div className='view'>
                <div className='block'>
                    <div>
                        Check out our demo <span className='font-weight-bold'>Mister</span>{' '}
                        <Link
                            className='link font-weight-bold'
                            to={`/services/mister${searchFilter(location.search)}`}>shop</Link>,{' '}
                        <Link
                            className='link font-weight-bold'
                            to={`/services/mister/queue${searchFilter(location.search)}`}>queue</Link>,{' '}
                        <Link
                            className='link font-weight-bold'
                            to={`/services/mister/dashboard${searchFilter(location.search)}`}>dashboard</Link>,{' '}
                        <Link
                            className='link font-weight-bold'
                            to={`/services/mister/scan${searchFilter(location.search)}`}>scan</Link>.
                    </div>
                </div>
            </div>
            <div className='queues__footer-line'>
                <div className='queues__footer-line-left'>&nbsp;</div>
                <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                <div className='queues__footer-line-right'>&nbsp;</div>
            </div>
            <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                <a
                    className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                >Uk</a>
                {' '}
                <a
                    className={classNames('link', 'font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                    href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                >En</a>
            </div>
            <div className='queues__footer-content'>
                <div className='queues__footer-info'>
                    <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                    <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                    <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                </div>
            </div>
            <div className='queues__footer-rights'>
                © {year} Ottry. All rights reserved.
            </div>
        </div>
    )
};

export default React.memo(Queues);