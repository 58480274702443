/**
 * @generated SignedSource<<85f92e37295417110650cc152157e100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "page"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "size"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "page",
        "variableName": "page"
      },
      {
        "kind": "Variable",
        "name": "size",
        "variableName": "size"
      }
    ],
    "concreteType": "Page_Addl",
    "kind": "LinkedField",
    "name": "addlsByViewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalPages",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Addl",
        "kind": "LinkedField",
        "name": "content",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameUk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AddlsByViewerQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AddlsByViewerQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "7b2947323c5cf155602fa2e9a411a00e",
    "id": null,
    "metadata": {},
    "name": "AddlsByViewerQuery",
    "operationKind": "query",
    "text": "query AddlsByViewerQuery(\n  $size: Int!\n  $page: Int!\n) {\n  addlsByViewer(size: $size, page: $page) {\n    totalPages\n    content {\n      id\n      name\n      nameUk\n    }\n  }\n}\n"
  }
};
})();

node.hash = "faa2b8f379961339cbae3f5c411ad8cb";

module.exports = node;
