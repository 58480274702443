import React from 'react';
import {graphql, useMutation, useFragment, useLazyLoadQuery} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../../../searchFilter';
import useQuery from '../../../../useQuery';
import i18n from '../../../../i18n';

const Addls = React.memo(({service}) => {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    const data = useLazyLoadQuery(
        graphql`
            query AddlsByViewerCabinetServiceQuery($size: Int!, $page: Int!) {
                addlsByViewer(size: $size, page: $page) {
                    totalPages,
                    content {
                        id
                        name
                        nameUk
                    }
                }
            }
        `,
        {size: 100500, page: 0}
    );
    const fService = useFragment(
        graphql`
            fragment AddlsCabinetService_service on Service {
                id
                addls {
                    id
                    name
                    nameUk
                }
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation AddlsCabinetServiceMutation($request: UpdateServiceAddlsRequestInput) {
                updateServiceAddls(request: $request) {
                    id
                    addls {
                        id
                        name
                        nameUk
                    }
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, addls: data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Additional fields'/>
        </div>
        {fService.addls && !!fService.addls.length &&
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fService.addls.map(e => i18n(e, 'name', locale)).join(', ')}
        </div>
        }
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Additional fields to be filled in by the customer during order placement (for example, Name, Phone)'/>
                </p>
                <p className='margin-bottom-0dot5rem font-size-0dot8rem'>
                    <span><FormattedMessage defaultMessage='You can create additional field using'/></span>
                    {' '}
                    <Link className='link' to={`../../addls${searchFilter(location.search)}`}><FormattedMessage defaultMessage='this link'/></Link>
                </p>
                <form onSubmit={submit}>
                    <div className='display-flex flex-wrap-wrap margin-top-0dot5rem'>
                    {data.addlsByViewer.content.map(e =>
                        <div key={e.id} className='checkbox margin-right-0dot5rem margin-bottom-0dot5rem'>
                            <label>
                                <div><input
                                    defaultChecked={fService.addls && fService.addls.map(e => e.id).includes(e.id)}
                                    value={e.id}
                                    type='checkbox'
                                    {...register('input')}
                                /></div>
                                {i18n(e, 'name', locale)}
                            </label>
                        </div>
                    )}
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Addls);
