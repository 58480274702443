/**
 * @generated SignedSource<<f38c55347c8b2bba982a9daaabd08bad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Addl",
    "kind": "LinkedField",
    "name": "createAddl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCreateAddlMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCreateAddlMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4a1e074771919139196482da59d837e9",
    "id": null,
    "metadata": {},
    "name": "CreateCreateAddlMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCreateAddlMutation(\n  $request: AddlRequestInput\n) {\n  createAddl(request: $request) {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "6bb3c4850f5e354ff150c07e900a2da2";

module.exports = node;
