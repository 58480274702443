import React from 'react';
import './Footer.scss';
import {Link, useLocation} from 'react-router-dom';
import useQuery from '../../useQuery';
import classNames from 'classnames';
import searchFilter from '../../searchFilter';
import {FormattedMessage} from 'react-intl';
import Lol from '../Lol';

const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

function Footer() {
    const {locale = 'uk'} = useQuery();
    const location = useLocation();
    return (
        <>
        <div className='margin-top-1dot5rem'><Lol/></div>
        <div className='view'>
            <div className='block'>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('link', 'font-weight-bold', 'margin-right-0dot5rem', {
                            'font-size-1dot2rem': locale === 'uk'
                        })}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('link', 'font-weight-bold', {
                            'font-size-1dot2rem': locale === 'en'
                        })}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                    >En</a>
                </div>
                <div className='margin-bottom-1rem font-size-0dot8rem'>
                    <div className='footer__fast'>
                        <div className='footer__contacts'>
                            <p className='footer__fast-title'><FormattedMessage defaultMessage='Contacts'/></p>
                            <p><FormattedMessage defaultMessage='Ottry LLC'/></p>
                            <p><FormattedMessage defaultMessage='VAT identification number is 41385243'/></p>
                            <p><FormattedMessage defaultMessage='16, Hlybochytska Street, office 13, Kyiv, 04050'/></p>
                            <p><a className='focus' href='tel:+380979381162'>+38 097 938 1162</a></p>
                            <p><a className='focus' href='mailto:info@ottry.com'>info@ottry.com</a></p>
                        </div>
                        <div className='footer__useful'>
                            <p className='footer__fast-title'><FormattedMessage defaultMessage='Useful'/></p>
                            <p><Link
                                className='focus'
                                to={`/faq${searchFilter(location.search)}`}
                            ><FormattedMessage defaultMessage='How do I get my receipt?'/></Link></p>
                            <p><Link
                                className='focus'
                                to={`/faq${searchFilter(location.search)}`}
                            ><FormattedMessage defaultMessage='Can I exchange or return?'/></Link></p>
                            <p><Link
                                className='focus'
                                to={`/faq${searchFilter(location.search)}`}
                            ><FormattedMessage defaultMessage='Where can I write wishes, suggestions, complaints?'/></Link></p>
                        </div>
                        <div className='footer__payment'>
                            <img src='https://wfp-help.s3.eu-central-1.amazonaws.com/1568625809_payment_methods_1.png%3Fversion%3D1%26amp%3BmodificationDate%3D1510658069000%26amp%3Bapi%3Dv2' alt=''/>
                        </div>
                    </div>
                    <div className='footer__content margin-top-0dot5rem'>
                        <div className='footer__info'>
                            <a
                                href={formLink}
                                target='_blank'
                                rel='noopener noreferrer'
                            ><FormattedMessage defaultMessage='I want to sell online'/></a>
                            <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                                <FormattedMessage defaultMessage='Report an issue'/>
                            </a>
                            <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                            <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                            <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                        </div>
                    </div>
                    <div className='footer__rights'>
                        © {year} Ottry. All rights reserved.
                    </div>
                </div>
            </div>
        </div>
        </>
    );
}

export default React.memo(Footer);