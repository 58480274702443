/**
 * @generated SignedSource<<528d63b41af2f7e0e7e8061b8fc285ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "updateServiceNopng",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nopng",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NopngCabinetServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NopngCabinetServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1f045ec57adcd8e9d6736169e88b20e8",
    "id": null,
    "metadata": {},
    "name": "NopngCabinetServiceMutation",
    "operationKind": "mutation",
    "text": "mutation NopngCabinetServiceMutation(\n  $request: UpdateServiceNopngRequestInput\n) {\n  updateServiceNopng(request: $request) {\n    id\n    nopng\n    version\n  }\n}\n"
  }
};
})();

node.hash = "123473ab47d94d2cce922bca834b05bb";

module.exports = node;
