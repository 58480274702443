/**
 * @generated SignedSource<<a56c774e478906e5aeb1654a847200ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddlByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Addl",
        "kind": "LinkedField",
        "name": "addlById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NameCabinetAddl_addl"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PlaceholderCabinetAddl_addl"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "RegexCabinetAddl_addl"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TypeCabinetAddl_addl"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "NotEmptyCabinetAddl_addl"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddlByIdQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Addl",
        "kind": "LinkedField",
        "name": "addlById",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameUk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "version",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "merchant",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placeholder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "placeholderUk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "regex",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "message",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messageUk",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isStar",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "empty",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emptyUk",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a47e6a2b5bcd62127b55f80fa99a5e2",
    "id": null,
    "metadata": {},
    "name": "AddlByIdQuery",
    "operationKind": "query",
    "text": "query AddlByIdQuery(\n  $id: String\n) {\n  addlById(id: $id) {\n    id\n    ...NameCabinetAddl_addl\n    ...PlaceholderCabinetAddl_addl\n    ...RegexCabinetAddl_addl\n    ...TypeCabinetAddl_addl\n    ...NotEmptyCabinetAddl_addl\n  }\n}\n\nfragment NameCabinetAddl_addl on Addl {\n  id\n  name\n  nameUk\n  version\n  merchant {\n    id\n  }\n}\n\nfragment NotEmptyCabinetAddl_addl on Addl {\n  id\n  isStar\n  empty\n  emptyUk\n  version\n  merchant {\n    id\n  }\n}\n\nfragment PlaceholderCabinetAddl_addl on Addl {\n  id\n  placeholder\n  placeholderUk\n  version\n  merchant {\n    id\n  }\n}\n\nfragment RegexCabinetAddl_addl on Addl {\n  id\n  regex\n  message\n  messageUk\n  version\n  merchant {\n    id\n  }\n}\n\nfragment TypeCabinetAddl_addl on Addl {\n  id\n  type\n  version\n  merchant {\n    id\n  }\n}\n"
  }
};
})();

node.hash = "5b098cac43a05e608bafdf4024ed4777";

module.exports = node;
