import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import Marked from '../../../Marked';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';

const SuccessImpulseEmailTemplate = React.memo(({service}) => {
    const {locale = 'uk'} = useQuery();
    const fService = useFragment(
        graphql`
            fragment SuccessImpulseEmailTemplateCabinetService_service on Service {
                id
                successImpulseEmailTemplate
                successImpulseEmailTemplateUk
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation SuccessImpulseEmailTemplateCabinetServiceMutation($request: UpdateServiceSuccessImpulseEmailTemplateRequestInput) {
                updateServiceSuccessImpulseEmailTemplate(request: $request) {
                    id
                    successImpulseEmailTemplate
                    successImpulseEmailTemplateUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            successImpulseEmailTemplate: data.input,
            successImpulseEmailTemplateUk: data.inputUk
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Success impulse email template'/>
        </div>
        <div className='margin-top-0dot5rem'><Marked content={i18n(fService, 'successImpulseEmailTemplate', locale)}/></div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div>
                        <textarea
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fService.successImpulseEmailTemplate}
                            {...register('input')}
                        />
                    </div>
                    <div>
                        <textarea
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fService.successImpulseEmailTemplateUk}
                            {...register('inputUk')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(SuccessImpulseEmailTemplate);
