import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import {isSet, nullOnEmpty} from '../../../../utility';
import moment from 'moment';
import {STRATEGY} from "../../../../constants";
import ShowMoreQueryParam from '../../../ShowMoreQueryParam';

const Season = React.memo(({service}) => {
    const fService = useFragment(
        graphql`
            fragment SeasonCabinetService_service on Service {
                id
                version
                start
                end
                strategy
                every
            }
        `,
        service
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation SeasonCabinetServiceMutation($request: UpdateServiceSeasonRequestInput) {
                updateServiceSeason(request: $request) {
                    id
                    version
                    start
                    end
                    strategy
                    every
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            start: !data.start ? null : moment(data.start).utc().format(),
            end: !data.end ? null : moment(data.end).utc().format(),
            strategy: nullOnEmpty(data.strategy),
            every: nullOnEmpty(data.every)
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Start and end of sales'/>
        </div>
        <div className='font-weight-bold margin-top-0dot5rem'>
            {fService.start && <>{moment(fService.start).format('YYYY-MM-DD HH:mm:ss')}</>}
            /{fService.end && <>{moment(fService.end).format('YYYY-MM-DD HH:mm:ss')}</>}
            <ShowMoreQueryParam>
                /{isSet(fService.strategy) && <>{STRATEGY[fService.strategy]}</>}
                /{fService.every}
            </ShowMoreQueryParam>
        </div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Date format YYYY MM DD hh:mm. For example'/>
                    {' '}
                    {moment().format('YYYY-MM-DD')} 10:00
                </p>
                <form onSubmit={submit}>
                    <p className='margin-top-1rem margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='Start of sales'/>
                    </p>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.start && moment(fService.start).format('YYYY-MM-DD HH:mm:ss')}
                            {...register('start', {
                                validate: value => !value || moment(value).isValid()
                            })}
                            placeholder='2024-12-30 10:00'
                        />
                    </div>
                    <p className='margin-top-1rem margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='End of sales'/>
                    </p>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.end && moment(fService.end).format('YYYY-MM-DD HH:mm:ss')}
                            {...register('end', {
                                validate: value => !value || moment(value).isValid()
                            })}
                            placeholder='2024-12-30 10:00'
                        />
                    </div>
                    <ShowMoreQueryParam>
                        <div className='select'>
                            <select
                                className='margin-0dot5rem-0'
                                defaultValue={isSet(fService.strategy) ? fService.strategy : ''}
                                {...register('strategy')}
                            >
                                <option value=''/>
                                <option value='0'>{STRATEGY[0]}</option>
                                <option value='1'>{STRATEGY[1]}</option>
                                <option value='2'>{STRATEGY[2]}</option>
                            </select>
                        </div>
                        <div className='input'>
                            <input
                                className='margin-0dot5rem-0 width-100percent'
                                defaultValue={fService.every}
                                {...register('every')}
                            />
                        </div>
                    </ShowMoreQueryParam>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Season);
