/**
 * @generated SignedSource<<934cf850cd3d4c94a8b609fe0007fe59>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "updateServiceName",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "nameUk",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NameCabinetServiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NameCabinetServiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "dbbc53b831da9210e1339964cf180206",
    "id": null,
    "metadata": {},
    "name": "NameCabinetServiceMutation",
    "operationKind": "mutation",
    "text": "mutation NameCabinetServiceMutation(\n  $request: UpdateServiceNameRequestInput\n) {\n  updateServiceName(request: $request) {\n    id\n    name\n    nameUk\n    version\n  }\n}\n"
  }
};
})();

node.hash = "6dac8f20625b861023623383c4cdfe1a";

module.exports = node;
