import React from 'react';
import useQuery from '../../../useQuery';
import {isTrue} from '../../../utility';
import SuspenseList from '../../SuspenseList';
import TargetServices from './TargetServices';
import LatestImpulses from './LatestImpulses';

export default React.memo(() => {
    const {more} = useQuery();
    return (
        <SuspenseList revealOrder='forwards'>
            <LatestImpulses/>
            {isTrue(more) && <TargetServices/>}
        </SuspenseList>
    );
});
