/**
 * @generated SignedSource<<3c05cfdd250b855ff1c0eff2d55847fa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Impulsetick",
    "kind": "LinkedField",
    "name": "tryImpulsetick",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImpulseticksMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImpulseticksMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d653a523de52336c3393d6571293eef6",
    "id": null,
    "metadata": {},
    "name": "ImpulseticksMutation",
    "operationKind": "mutation",
    "text": "mutation ImpulseticksMutation(\n  $request: TryImpulsetickRequestInput\n) {\n  tryImpulsetick(request: $request) {\n    id\n    version\n    status\n  }\n}\n"
  }
};
})();

node.hash = "3209b586e90adf2c80de3a30500cf62b";

module.exports = node;
