import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import Marked from '../../../Marked';
import {upload} from '../../../../fh';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';

const config = {shouldValidate: true, shouldDirty: true};

const Img = React.memo(({setFollowingFlag, setFollowingValue, followingValue, aim, target}) => {
    const [uri, setUri] = React.useState();
    const [flag, setFlag] = React.useState();
    const [applying, setApplying] = React.useState();
    const {register, handleSubmit} = useForm();
    const onSubmit = async (data) => {
        const formData = new FormData();
        formData.append('file', data.file[0]);
        formData.append('aim', aim);
        formData.append('target', target);
        setApplying(true);
        try {
            const response = await upload('/api/upload', formData);
            const json = await response.json();
            setUri(json.uri);
            setApplying(false);
            setFlag(false);
        } catch (response) {
            setApplying(false);
        }
    };
    const submit = handleSubmit(onSubmit);
    return (
        <div>
            <div className='display-flex align-items-center'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                    <FormattedMessage defaultMessage='Cancel'/>
                    :
                    <FormattedMessage defaultMessage='Upload image'/>
                    }{applying && '...'}
                </div>
                {uri &&
                <div className='margin-left-0dot5rem overflow-x-auto white-space-nowrap'>
                    <a
                        className='link'
                        onClick={(e) => {
                            e.preventDefault();
                            setFollowingValue('input', `![Img](https://${uri})\r\n${followingValue || ''}`, config);
                            setFollowingFlag(true);
                        }}
                    >{uri}</a>
                </div>
                }
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Upload an image (max. 1MB). The file name must not contain Cyrillic letters and spaces. After uploading click on the link to add the image to the event description.'/>
                </p>
                <form onSubmit={submit}>
                    <input className='margin-0dot5rem-0' {...register('file')} type='file'/>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => submit()}
                        >
                            <FormattedMessage defaultMessage='Upload'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
    );
});

const Details = React.memo(({endpoint}) => {
    const {locale = 'uk'} = useQuery();
    const fEndpoint = useFragment(
        graphql`
            fragment DetailsCabinetEndpoint_endpoint on Endpoint {
                id
                details
                detailsUk
                version
            }
        `,
        endpoint
    );
    const {register, handleSubmit, setValue} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation DetailsCabinetEndpointMutation($request: UpdateEndpointDetailsRequestInput) {
                updateEndpointDetails(request: $request) {
                    id
                    details
                    detailsUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fEndpoint.id,
            version: fEndpoint.version,
            details: data.input,
            detailsUk: data.inputUk
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Details'/>
        </div>
        <div className='margin-top-0dot5rem'><Marked content={i18n(fEndpoint, 'details', locale)}/></div>
        <div className='padding-top-0dot5rem'>
            <Img {...{setFollowingFlag: setFlag, setFollowingValue: setValue, followingValue: fEndpoint.details, aim: 1, target: fEndpoint.id}}/>
        </div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div>
                        <textarea
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fEndpoint.details}
                            {...register('input')}
                        />
                    </div>
                    <div>
                        <textarea
                            className='textarea margin-0dot5rem-0'
                            rows='8'
                            defaultValue={fEndpoint.details}
                            {...register('inputUk')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Details);
