import React from 'react';
import {useParams, Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import ImpulseImpl from './ImpulseImpl';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import ErrorFallback from '../../ErrorFallback';

const Impulse = () => {
    const {id} = useParams();
    const data = useLazyLoadQuery(
        graphql`
            query ImpulseQuery($id: String) {
                impulseById(id: $id) {
                    ...ImpulseImpl_impulse
                }
            }
        `,
        {id}
    );
    return (
        <ImpulseImpl impulse={data.impulseById}/>
    );
};

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-1rem'>
                <Link className='focus' to={`/cabinet/impulses${searchFilter(location.search)}`}>
                    &#60; <FormattedMessage defaultMessage='Orders'/>
                </Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<Skeleton/>}>
                    <Impulse/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
