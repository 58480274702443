import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {useParams, Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import Name from './properties/Name';
import Placeholder from './properties/Placeholder';
import Regex from './properties/Regex';
import Type from './properties/Type';
import NotEmpty from './properties/NotEmpty';
import ShowMoreQueryParam from '../../ShowMoreQueryParam';
import ErrorFallback from '../../ErrorFallback';

const Addl = () => {
    const {id} = useParams();
    const query = useLazyLoadQuery(
        graphql`
            query AddlByIdQuery($id: String) {
                addlById(id: $id) {
                    id
                    ...NameCabinetAddl_addl
                    ...PlaceholderCabinetAddl_addl
                    ...RegexCabinetAddl_addl
                    ...TypeCabinetAddl_addl
                    ...NotEmptyCabinetAddl_addl
                }
            }
        `,
        {id}
    );
    return (
        <>
        <Name {...{addl: query.addlById}}/>
        <Placeholder {...{addl: query.addlById}}/>
        <NotEmpty {...{addl: query.addlById}}/>
        <ShowMoreQueryParam>
            <Regex {...{addl: query.addlById}}/>
        </ShowMoreQueryParam>
        <Type {...{addl: query.addlById}}/>
        </>
    );
};

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-1rem'>
                <Link className='focus' to={`/cabinet/addls${searchFilter(location.search)}`}>
                    &#60; <FormattedMessage defaultMessage='Additional fields'/>
                </Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<Skeleton/>}>
                    <Addl/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
