import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';

const Publish = React.memo(({service}) => {
    const fService = useFragment(
        graphql`
            fragment PublishCabinetService_service on Service {
                id
                publish
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation PublishCabinetServiceMutation($request: UpdateServicePublishRequestInput) {
                updateServicePublish(request: $request) {
                    id
                    publish
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, publish: data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Publish'/>
        </div>
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fService.publish ? <FormattedMessage defaultMessage='Yes'/> : <FormattedMessage defaultMessage='No'/>}
        </div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='checkbox margin-0dot5rem-0'>
                        <label>
                            <div>
                                <input
                                    type='checkbox'
                                    defaultChecked={fService.publish}
                                    {...register('input')}
                                />
                            </div>
                        </label>
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Publish);
