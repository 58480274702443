import React from 'react'
import './Arsenal.scss';
import {Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../../Skeleton';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import searchFilter from '../../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../../utility';
import SuspenseList from '../../../SuspenseList';
import SuspenseImage from '../../../SuspenseImageDynamicSize';
import ErrorFallback from '../../../ErrorFallback';

const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

const fancyId = process.env.NODE_ENV === 'development' ? '58b40a57cd0484f9e50041aa' : null;

const Poster = React.memo(({id, reference}) => {
    const {more, locale = 'uk'} = useQuery();
    const data = useLazyLoadQuery(
        graphql`
            query ArsenalPosterQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                }
            }
        `,
        {id}
    );
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    return (
        <div className='card'>
            <div className='font-weight-bold'>{i18n(data.serviceById, 'name', locale)}</div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{data.serviceById.id}</div>}
            <div className='inline'>
                <div className='margin-top-1rem'>
                    {cover &&
                    <div>
                        <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                            <SuspenseImage
                                className='home__logo'
                                src={cover}
                            />
                        </ErrorBoundary>
                    </div>
                    }
                    <Link
                        className='link font-weight-bold'
                        to={reference}
                    >I'm all in</Link>
                </div>
            </div>
        </div>
    );
});

const PosterWithSuspense = React.memo(({id, reference}) =>
    <ErrorBoundary>
        <React.Suspense fallback={
            <div className='card'>
                <Skeleton/>
            </div>
        }>
            <Poster {...{id, reference}}/>
        </React.Suspense>
    </ErrorBoundary>
);

const Arsenal = React.memo(() => {
    const location = useLocation();
    return (
        <>
        <Helmet>
            <title>ARSENAL XXII</title>
            <meta name='description' content='Club at famous Arsenal Factory, which was operating in Arms Industry since 1764.'/>
            {/*<meta property='og:image' content={cover}/>*/}
        </Helmet>
        <div>
            <div className='view'>
                <div className='block'>
                    <div className='font-size-2rem font-weight-bold text-align-center'>
                        ARSENAL XXII
                    </div>
                    <div className='color-grey text-align-center'>
                        Cultural Center
                    </div>
                    <div className='margin-top-2rem text-align-center'>
                        <div className='arsenal__circle'>&nbsp;</div>
                    </div>
                    <div className='margin-top-2rem text-align-center'>
                        Club at famous Arsenal Factory, which was operating in Arms Industry since 1764.
                    </div>
                    <div className='margin-top-1dot5rem'>
                        <SuspenseList revealOrder='forwards'>
                            <React.Suspense fallback={<Skeleton/>}>
                                <div className='font-size-2rem font-weight-bold margin-bottom-0dot5rem'>Timeline</div>
                            </React.Suspense>
                            <div className='display-flex flex-wrap-wrap margin-top-1rem'>
                                <SuspenseList revealOrder='forwards'>
                                    <PosterWithSuspense {...{
                                        id: fancyId || '6161d586a5d58ab6cf67b546',
                                        reference: `/services/warp/${fancyId || '6161d586a5d58ab6cf67b546'}${searchFilter(location.search)}`
                                    }}/>
                                    <PosterWithSuspense {...{
                                        id: fancyId || '5d4fc27cd335cb7fc1c2c17f',
                                        reference: `/services/warp/${fancyId || '5d4fc27cd335cb7fc1c2c17f'}${searchFilter(location.search)}`
                                    }}/>
                                </SuspenseList>
                            </div>
                            <React.Suspense fallback={<Skeleton/>}>
                                <div className='font-size-2rem font-weight-bold margin-bottom-0dot5rem'>Took place</div>
                            </React.Suspense>
                            <div className='display-flex flex-wrap-wrap margin-top-1rem'>
                                <SuspenseList revealOrder='forwards'>
                                    <PosterWithSuspense {...{
                                        id: fancyId || '5de56d52ce5c1f60efbc8cdf',
                                        reference: `/services/warp/${fancyId || '5de56d52ce5c1f60efbc8cdf'}${searchFilter(location.search)}`
                                    }}/>
                                    <PosterWithSuspense {...{
                                        id: fancyId || '60f8129d2ccb0e3e9c9ddc31',
                                        reference: `/services/brave${searchFilter(location.search)}`
                                    }}/>
                                </SuspenseList>
                            </div>
                        </SuspenseList>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
});

export default React.memo(() => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='arsenal black'>
            <div className='arsenal__header'>
                <Link to={`/${searchFilter(location.search)}`}><span className='arsenal__accent font-weight-bold'>Impulse</span> by Ottry</Link>
            </div>
            <Arsenal/>
            <div className='arsenal__footer'>
                <div className='arsenal__footer-line'>
                    <div className='arsenal__footer-line-left'>&nbsp;</div>
                    <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                    <div className='arsenal__footer-line-right'>&nbsp;</div>
                </div>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                    >En</a>
                </div>
                <div className='arsenal__payment'>
                    <img
                        src='https://wfp-help.s3.eu-central-1.amazonaws.com/1568625809_payment_methods_1.png%3Fversion%3D1%26amp%3BmodificationDate%3D1510658069000%26amp%3Bapi%3Dv2'
                        alt=''/>
                </div>
                <div className='arsenal__footer-content'>
                    <div className='arsenal__footer-info'>
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                    </div>
                </div>
                <div className='arsenal__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
});