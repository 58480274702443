import React from 'react';
import {DEFAULT_SERVICE_DESCRIPTION_SEPARATOR} from '../constants';
import getTagValue from './get-tag-value';
import searchFilter from '../searchFilter';
import {isSet} from '../utility';

const getServiceUrl = ({description, id, slug, warp, location}) => {
    return React.useMemo(() => {
        const serviceTags = description && description.split(DEFAULT_SERVICE_DESCRIPTION_SEPARATOR) || [];
        const isNewService = !!getTagValue(serviceTags, 'theme');
        const fancyPath = getTagValue(serviceTags, 'fancy', {defaultValue: ''});
        const getFancyPath = () => {
            return `${fancyPath.includes('services') ? `${fancyPath}${searchFilter(location.search)}` : `/services${fancyPath}`}/${slug ? encodeURIComponent(slug) : id}${searchFilter(location.search)}`;
        };
        const getDefaultPath = () => {
            return isSet(warp) ?
                `/services/warp/${slug ? encodeURIComponent(slug) : id}${searchFilter(location.search)}`
                : `/services/${id}${searchFilter(location.search)}`;
        };
        const getNewServicePath = () => {
            return `/events/${slug ? encodeURIComponent(slug) : id}${searchFilter(location.search)}`;
        };
        const serviceUrl = () => {
            return fancyPath ? getFancyPath() : isNewService ? getNewServicePath() : getDefaultPath();
        };
        return serviceUrl();
    }, [description, id, slug, warp, location]);
};

export default getServiceUrl;