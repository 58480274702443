import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import i18n from '../../../../i18n';
import {FORM_FIELDS} from '../../DynamicTranslations';
import useQuery from '../../../../useQuery';
import ShowMoreQueryParam from '../../../ShowMoreQueryParam';

const Location = ({service}) => {
    const {locale = 'uk'} = useQuery();
    const fService = useFragment(
        graphql`
            fragment LocationCabinetService_service on Service {
                id
                location
                locationUk
                version
            }
        `,
        service
    );
    const {register, handleSubmit, formState: {errors}} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation LocationCabinetServiceMutation($request: UpdateServiceLocationRequestInput) {
                updateServiceLocation(request: $request) {
                    id
                    location
                    locationUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, location: data.input, locationUk: data.inputUk}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Event location'/>
        </div>
        <div className='font-weight-bold margin-top-0dot5rem'>{i18n(fService, 'location', locale)}</div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='Preferably specify in the format'/>
                    {' '}
                    <span className='font-weight-bold'><FormattedMessage defaultMessage='City, location'/></span>
                </p>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.location}
                            {...register('input')}
                        />
                    </div>
                    <ShowMoreQueryParam>
                        <div className='input'>
                            <input
                                className='margin-0dot5rem-0 width-100percent'
                                defaultValue={fService.locationUk}
                                placeholder={i18n(FORM_FIELDS.languageUk, 'placeholder', locale)}
                                {...register('inputUk')}
                            />
                        </div>
                    </ShowMoreQueryParam>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
};
export default React.memo(Location);
