import React from 'react';
import {FormattedMessage} from 'react-intl';
import qs from 'qs';
import {graphql, useMutation, useLazyLoadQuery} from 'react-relay/hooks';
import useQuery from '../../../useQuery';
import {isTrue, timeZone} from '../../../utility';
import useMountedState from '../../../useMountedState';
import useSubscription from '../../../useSubscription';
import classNames from 'classnames';
import useSubscriptionStatus from '../../../useSubscriptionStatus';

const dim = (value) => value ? value.split(',').map(Number) : [];

const RAZZLE_GRECAPTCHA = process.env.RAZZLE_GRECAPTCHA;
const RAZZLE_APP_PREFIX = process.env.RAZZLE_APP_PREFIX;

const DataImpl = React.memo(({refreshedQueryOptions, flag}) => {
    const {dataById} = useLazyLoadQuery(
        graphql`
            query DownloadDataImplDataByIdQuery($request: DataByIdRequestInput) {
                dataById(request: $request) {
                    id
                    status
                }
            }
        `,
        refreshedQueryOptions.variables,
        refreshedQueryOptions.options
    );
    const [commit, isMutationInFlight] = useMutation(
        graphql`
            mutation DownloadDataImplHandleMutation {
                handle {
                    accessToken
                }
            }
    `);
    return (
        <div className={classNames({'opacity-0dot6': flag})}>
            <div
                className='button primary margin-left-0dot5rem'
                onClick={() => !isMutationInFlight && dataById.status === 1 && commit({
                    onCompleted: (payload) =>
                        window.location.href = RAZZLE_APP_PREFIX + `/api/data/download?` + qs.stringify({
                            handle: payload.handle.accessToken,
                            data: dataById.id
                        }),
                    onError: () => {}
                })}
            >
                Download
                {(isMutationInFlight || dataById.status !== 1) && '...'}
                {dataById.status === 3 && 'expired :('}
            </div>
        </div>
    );
});

const Data = React.memo(({data}) => {
    const [refreshedQueryOptions, setRefreshedQueryOptions] = React.useState({
        options: {fetchKey: 0, fetchPolicy: 'network-only'},
        variables: {request: {id: data, fetchKey: 0}}
    });
    const [isPending, startTransition] = React.useTransition();
    const refresh = React.useCallback(() => {
        startTransition(() => {
            setRefreshedQueryOptions(prev => ({
                ...prev,
                options: {
                    ...prev.options,
                    fetchKey: prev.options.fetchKey + 1
                },
                variables: {
                    request: {
                        ...prev.variables.request,
                        fetchKey: prev.variables.request.fetchKey + 1
                    }
                }
            }));
        });
    }, []);
    const payload = React.useMemo(() => ({
        channel: `/datas/${data}`,
        onNext: () => refresh(),
        onError: () => {}
    }), [refresh, data]);
    useSubscription(payload);
    const subscriptionStatus = useSubscriptionStatus();
    React.useEffect(() => {
        if (subscriptionStatus === 0)
            refresh();
    }, [subscriptionStatus, refresh]);
    return (
        <DataImpl {...{
            refreshedQueryOptions,
            flag: isPending
        }}/>
    );
});

const DownloadEx = React.memo(({page, size, contain, inbox, sent, status, locale, timeZone}) => {
    const [data, setData] = React.useState();

    const [create, creating] = useMutation(
        graphql`
            mutation DownloadDownloadExCreateDataMutation($request: DataRequestInput) {
                createData(request: $request) {
                    id
                }
            }
    `);
    const [grecaptcha, setGrecaptcha] = useMountedState(false);
    const submit = () => {
        setGrecaptcha(true);
        window.grecaptcha.ready(() => {
            window.grecaptcha.execute(RAZZLE_GRECAPTCHA, {action: 'submit'}).then((token) => {
                create({
                    variables: {
                        request: {
                            reCaptcha: token,
                            size: parseInt(size),
                            page: parseInt(page),
                            contain,
                            inbox: isTrue(inbox),
                            sent: isTrue(sent),
                            status: dim(status),
                            locale, 
                            timeZone
                        }
                    },
                    onCompleted: (response) => {
                        setData(response?.createData?.id);
                    },
                    onError: () => {}
                });
                setGrecaptcha(false);
            });
        });
    };
    
    return (
        <>
        <div className='margin-top-1rem display-flex align-items-center'>
            {data ?
            <div 
                className='cursor-pointer' 
                onClick={() =>setData()}
            >+ New request</div>
            :
            <div
                className='button'
                onClick={() => !grecaptcha && !creating && submit()}
            ><FormattedMessage defaultMessage='Download'/>{(grecaptcha || creating) && '...'}</div>
            }
            {data && <React.Suspense fallback={
                <div className='margin-left-0dot5rem'><div className='button'>Loading...</div></div>
            }><Data {...{data}}/></React.Suspense>
            }
        </div>
        </>
    );
});

export default React.memo(({contain, inbox, sent, status}) => {
    const {locale = 'uk'} = useQuery();
    return (
        <div className='margin-top-1rem border-top-1px-dotted-black'>
            <DownloadEx {...{page: 0, size: 100500, contain, inbox, sent, status, locale, timeZone: timeZone()}}/>
        </div>
    );
});
