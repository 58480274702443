import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import Marked from '../../../Marked';
import {Link} from 'react-router-dom';
import i18n from '../../../../i18n';
import {FORM_FIELDS} from '../../DynamicTranslations';
import useQuery from '../../../../useQuery';
import getServiceUrl from '../../../../utilities/get-service-url';
import {useLocation} from 'react-router-dom';

const Slug = React.memo(({service}) => {
    const {locale = 'uk'} = useQuery();
    const fService = useFragment(
        graphql`
            fragment SlugCabinetService_service on Service {
                id
                slug
                warp
                description
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation SlugCabinetServiceMutation($request: UpdateServiceSlugRequestInput) {
                updateServiceSlug(request: $request) {
                    id
                    slug
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fService.id,
            version: fService.version,
            slug: data.input
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    const location = useLocation();
    const serviceLink = getServiceUrl({description: fService.description, id: fService.id, slug: fService.slug, warp: fService.warp, location});
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='A unique readable link to the event'/>
        </div>
        <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
            <FormattedMessage defaultMessage='Current link to the event'/>
            {' '}
            <Link className='link' to={serviceLink}>
                https://ottry.com{serviceLink}
            </Link>
        </p>
        <div className='margin-top-0dot5rem'><Marked content={fService.slug}/></div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <p className='margin-top-1rem margin-bottom-0dot5rem font-size-0dot8rem'>
                    <FormattedMessage defaultMessage='In the field below, you can specify the text that will be displayed in the link instead of'/>
                    {' '}
                    <span className='font-weight-bold'>{fService.slug ? fService.slug : fService.id}</span>
                </p>
                <form onSubmit={submit}>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fService.slug}
                            placeholder={i18n(FORM_FIELDS.slug, 'placeholder', locale)}
                            {...register('input')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Slug);
