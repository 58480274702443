import React from 'react';
import './Endpoints.scss';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {Link, useLocation} from 'react-router-dom';
import searchFilter from '../../../searchFilter';
import classNames from 'classnames';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import LocationPending from '../../LocationPending';
import i18n from '../../../i18n';
import ErrorFallback from '../../ErrorFallback';

const Endpoints = React.memo(({request}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    const {size, page} = request;
    const data = useLazyLoadQuery(
        graphql`
            query EndpointsByViewerQuery($size: Int!, $page: Int!) {
                endpointsByViewer(size: $size, page: $page) {
                    totalPages,
                    content {
                        id
                        name
                        nameUk
                    }
                }
            }
        `,
        {size, page}
    );
    return (
        <>
            <div className='display-flex flex-wrap-wrap'>
                {data.endpointsByViewer.content.map((endpoint) =>
                    <div key={endpoint.id} className='cabinet-endpoints__card'>
                        <div className='border-bottom-1px-dotted-black height-100percent display-flex flex-direction-column'>
                            <div className='font-weight-bold'>
                                <Link to={`${endpoint.id}${searchFilter(location.search)}`} className='cabinet-list-link'>
                                    {i18n(endpoint, 'name', locale)}
                                </Link>
                            </div>
                            <div className='cabinet-endpoints__card-meta font-size-0dot8rem color-grey'>
                                {endpoint.id}
                            </div>
                        </div>
                    </div>
                )}
                {!data.endpointsByViewer.content.length &&
                <span><FormattedMessage defaultMessage='No data for now'/></span>
                }
            </div>
            <div className='display-flex justify-content-flex-end flex-wrap-wrap'>
                {[...new Array(data.endpointsByViewer.totalPages)].map((x, i) =>
                    <Link
                        key={i}
                        className={classNames('cabinet-endpoints__pager-item', {
                            'cabinet-endpoints__pager-item-active': parseInt(page) === i
                        })}
                        to={`${location.pathname}${searchFilter(location.search, {page: i}, false)}`}
                    >
                        {i + 1}
                    </Link>
                )}
                <div className='cabinet-endpoints__pager-item'>{'| '}page</div>
            </div>
            <div className='display-flex justify-content-flex-end flex-wrap-wrap'>
                <Link
                    className={classNames('cabinet-endpoints__pager-item', {
                        'cabinet-endpoints__pager-item-active': parseInt(size) === 2
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 2}, false)}`}
                >2</Link>
                <Link
                    className={classNames('cabinet-endpoints__pager-item', {
                        'cabinet-endpoints__pager-item-active': parseInt(size) === 4
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 4}, false)}`}
                >4</Link>
                <Link
                    className={classNames('cabinet-endpoints__pager-item', {
                        'cabinet-endpoints__pager-item-active': parseInt(size) === 8
                    })}
                    to={`${location.pathname}${searchFilter(location.search, {page:0, size: 8}, false)}`}
                >8</Link>
                <div className='cabinet-endpoints__pager-item'>
                    {'| '}per page
                </div>
            </div>
        </>
    );
});

export default React.memo(() => {
    const location = useLocation();
    const {size = 8, page = 0} = useQuery();
    const request = { size, page};
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-0dot5rem'>
                <span className='font-size-2dot5rem font-weight-bold '><FormattedMessage defaultMessage='Tickets'/></span>
                <div className='display-flex justify-content-flex-end'>
                    <Link
                        className='link font-weight-bold'
                        to={`create${searchFilter(location.search)}`}
                    >+ <FormattedMessage defaultMessage='Create'/></Link>
                </div>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense
                    fallback={
                        <>
                        <div className='display-flex flex-wrap-wrap'>
                            {[1,2,3].map(i =>
                                <div key={i} className='card'>
                                    <div className='height-100percent display-flex flex-direction-column'>
                                        <Skeleton/>
                                    </div>
                                </div>
                            )}
                        </div>
                        </>
                    }
                >
                    <LocationPending {...{data: request}}>
                        {deferredData =>
                            <Endpoints {...{request: deferredData}}/>
                        }
                    </LocationPending>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
