import React from 'react'
import './Shum.scss';
import {Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from './../../../Skeleton';
import {CartStateContext, CartDispatchContext} from '../../../../contexts/cart';
import {useNavigate} from 'react-router-dom';
import {graphql, useLazyLoadQuery, useFragment} from 'react-relay/hooks';
import searchFilter from '../../../../searchFilter';
import {Helmet} from 'react-helmet-async';
import Marked from './../../../Marked';
import i18n from '../../../../i18n';
import useQuery from '../../../../useQuery';
import classNames from 'classnames';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../../utility';
import useNow from '../../../../useNow';
import {adopt} from '../../../../math';
import {IN, ENTITY_TYPE} from '../../../../constants';
import Mini from './../../mini/Mini';
import ErrorFallback from '../../../ErrorFallback';

const legal = 'https://www.ottry.com/legal';
const facebookLink = 'https://www.facebook.com/OTTRYcom';
const formLink = 'https://docs.google.com/forms/d/e/1FAIpQLSesrYhmBpJV-mvZD9AHOM79MyP8CcgSzFANSQWNby__dilgRw/viewform';
const year = new Date().getFullYear();

const ShumEndpoint = React.memo(({endpoint, serviceById}) => {
    const location = useLocation();
    const {locale = 'uk', more} = useQuery();
    const navigate = useNavigate();
    const state = React.useContext(CartStateContext);
    const dispatch = React.useContext(CartDispatchContext);
    const fEndpoint = useFragment(
        graphql`
            fragment ShumEndpoint_endpoint on Endpoint {
                id
                name
                nameUk
                quantity
                price
                start
                end
                strategy
                every
            }
        `,
        endpoint
    );
    const fServiceById = useFragment(
        graphql`
            fragment ShumEndpoint_serviceById on Service {
                id
            }
        `,
        serviceById
    );
    const count = React.useMemo(() =>
            state
                .filter(e => e.serviceId === fServiceById.id && e.endpointId === fEndpoint.id)
                .reduce((accumulator, currentValue) => accumulator + currentValue.quantity, 0),
        [state, fEndpoint.id, fServiceById.id]
    );
    const now = useNow();
    const dpt = React.useMemo(() => adopt(now, fEndpoint.start, fEndpoint.end, fEndpoint.strategy,
        fEndpoint.every), [now, fEndpoint]);
    return (
        <div className='margin-top-1rem'>
            <div className='font-weight-bold'>
                {i18n(fEndpoint, 'name', locale)} {fEndpoint.price} UAH
            </div>
            {isTrue(more) && <div className='margin-top-0dot2rem font-size-0dot8rem color-grey'>{fEndpoint.id}</div>}
            <Mini {...{
                start: fEndpoint.start,
                end: fEndpoint.end,
                strategy: fEndpoint.strategy,
                every: fEndpoint.every,
                entityType: ENTITY_TYPE.endpoint
            }}/>
            {dpt.state === IN &&
            <>
            {(fEndpoint.quantity < 16 || isTrue(more)) && <div className='input-warning'>{fEndpoint.quantity} <FormattedMessage defaultMessage='left'/></div>}
            <div className='display-flex margin-top-1rem'>
                {/*<div*/}
                    {/*className='button primary'*/}
                    {/*onClick={() => dispatch({*/}
                        {/*type: 'add',*/}
                        {/*payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id}*/}
                    {/*})}*/}
                {/*>*/}
                    {/*+1 в кошик*/}
                    {/*{!!count && <span className='badge margin-left-0dot25rem'>{count}</span>}*/}
                {/*</div>*/}
                <div
                    className='button primary'
                    onClick={() => {
                        !count && dispatch({
                            type: 'add',
                            payload: {endpointId: fEndpoint.id, serviceId: fServiceById.id}
                        });
                        navigate(`/checkout-new/cart${searchFilter(location.search)}`);
                    }}
                >
                    {count ? <FormattedMessage defaultMessage='Already in the cart, go'/> : <FormattedMessage defaultMessage='Buy'/>}
                </div>
            </div>
            </>
            }
        </div>
    );
});

const Shum = ({id}) => {
    const data = useLazyLoadQuery(
        graphql`
            query ShumQuery($id: String) {
                serviceById(id: $id) {
                    id
                    name
                    nameUk
                    details
                    detailsUk
                    start
                    end
                    strategy
                    every
                    endpoints {
                        id
                        quantity
                        ...ShumEndpoint_endpoint
                    }
                    ...ShumEndpoint_serviceById
                }
            }
        `,
        {id}
    );
    const {locale = 'uk', more} = useQuery();
    const cover = React.useMemo(() => {
        if (i18n(data.serviceById, 'details', locale)) {
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/i;
            const found = i18n(data.serviceById, 'details', locale).match(regex);
            return found && found[0];
        }
    }, [data, locale]);
    const now = useNow();
    const {state} = React.useMemo(() => adopt(now, data.serviceById.start, data.serviceById.end, data.serviceById.strategy,
        data.serviceById.every), [now, data.serviceById]);
    return (
        <>
        <Helmet>
            <title>{i18n(data.serviceById, 'name', locale)}</title>
            <meta name='description' content={i18n(data.serviceById, 'name', locale)}/>
            <meta property='og:image' content={cover}/>
        </Helmet>
        <div>
            <div className='view'>
                <div className='block'>
                    <div className='font-size-2rem font-weight-bold text-align-center margin-bottom-1rem'>
                        {i18n(data.serviceById, 'name', locale)}
                    </div>
                    <Marked {...{content: i18n(data.serviceById, 'details', locale)}}/>
                    {i18n(data.serviceById, 'details', locale) && i18n(data.serviceById, 'details', locale).endsWith('!') ?
                        <>
                        </>
                        :
                        <>
                        <Mini {...{
                            start: data.serviceById.start,
                            end: data.serviceById.end,
                            strategy: data.serviceById.strategy,
                            every: data.serviceById.every,
                            entityType: ENTITY_TYPE.service
                        }}/>
                        {state === IN &&
                        <>
                        {data.serviceById.endpoints.map((endpoint, index) =>
                        <div key={endpoint.id}>
                            {(
                                more ||
                                (
                                    (
                                        index === 0 ||
                                        (index > 0 && data.serviceById.endpoints[index -1].quantity < 16)
                                    ) && data.serviceById.endpoints[index].quantity > 0
                                )
                            ) && <ShumEndpoint endpoint={endpoint} serviceById={data.serviceById}/>
                            }
                        </div>
                        )}
                        {!data.serviceById.endpoints.some(e => e.quantity) && <div>Квитки закінчилися</div>}
                        </>
                        }
                        </>
                    }
                    <br/>
                    <p className='font-size-0dot8rem'><FormattedMessage defaultMessage='* After payment, a letter with a QR code will be sent to your e-mail address, which must be presented at the entrance. Please note that in the case of using the Gmail mail service, the letter with the ticket may end up in the "Alerts", "Promotions" or "Social networks" folder.'/></p>
                </div>
            </div>
        </div>
        </>
    )
};

export default React.memo(({id}) => {
    const location = useLocation();
    const {locale = 'uk'} = useQuery();
    return (
        <div className='shum black'>
            <div className='shum__header'>
                <Link to={`/${searchFilter(location.search)}`}><span className='shum__accent font-weight-bold'>Impulse</span> by Ottry</Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={
                    <div className='view'>
                        <div className='block'>
                            <Skeleton/>
                        </div>
                    </div>
                }>
                    <Shum {...{id}}/>
                </React.Suspense>
            </ErrorBoundary>
            <div className='shum__footer'>
                <div className='shum__footer-line'>
                    <div className='shum__footer-line-left'>&nbsp;</div>
                    <Link to={`/${searchFilter(location.search)}`}>Ottry</Link>
                    <div className='shum__footer-line-right'>&nbsp;</div>
                </div>
                <div className='display-flex justify-content-center align-items-baseline margin-bottom-0dot5rem'>
                    <a
                        className={classNames('font-weight-bold', 'margin-right-0dot5rem', {'font-size-1dot2rem': locale === 'uk'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'uk'}, false)}`}
                    >Uk</a>
                    {' '}
                    <a
                        className={classNames('font-weight-bold', {'font-size-1dot2rem': locale === 'en'})}
                        href={`${location.pathname}${searchFilter(location.search, {locale: 'en'}, false)}`}
                    >En</a>
                </div>
                <div className='shum__payment'>
                    <img
                        src='https://wfp-help.s3.eu-central-1.amazonaws.com/1568625809_payment_methods_1.png%3Fversion%3D1%26amp%3BmodificationDate%3D1510658069000%26amp%3Bapi%3Dv2'
                        alt=''/>
                </div>
                <div className='shum__footer-content'>
                    <div className='shum__footer-info'>
                        <a
                            href={formLink}
                            target='_blank'
                            rel='noopener noreferrer'
                        ><FormattedMessage defaultMessage='I want to sell online'/></a>
                        <a href={facebookLink} target='_blank' rel='noopener noreferrer'>
                            <FormattedMessage defaultMessage='Report an issue'/>
                        </a>
                        <Link to={`/rules${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Rules'/></Link>
                        <Link to={`/terms${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Terms of service'/></Link>
                        <Link to={`/privacy${searchFilter(location.search)}`}><FormattedMessage defaultMessage='Privacy policy'/></Link>
                    </div>
                </div>
                <div className='shum__footer-rights'>
                    © {year} Ottry. All rights reserved.
                </div>
            </div>
        </div>
    );
});
