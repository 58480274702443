/**
 * @generated SignedSource<<92c541e1425d94f20cfe87e13df4756e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "updateServiceCoupons",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Coupon",
        "kind": "LinkedField",
        "name": "coupons",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CouponsCabinetServiceMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CouponsCabinetServiceMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "546150b101ba61c5a5e210e90cb8130b",
    "id": null,
    "metadata": {},
    "name": "CouponsCabinetServiceMutation",
    "operationKind": "mutation",
    "text": "mutation CouponsCabinetServiceMutation(\n  $request: UpdateServiceCouponsRequestInput\n) {\n  updateServiceCoupons(request: $request) {\n    id\n    coupons {\n      id\n      code\n      discount\n    }\n    version\n  }\n}\n"
  }
};
})();

node.hash = "4c0c022341684db4edc155b6759c8d3e";

module.exports = node;
