/**
 * @generated SignedSource<<7f0d01d8befc629e767deef91516bc94>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "ValueObject",
    "kind": "LinkedField",
    "name": "numbers",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NumbersServiceNumbersImplQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NumbersServiceNumbersImplQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "eab7426e54e54397f29731f0fae2ac89",
    "id": null,
    "metadata": {},
    "name": "NumbersServiceNumbersImplQuery",
    "operationKind": "query",
    "text": "query NumbersServiceNumbersImplQuery(\n  $request: ServiceNumbersRequestInput\n) {\n  numbers(request: $request) {\n    value\n  }\n}\n"
  }
};
})();

node.hash = "c5db6699f447eb8b2842e8f7d835e02d";

module.exports = node;
