import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';

const Demo = React.memo(({service}) => {
    const fService = useFragment(
        graphql`
            fragment DemoCabinetService_service on Service {
                id
                demo
                version
            }
        `,
        service
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation DemoCabinetServiceMutation($request: UpdateServiceDemoRequestInput) {
                updateServiceDemo(request: $request) {
                    id
                    demo
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {id: fService.id, version: fService.version, demo: data.input}},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Demo mode'/>
        </div>
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fService.demo ? <FormattedMessage defaultMessage='Activated'/> : <FormattedMessage defaultMessage='Deactivated'/>}
        </div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='checkbox margin-0dot5rem-0'>
                        <label>
                            <div>
                                <input
                                    type='checkbox'
                                    defaultChecked={fService.demo}
                                    {...register('input')}
                                />
                            </div>
                            <FormattedMessage defaultMessage='Testing of payment system mode. Funds will be returned to the card 30 minutes after payment.'/>
                        </label>
                    </div>
                    <div className='display-flex'>
                        <div
                            className='button primary'
                            onClick={() => !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(Demo);
