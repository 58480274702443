/**
 * @generated SignedSource<<cbb76ec4e828f62f906cb3d835b015ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "request"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "request",
        "variableName": "request"
      }
    ],
    "concreteType": "Service",
    "kind": "LinkedField",
    "name": "updateServiceAddls",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Addl",
        "kind": "LinkedField",
        "name": "addls",
        "plural": true,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nameUk",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddlsCabinetServiceMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddlsCabinetServiceMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "1e6752b43306b49cd2fd6b4244b3ae7f",
    "id": null,
    "metadata": {},
    "name": "AddlsCabinetServiceMutation",
    "operationKind": "mutation",
    "text": "mutation AddlsCabinetServiceMutation(\n  $request: UpdateServiceAddlsRequestInput\n) {\n  updateServiceAddls(request: $request) {\n    id\n    addls {\n      id\n      name\n      nameUk\n    }\n    version\n  }\n}\n"
  }
};
})();

node.hash = "4765ebad66e8ddb14c630840a3d6e31e";

module.exports = node;
