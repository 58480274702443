import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import useQuery from '../../../useQuery';
import {FormattedMessage} from 'react-intl';
import {isTrue} from '../../../utility';
import Numbers from './Numbers';
import ChartSent from './ChartSent';
import ChartInbox from './ChartInbox';
import ErrorFallback from '../../ErrorFallback';
import Browser from '../../Browser';

const Statistics = React.memo(() => {
    const {more} = useQuery();
    const [chartSent, setChartSent] = React.useState();
    const [chartInbox, setChartInbox] = React.useState();
    return (
        <div>
            <div className='margin-0-auto display-flex flex-direction-column'>
                <ChartSent {...{setChartSent}}/>
                {isTrue(more) && chartSent && <ChartInbox {...{setChartInbox}}/>}
                {isTrue(more) && chartInbox && <div className='flex-order-minus1'><Numbers/></div>}
            </div>
        </div>
    );
});

export default React.memo(() => {
    return (
        <>
        <div className='font-weight-bold font-size-2dot5rem margin-top-1dot5rem'>
            <FormattedMessage defaultMessage='Statistics'/>
        </div>
        <Browser>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<div className='margin-top-1rem'><Skeleton/></div>}>
                    <Statistics/>
                </React.Suspense>
            </ErrorBoundary>
        </Browser>
        </>
    );
});
