import React from 'react';
import {graphql, useLazyLoadQuery} from 'react-relay/hooks';
import {useParams, Link, useLocation} from 'react-router-dom';
import {ErrorBoundary} from 'react-error-boundary';
import Skeleton from '../../Skeleton';
import {FormattedMessage} from 'react-intl';
import searchFilter from '../../../searchFilter';
import Discount from './properties/Discount';
import Code from './properties/Code';
import ErrorFallback from '../../ErrorFallback';

const Coupon = () => {
    const {id} = useParams();
    const query = useLazyLoadQuery(
        graphql`
            query CouponByIdQuery($id: String) {
                couponById(id: $id) {
                    id
                    ...DiscountCabinetCoupon_coupon
                    ...CodeCabinetCoupon_coupon
                }
            }
        `,
        {id}
    );
    return (
        <>
        <Code {...{coupon: query.couponById}}/>
        <Discount {...{coupon: query.couponById}}/>
        </>
    );
};

export default React.memo(() => {
    const location = useLocation();
    return (
        <div className='margin-top-1dot5rem'>
            <div className='margin-bottom-1rem'>
                <Link className='focus' to={`/cabinet/coupons${searchFilter(location.search)}`}>
                    &#60; <FormattedMessage defaultMessage='Coupons'/>
                </Link>
            </div>
            <ErrorBoundary {...{FallbackComponent: ErrorFallback}}>
                <React.Suspense fallback={<Skeleton/>}>
                    <Coupon/>
                </React.Suspense>
            </ErrorBoundary>
        </div>
    );
});
