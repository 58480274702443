import React from 'react';
import {graphql, useMutation, useFragment} from 'react-relay/hooks';
import {useForm} from 'react-hook-form';
import {FormattedMessage} from 'react-intl';
import classNames from 'classnames';
import i18n from '../../../../i18n';
import {FORM_FIELDS} from '../../DynamicTranslations';
import useQuery from '../../../../useQuery';

const NotEmpty = React.memo(({addl}) => {
    const {locale = 'uk'} = useQuery();
    const fAddl = useFragment(
        graphql`
            fragment NotEmptyCabinetAddl_addl on Addl {
                id
                isStar
                empty
                emptyUk
                version
                merchant {
                    id
                }
            }
        `,
        addl
    );
    const {register, handleSubmit} = useForm();
    const [flag, setFlag] = React.useState(false);
    const [apply, applying] = useMutation(
        graphql`
            mutation NotEmptyCabinetAddlMutation($request: UpdateAddlNotEmptyRequestInput) {
                updateAddlNotEmpty(request: $request) {
                    id
                    isStar
                    empty
                    emptyUk
                    version
                }
            }
    `);
    const submit = handleSubmit(data => apply({
        variables: {request: {
            id: fAddl.id,
            version: fAddl.version,
            isStar: data.isStar,
            empty: data.empty,
            emptyUk: data.emptyUk
        }},
        onCompleted: () => {setFlag(false)},
        onError: () => {}
    }));
    return (
        <>
        <div className='color-blue opacity-0dot6 margin-top-1rem margin-bottom-0dot5rem'>
            <FormattedMessage defaultMessage='Field is required'/>
        </div>
        <div className='margin-top-0dot5rem font-weight-bold'>
            {fAddl.isStar ? <FormattedMessage defaultMessage='Yes'/> : <FormattedMessage defaultMessage='No'/>}
        </div>
        <div className='padding-top-0dot5rem'>
            <div className='display-flex'>
                <div
                    className='button'
                    onClick={() => !applying && setFlag((flag) => !flag)}
                >
                    {flag ?
                        <FormattedMessage defaultMessage='Cancel'/>
                        :
                        <FormattedMessage defaultMessage='Edit'/>
                    }{applying && '...'}
                </div>
            </div>
            {flag &&
            <div className='inline'>
                <form onSubmit={submit}>
                    <div className='checkbox margin-0dot5rem-0'>
                        <label>
                            <div>
                                <input
                                    type='checkbox'
                                    defaultChecked={fAddl.isStar}
                                    {...register('isStar')}
                                />
                            </div>
                            <FormattedMessage defaultMessage='Make the field mandatory for the customer to fill out during the order placement'/>
                        </label>
                    </div>
                    <p className='margin-top-1rem margin-bottom-0dot5rem'>
                        <FormattedMessage defaultMessage='The error that will be displayed if the field is left empty'/>
                    </p>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fAddl.empty}
                            placeholder={i18n(FORM_FIELDS.languageEn, 'placeholder', locale)}
                            {...register('empty')}
                        />
                    </div>
                    <div className='input'>
                        <input
                            className='margin-0dot5rem-0 width-100percent'
                            defaultValue={fAddl.emptyUk}
                            placeholder={i18n(FORM_FIELDS.languageUk, 'placeholder', locale)}
                            {...register('emptyUk')}
                        />
                    </div>
                    <div className='display-flex'>
                        <div
                            className={classNames('button primary', {'disabled': !fAddl.merchant})}
                            onClick={() => fAddl.merchant && !applying && submit()}
                        >
                            <FormattedMessage defaultMessage='Save'/>{applying && '...'}
                        </div>
                    </div>
                </form>
                <div className='divider flex-0dot8 border-color-lightgrey padding-top-1rem'/>
            </div>
            }
        </div>
        </>
    );
});
export default React.memo(NotEmpty);
